import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';
import { shippingValidations } from 'additive-voucher/validations/shipping';
import { set, setProperties } from '@ember/object';
import { alias, or, equal } from '@ember/object/computed';
import cloneDeep from 'lodash.clonedeep';
import { computed } from '@ember/object';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

/**
 * Detail modal component to handle editing/creating of a shipping-model.
 *
 * It is multilingual and handles "inside"-editing,
 * which means it goes into editmode by property,
 * without any extra-route or something.
 *
 * It handles translations CRUD as well.
 *
 * It goes into creation mode, when shippingId equals "new".
 *
 * We have to use cloneDeep as the deep reference of the prices Array
 * remains and would cause wrong behaviours.
 *
 * TODO: as we fetch/update the store model when changing translation
 * the list will use the current/last language of the model for outputing info
 * as well. Maybe prevent this behaviour or refetch defaultLanguage befor back-routing.
 *
 * TODO: create multilang-modal component and use it here
 */
export default Component.extend({
  authenticatedFetch: service(),
  store: service(),
  intl: service(),
  router: service(),
  uiAppSettings: service(),
  uiToast: service(),
  uiDialog: service(),

  tagName: '',

  shippingId: null,
  model: null,
  currentLocale: 'de',

  _changeset: null,
  /* props to snapshot the changeset after creation */
  _availableLanguagesSnapshot: null,
  _pricesInitialSnapshot: null,

  isEdit: false,
  isFormTouched: false,
  isUniqueCountriesError: false,
  isPricesDirty: false,

  languages: alias('uiAppSettings.languages.contentLanguages'),
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),

  isLoading: or('getModel.isRunning', 'deleteTranslation.isRunning'),
  isNew: equal('shippingId', 'new'),

  /**
   * `ui-lang-select`s `publishedLanguages` property,
   * as we not really have publishing by language,
   * but a whole model `publishing` with the `enabled` prop,
   * we return all existing languages when model is enabled, otherwise empty array
   */
  _publishedLanguages: computed('_changeset.enabled', {
    get() {
      return this._changeset.get('enabled') ? this._changeset.get('availableLanguages') : [];
    }
  }),

  getModel: task(function* () {
    try {
      let model = null;

      if (this.isNew) {
        model = yield this.store.createRecord('shipping', {
          language: this.defaultLanguage || 'de',
          prices: []
        });
        set(this, 'isEdit', true);
      } else {
        model = yield this.store.findRecord('shipping', this.shippingId, {
          adapterOptions: {
            currentLocale: this.currentLocale
          },
          reload: true
        });
      }

      this.createChangeset(model);
    } catch (error) {
      throw new Error(`[av-shipping-list] fetch shippings ${error}`);
    }
  }).restartable(),

  saveModel: task(function* () {
    try {
      set(this, 'isFormTouched', true);

      yield this._changeset.validate();

      if (!this._changeset.get('isValid')) {
        return;
      }

      /* Validate: countries needs to be unique */
      let allCountries = [];
      let uniqueCountriesError = false;

      (this._changeset.get('prices') || []).map((price) => {
        (price.countries || []).map((country) => {
          if (uniqueCountriesError === true) {
            return;
          }

          if (allCountries.indexOf(country) >= 0) {
            uniqueCountriesError = true;
            return;
          }

          allCountries.push(country);
        });
      });

      if (uniqueCountriesError) {
        set(this, 'isUniqueCountriesError', true);
        return;
      }

      /* Because of deep-clone we are losing the ember-data reference, so manually pass props */
      this._changeset.execute();
      this._model.setProperties(this._changeset.get('data'));

      let tasks = [];
      tasks.push(
        this._model.save({
          adapterOptions: {
            currentLocale: this.currentLocale
          }
        })
      );
      tasks.push(timeout(250));

      const [model] = yield all(tasks);

      set(this, 'isEdit', false);

      if (this.isNew) {
        this.router.replaceWith(this.router.currentRouteName, {
          shipping_id: model.id
        });
        this.createChangeset(model);
      }

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
      /* throw new Error(`[av-shipping-list] saving shipping ${error}`); */
    }
  }).drop(),

  deleteTranslation: task(function* (language) {
    try {
      const adapter = this.store.adapterFor('shipping');
      const computedUrl = adapter.buildURL('shipping', this.shippingId);
      let tasks = [];
      tasks.push(
        yield this.authenticatedFetch.fetch(`${computedUrl}/${language}`, {
          method: 'DELETE'
        })
      );
      tasks.push(timeout(250));

      yield all(tasks);

      /**
       * If current language was the same as the user wants to delete,
       * change to first available language
       */
      if (this.currentLocale === language) {
        const remainingLanguages = [...this._model.availableLanguages].filter(
          (lang) => lang !== language
        );
        set(
          this,
          'currentLocale',
          remainingLanguages.length ? remainingLanguages[0] : this.defaultLanguage
        );
      }

      this.getModel.perform();
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }).drop(),

  createChangeset(model) {
    if (model.default) {
      /* Default items are non-editable */
      return this.router.replaceWith('instance.settings.shipping-package');
    }

    set(this, '_pricesInitialSnapshot', cloneDeep(model.prices));
    set(this, '_availableLanguagesSnapshot', cloneDeep(model.availableLanguages));

    /**
     * model.toJSON unfortunatley does not work, because it ignores the serializer,
     * and we need the serializer for amount normalization.
     * so we have to get each needed model-prop by getProperties
     */
    const modelProps = model.getProperties(
      'name',
      'description',
      'enabled',
      'default',
      'language',
      'availableLanguages',
      'prices'
    );

    const validation = shippingValidations(this.intl);
    /* We have to use cloneDeep otherwise the object inside de prices-array would still be referenced */
    const changeset = new Changeset(cloneDeep(modelProps), lookupValidator(validation), validation);
    set(this, '_changeset', changeset);
    set(this, '_model', model);
  },

  init() {
    this._super(...arguments);

    if (!this.shippingId) {
      throw new Error('[av-shipping-detail-modal] shippingId missing, aborted.');
    }

    let model = this.model;
    if (!model) {
      model = this.store.peekRecord('shipping', this.shippingId);
    }

    if (model) {
      this.createChangeset(model);
    } else {
      this.getModel.perform();
    }

    this.defaultLanguage && set(this, 'currentLocale', this.defaultLanguage);
  },

  onClose() {},
  _onDiscard() {
    if (this.isNew) {
      this.router.transitionTo('instance.settings.shipping-package');
      this._model.deleteRecord();
      return;
    }

    this._changeset.rollback();
    this._changeset.set('prices', cloneDeep(this._pricesInitialSnapshot));
    this._changeset.set('availableLanguages', cloneDeep(this._availableLanguagesSnapshot));

    set(this, 'isEdit', false);
  },
  actions: {
    onClose() {
      if (this.isEdit) {
        /* changeset does not recognize an array-change, so we have to self handle prices dirty state */
        if (this._changeset.isDirty || this.isPricesDirty) {
          this.uiDialog.showDiscardChangesConfirm(() => {
            this._onDiscard();
          });
        } else {
          if (this.isNew) {
            this.router.transitionTo('instance.settings.shipping-package');
            this._model.deleteRecord();
          }
          !this.isDestroying && set(this, 'isEdit', false);
        }
      } else {
        this.onClose();
      }
    },
    onNewLanguage(language) {
      setProperties(this, {
        currentLocale: language,
        isEdit: true,
        isFormTouched: false
      });

      /* Reset multilingual props */
      this._changeset.set('name', '');
      this._changeset.set('description', '');
      this._changeset.set('language', language);
    },
    changeLocale(language) {
      if (this.currentLocale === language) {
        return;
      }

      setProperties(this, {
        isFormTouched: false,
        currentLocale: language
      });

      if (this.isNew) {
        this._changeset.set('language', language);
        return;
      }

      this.getModel.perform();
    },
    addPrice() {
      let prices = this._changeset.get('prices');
      prices.push({
        countries: [],
        price: 0
      });
      this._changeset.set('prices', [...prices]);
      set(this, 'isPricesDirty', true);
    },
    onPriceUpdate(arrayIndex, value) {
      let prices = this._changeset.get('prices');
      prices[arrayIndex] = {
        countries: value.countries,
        price: parseInt(value.price)
      };

      this._changeset.set('prices', [...prices]);

      set(this, 'isPricesDirty', true);
    },
    onPriceDelete(arrayIndex) {
      const prices = this._changeset.get('prices');
      prices.splice(arrayIndex, 1);

      this._changeset.set('prices', [...prices]);

      set(this, 'isPricesDirty', true);
    },
    async toggleEnable() {
      if (this.isEdit) {
        throw new Error(
          '[av-shipping-detail-modal] In edit mode not possible, aborted saving due to possible invalid model'
        );
      }
      this._changeset.set('enabled', !this._changeset.get('enabled'));
      await this.saveModel.perform();
    }
  }
});
