export const FONT_FALLBACKS = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  serif: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif'
};

export const DEFAULT_STYLES_COLORS = {
  /* Main Colors */
  main: {
    color: '#3D3D3D',
    contrastColor: '#FFFFFF'
  },
  accent: {
    color: '#967E6E',
    contrastColor: '#FFFFFF'
  },
  ambient: {
    color: '#FFFFFF',
    contrastColor: '#3D3D3D'
  },
  /* Special colors */
  error: {
    color: '#f6374e',
    contrastColor: '#FFFFFF'
  },
  titleSuccess: {
    color: '#24B474',
    contrastColor: '#FFFFFF'
  },
  titleFailure: {
    color: '#f6374e',
    contrastColor: '#FFFFFF'
  }
};

export const DEFAULT_STYLES_TYPOGRAPHY = {
  h1: {
    fontFamily: 'primary',
    fontWeight: 'bold',
    fontSize: '40px',
    uppercase: false,
    letterSpacing: 0,
    lineHeight: '48px'
  },
  h2: {
    fontFamily: 'primary',
    fontWeight: 'bold',
    fontSize: '32px',
    uppercase: false,
    letterSpacing: 0,
    lineHeight: '40px'
  },
  h3: {
    fontFamily: 'primary',
    fontWeight: 'bold',
    fontSize: '24px',
    uppercase: false,
    letterSpacing: 0,
    lineHeight: '28px'
  },
  p: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    fontSize: '18px',
    uppercase: false,
    letterSpacing: 0,
    lineHeight: '24px'
  },
  button: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    fontSize: '14px',
    uppercase: false,
    letterSpacing: 0,
    lineHeight: '20px'
  }
};

export const DEFAULT_STYLES = {
  borderRadius: '2px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
  scrollOffset: {
    sm: {
      value: '0',
      unit: 'px'
    },
    md: {
      value: '0',
      unit: 'px'
    }
  }
};

export const DEFAULT_COMPONENTS = {
  overlay: { zIndex: 9999 },
  tooltip: { zIndex: 9999 }
};

export const CONTAINER_VALUES = {
  width: undefined,
  breakpointMd: 768,
  breakpointSm: 375
};

export const StyleValues = {
  globals: DEFAULT_STYLES,
  container: CONTAINER_VALUES,
  colors: DEFAULT_STYLES_COLORS,
  typography: DEFAULT_STYLES_TYPOGRAPHY,
  fonts: {
    primary: FONT_FALLBACKS.sansSerif,
    secondary: FONT_FALLBACKS.serif,
    fontWeightBold: 'bold'
  },
  components: DEFAULT_COMPONENTS
};
