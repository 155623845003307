import Component from '@ember/component';

import { computed } from '@ember/object';
import { filter, gt } from '@ember/object/computed';
import { isPresent } from '@ember/utils';

/**
 * Wrapping dialog for voucher previews
 *
 * @class voucher-preview-dialog
 */
export default Component.extend({
  tagName: '',

  /**
   * Boolean indicating if the dialog should fit the content or use default size
   *
   * @property fitContent
   * @type {Boolean}
   * @default false
   */
  fitContent: false,

  /**
   * An array of voucher previews including the url and sizes
   *
   * @property previews
   * @type {Array}
   * @default null
   */
  previews: null,

  _modalClassNames: computed('fitContent', {
    get() {
      const className = 'voucher-preview-dialog';
      if (this.fitContent) {
        return `white h-auto ${className} ${className}__fit-content`;
      }

      return `${className} white`;
    }
  }),

  /**
   * boolean indicating if there are any previews provided
   *
   * @computed _hasPreviews
   * @type {Boolean}
   * @private
   */
  _hasPreviews: gt('_previews.length', 0),

  /**
   * filtered array of preview urls with valid data
   *
   * @computed _previews
   * @type {Array}
   * @private
   */
  _previews: filter('previews', function (preview) {
    if (typeof preview === 'object') {
      return isPresent(preview.url);
    }
  }),

  /**
   * boolean indicating if carousel should be used or only single preview is necessary
   *
   * @computed _showCarousel
   * @type {Boolean}
   * @private
   */
  _showCarousel: gt('_previews.length', 1),

  /**
   * Called when the user clicks on the close icon
   *
   * @function onClose
   * @return {Any}
   */
  onClose() {},

  actions: {
    onClose() {
      this.onClose();
    }
  }
});
