import Component from '@ember/component';
import { task, all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  uiPaths: service(),
  uiToast: service(),

  /**
   * the current organization slug
   *
   * @property organizationSlug
   * @type {String}
   * @default null
   */
  organizationSlug: null,

  /**
   * the organization slug of which the chart-data was fetched,
   * used to detect whether the slug has changes
   *
   * @property _previousOrganization
   * @type {String}
   * @default null
   * @private
   */
  _previousOrganization: null,

  /*
   * @property _shouldReload
   * indicates whether the periods should be reloaded
   * @type {Boolean}
   * @default false
   * @private
   */
  _shouldReload: true,

  init() {
    this._super(...arguments);

    if (!this._previousOrganization) {
      this._previousOrganization = this.organizationSlug;
    }
  },

  didUpdateAttrs() {
    this._super();
    // reload indicator data on organization change
    if (this.organizationSlug !== this._previousOrganization) {
      set(this, '_shouldReload', true);
      set(this, '_previousOrganization', this.organizationSlug);
    }
  },

  /**
   * <slug>/dashboard/filters
   *
   * Loads the available periods for the select
   * Pre-selects the first item
   *
   * @throws {Error}
   */
  loadPeriods: task(function* () {
    try {
      let tasks = [];
      const url = this.uiPaths
        .pathsByRouteName('instance.dashboard', this.organizationSlug)
        .api().url;
      // load periods
      tasks.push(this.authenticatedFetch.fetch(`${url}/dashboard/filters`));
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[DASHBOARD] Error in response');
      }

      const periods = yield response.json();

      let data = [];
      if (!Array.isArray(periods.years)) {
        throw new Error('[DASHBOARD] Error while preparing data');
      }

      periods.years.forEach((period) => {
        // Translate if it's a string e.g. last_12_months
        if (typeof period === 'string') {
          data.push({
            name: this.intl.t(`dashboard.periodSelection.${period}`),
            value: period
          });
        } else {
          data.push({ name: period, value: period });
        }
      });

      // Pre-select first item
      set(this, 'selected', data[0]);
      set(this, 'initialValue', data[0]);
      set(this, '_shouldReload', false);

      return data;
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  onChange() {}
});
