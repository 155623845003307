import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
  uiAppSettings: service(),

  /**
   * Whether the FAQ dialog is shown or not
   *
   * @property isFaqDialog
   * @type {Boolean}
   * @default false
   */
  isFaqDialog: false,

  /**
   * Array of keys for FAQs
   *
   * @property faqKeys
   * @type {Array}
   * @default null
   */
  faqKeys: null,

  isProfessionalPlan: alias('uiAppSettings.isProfessionalPlan'),

  init() {
    this._super(...arguments);

    set(this, 'faqKeys', ['inheritedSettings', 'paymentTypesAmount']);
  }
});
