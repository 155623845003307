import Component from '@ember/component';

import { set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

import { PAYMENT_TYPES_MAP } from 'additive-voucher/utils/constants';
import { is as planIs, PLAN_STARTER } from '@additive-apps/utils/utils/plans';

/**
 * @class av-add-payment-types
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  store: service(),
  uiAppSettings: service(),

  isLoading: false,
  isPaymentTypeError: false,

  paymentTypes: null,
  paymentTypeOptions: null,

  selectedType: null,
  newPaymentType: null,

  init() {
    this._super(...arguments);

    // get all available types (include plan for this decision)
    let available = Object.keys(PAYMENT_TYPES_MAP);
    if (planIs(PLAN_STARTER, this.uiAppSettings.planName)) {
      // Starter can only have bank_transfer or paypal
      available = available.filter((pt) => ['bank_transfer', 'paypal'].indexOf(pt) >= 0);
    }

    // check which payment-type can be added
    const active = this.paymentTypes && this.paymentTypes.mapBy('type');
    const remaining =
      available
        .filter((item) => active.indexOf(item) === -1)
        .map((paymentType) => {
          return {
            name: this.intl.t(`global.paymentTypes.${paymentType}`),
            value: paymentType
          };
        }) || [];

    remaining.length > 0
      ? set(this, 'selectedType', remaining[0].value)
      : set(this, 'isPaymentTypeError', true);
    set(this, 'paymentTypeOptions', remaining);
  },

  willDestroyElement() {
    this._super(...arguments);
    setProperties(this, {
      isEditDialog: false,
      isPaymentTypeError: false,
      selectedType: null,
      newPaymentType: null
    });
  },
  actions: {
    addPaymentType(type) {
      const { credentials } = PAYMENT_TYPES_MAP[type];

      let paymentTypeDraft = null;
      if (type === 'oppwa') {
        paymentTypeDraft = this.store.createRecord('payment-type', {
          type,
          credentials,
          options: { sofortueberweisung: false, applePay: false }
        });
      } else {
        paymentTypeDraft = this.store.createRecord('payment-type', {
          type,
          credentials
        });
      }

      set(this, 'newPaymentType', paymentTypeDraft);
      set(this, 'isEditDialog', true);
    },
    closeCreationDialog() {
      this.newPaymentType && this.newPaymentType.rollbackAttributes();
      set(this, 'isEditDialog', false);
      this.onClose();
    }
  }
});
