import Component from '@ember/component';

export default Component.extend({
  classNames: ['av-order_customer-detail'],
  /**
   * The order object, which holds all customer data
   * @property order
   * @type {Object} the order object
   */
  order: null
});
