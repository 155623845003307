import RESTSerializer from '@ember-data/serializer/rest';
import { inject as service } from '@ember/service';

import { isNone } from '@ember/utils';
import { centsToAmount, amountToCents } from 'additive-voucher/utils/currency-serialization';

const AMOUNT_PROPERTIES = ['discountedAmount', 'amount', 'minAmount', 'maxAmount'];
const NULLABLE_PROPERTIES = [
  'discountedAmount',
  'discountPeriodEnd',
  'discountPeriodStart',
  'personCount'
];

export default class VoucherSerializer extends RESTSerializer {
  @service() currentUser;

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    // check if payload contains array or single voucher
    const vouchers = payload.vouchers ? payload.vouchers : [payload.voucher];
    // transform currency properties
    vouchers.forEach((voucher) => {
      voucher.images = voucher.images.sort((a, b) => a.position - b.position);
      AMOUNT_PROPERTIES.forEach((property) => {
        if (voucher[property]) {
          voucher[property] = centsToAmount(voucher[property]).toString();
        }
      });

      if (voucher.isDiscounted === false) {
        voucher.discountedAmount = null;
        voucher.hasDiscountPeriod = false;
        voucher.discountPeriodStart = null;
        voucher.discountPeriodEnd = null;
      }
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    Object.keys(json).forEach(
      (key) => isNone(json[key]) && NULLABLE_PROPERTIES.indexOf(key) < 0 && delete json[key]
    );
    // TODO: Check why images don't get serialized if we remove this
    const serializedImages =
      json.images &&
      json.images.map((item, index) => {
        item.position = index;
        return item;
      });

    json.images = serializedImages;

    // transform currency properties to cents
    AMOUNT_PROPERTIES.forEach((property) => {
      if (json[property]) {
        json[property] = amountToCents(json[property]);
      }
    });

    // clear discount properties on `isDiscounted: false`
    if (json.isDiscounted === false) {
      json.discountedAmount = null;
      json.hasDiscountPeriod = false;
      json.discountPeriodStart = null;
      json.discountPeriodEnd = null;
    }

    // transform interests to string array
    json.interests = json.interests?.map((interest) => (interest.id ? interest.id : interest));

    json.travelTimes = json.travelTimes?.map((travelTime) =>
      travelTime.id ? travelTime.id : travelTime
    );

    json.travelMotivations = json.travelMotivations?.map((travelMotivation) =>
      travelMotivation.id ? travelMotivation.id : travelMotivation
    );

    return json;
  }

  serializeIntoHash(data, type, record, options) {
    Object.assign(data, this.serialize(record, options));
  }

  normalizeFindRecordResponse(store, primaryModelClass, payload) {
    payload.voucher.links = {
      category: `/${this.currentUser.currentOrganization.id}/categories/${payload.voucher.categoryId}`
    };

    return super.normalizeFindRecordResponse(...arguments);
  }

  keyForRelationship(key, relationship) {
    if (relationship === 'belongsTo') {
      return `${key}Id`;
    }
    return key;
  }
}
