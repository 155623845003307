import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { bind, scheduleOnce } from '@ember/runloop';
import { set, computed } from '@ember/object';

export default Controller.extend({
  currentUser: service(),
  intl: service(),
  router: service(),
  uiDialog: service(),
  uiState: service(),

  isViewer: computed.alias('currentUser.isViewer'),

  isMobile: false,

  init() {
    this._super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);
    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);

    scheduleOnce('afterRender', this, this.resizeListener);
  },

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    set(this, 'isMobile', !window.matchMedia('(min-width: 600px)').matches);
  },

  actions: {
    /**
     * Opens the widget in configuration-mode
     */
    edit(widget) {
      if (this.isMobile) {
        this.uiDialog.showError(
          this.intl.t('errors.desktopOnly.title'),
          this.intl.t('errors.desktopOnly.widget')
        );
      } else {
        this.router.transitionTo('instance.widgets.index.widget', widget);
      }
    },
    /**
     * Opens the detail of the current order
     */
    toggleDetail() {
      this.uiState.getState('order-detail').toggle();
    }
  }
});
