import ENV from 'additive-voucher/config/environment';
import Controller from '@ember/controller';

import { task, timeout, all } from 'ember-concurrency';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import cloneDeep from 'lodash.clonedeep';
import lookupValidator from 'ember-changeset-validations';

import { legalSettingsValidation } from 'additive-voucher/validations/legal-settings';

import Changeset from 'ember-changeset';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiAppSettings: service(),
  uiToast: service(),

  legalResource: null,

  isLoading: computed.alias('loadLegal.isRunning'),

  organizationId: computed.alias('currentUser.currentOrganization.id'),
  languages: computed.alias('uiAppSettings.languages.contentLanguages'),

  _readonly: computed.not('currentUser.isAdmin'),

  loadLegal: task(function* (locale) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      const request = yield this.authenticatedFetch.fetch(`${url}/legals`, {
        headers: { 'Accept-Language': locale || 'de' }
      });
      // save voucher and keep the task running for at least 250ms
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] legal', response);
      } else {
        // destructure response
        const {
          legal: { language, availableLanguages, termsLink, privacyLink, disclaimerLink }
        } = yield response.json();

        // set the current langauge and all published Languages
        set(this, 'currentLanguage', language);
        set(this, 'availableLanguages', availableLanguages);

        const legalResource = {
          termsLink,
          privacyLink,
          disclaimerLink
        };

        this.createChangeset(legalResource);

        set(this, 'legalResource', legalResource);
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  updateLegals: task(function* (changeset, name) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    let tasks = [];

    this.changeset.get(`${name}.sync`) && this.changeset.set(`${name}.sync`, false);

    // save data to underlying object
    yield changeset.save();
    const legalsPayload = yield changeset.get('data');

    const request = yield this.authenticatedFetch.fetch(`${url}/legals`, {
      headers: { 'Accept-Language': this.currentLanguage || 'de' },
      method: 'PUT',
      body: JSON.stringify(legalsPayload)
    });
    // save voucher and keep the task running for at least 250ms
    tasks.push(request);
    tasks.push(timeout(250));

    const [response] = yield all(tasks);

    if (!response || !response.ok) {
      throw new Error('[SETTINGS - UPDATE] legal', response);
    }

    const {
      legal: { termsLink, privacyLink, disclaimerLink }
    } = yield response.json();
    const legalResource = {
      termsLink,
      privacyLink,
      disclaimerLink
    };

    set(this, 'legalResource', legalResource);
    // TODO: When api is finished we should recheck if we need a multilang setting
    this.uiToast.showToast({
      title: this.intl.t('toast.success'),
      type: 'success'
    });
  }),

  toggleSync: task(function* (name) {
    try {
      yield timeout(250);
      const synced = this.changeset.get(`${name}.sync`);
      this.changeset.set(`${name}.sync`, !synced);
      yield this.updateLegals.perform(this.changeset);
    } catch (error) {
      throw new Error('[SETTINGS - SYNC] legal', error);
    }
  }),

  createChangeset(resource) {
    const validation = legalSettingsValidation(this.intl);
    const changeset = new Changeset(cloneDeep(resource), lookupValidator(validation), validation);
    set(this, 'changeset', changeset);
  },

  actions: {
    changeLanguage(lang) {
      this.loadLegal.perform(lang);
    },
    onCloseDialog() {
      this.createChangeset(this.legalResource);
    }
  }
});
