import Component from '@ember/component';

import { task, timeout, all } from 'ember-concurrency';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { is as planIs, PLAN_STARTER } from '@additive-apps/utils/utils/plans';

/**
 * HOC Component for payment-types
 *
 * @class av-payment-types
 */
export default Component.extend({
  currentUser: service(),
  store: service(),
  uiAppSettings: service(),

  activeItem: null,
  isDeleteDialog: false,

  _paymentTypes: null,

  _isLoading: computed.alias('_getPaymentTypes.isRunning'),
  _readonly: computed.not('currentUser.isAdmin'),

  _availablePaymentTypes: computed('_paymentTypes.@each.isNew', 'uiAppSettings.planName', {
    get() {
      let paymentTypes = this._paymentTypes || [];

      if (planIs(PLAN_STARTER, this.uiAppSettings.planName)) {
        paymentTypes = paymentTypes.filter(
          (pt) => ['bank_transfer', 'paypal'].indexOf(pt.type) >= 0
        );
      }

      return paymentTypes.filterBy('isNew', false);
    }
  }),

  _getPaymentTypes: task(function* () {
    try {
      let tasks = [];
      const request = yield this.store.findAll('payment-type');
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      set(this, '_paymentTypes', response);
    } catch (error) {
      throw new Error(`[av-payment-types] fetch payment-types ${error}`);
    }
  }),

  init() {
    this._super(...arguments);

    const savedPaymentTypes = this.store.peekAll('payment-type');
    /* Only fetch when nothing in store */
    if (savedPaymentTypes && savedPaymentTypes.length > 1) {
      set(this, '_paymentTypes', savedPaymentTypes);
    } else {
      set(this, '_paymentTypes', []);

      this._getPaymentTypes.perform();
    }
  },

  actions: {
    onDelete(item) {
      set(this, 'activeItem', item);
      set(this, 'isDeleteDialog', true);
    }
  }
});
