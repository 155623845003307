import Controller from '@ember/controller';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import { categoryValidation } from 'additive-voucher/validations/category';

export default Controller.extend({
  intl: service(),
  router: service(),
  store: service(),

  categoryValidation: null,

  init() {
    this._super(...arguments);
    const intl = this.intl;
    // pass intl to extendedOrganization validation
    const catValidation = categoryValidation(intl);
    set(this, 'categoryValidation', catValidation);
  },

  actions: {
    onConfirm(record) {
      record.save();
      const categoryModel = this.store.createRecord('category', record.get('data'));
      return categoryModel.save();
    },
    onClose() {
      this.router.transitionTo('instance.vouchers.categories');
    }
  }
});
