import { validateFormat } from 'ember-changeset-validations/validators';

export const legalSettingsValidation = (intl) => {
  const regex =
    /(http(s)?:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

  return {
    'termsLink.value': validateFormat({
      regex,
      message: intl.t('errors.invalidUrl'),
      allowBlank: true
    }),
    'privacyLink.value': validateFormat({
      regex,
      message: intl.t('errors.invalidUrl'),
      allowBlank: true
    }),
    'disclaimerLink.value': validateFormat({
      regex,
      message: intl.t('errors.invalidUrl'),
      allowBlank: true
    })
  };
};
