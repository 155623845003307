import ENV from 'additive-voucher/config/environment';

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task, all } from 'ember-concurrency';

import AuthRouteMixin from '@additive-apps/auth/mixins/auth-route-mixin';
import Userback from '@userback/widget';

export default class InstanceRoute extends Route.extend(AuthRouteMixin) {
  @service authenticatedFetch;
  @service currentUser;
  @service session;
  @service store;
  @service uiAppSettings;
  @service uiLocale;

  model(params) {
    return this.fetchOrganizationData.perform(params);
  }

  @task(function* (params) {
    try {
      const organization = yield this.store.findRecord('organization', params.instance_id);
      this.currentUser.currentOrganization = organization;

      let tasks = [];
      tasks.push(this.currentUser.loadUser());
      tasks.push(this.uiAppSettings.loadLanguages());
      yield all(tasks);

      yield this.uiLocale.setLocale(
        this.currentUser?.user?.language || this.currentUser?.currentOrganization?.language || 'de'
      );

      if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
        Userback(
          this.currentUser.isAdditiveUser
            ? ENV.APP.userbackAccessToken
            : ENV.APP.userbackAccessTokenPartner,
          {
            email: this.currentUser.organizationUser.email,
            name: this.currentUser.organizationUser.fullName,
            categories: 'Gutscheine'
          }
        );
      }

      return organization;
    } catch (e) {
      // TODO: don't throw and handle differently
      throw new Error(`[ORGANIZATION]: ${e}`);
    }
  })
  fetchOrganizationData;

  @action
  loading() {
    return !this.currentUser.currentOrganization;
  }
}
