import Component from '@ember/component';
import { computed } from '@ember/object';
import { centsToAmount } from 'additive-voucher/utils/currency-serialization';

export default Component.extend({
  classNames: ['av-history-item relative flex flex-column pb4 pl2'],
  classNameBindings: ['item.state'],

  item: null,

  isPendingBankTransfer: computed('item.{paymentType,successful}', {
    get() {
      const { paymentType, successful } = this.item;
      return paymentType === 'bank_transfer' && !successful;
    }
  }),
  redeemedAmount: computed('item.amountRedeemed', {
    get() {
      const amount = centsToAmount(this.item.amountRedeemed);

      return amount || 0;
    }
  }),
  isReactivated: computed('item.{canceledBy,state}', {
    get() {
      return (
        this.item.canceledBy && (this.item.state === 'redeemed' || this.item.state === 'canceled')
      );
    }
  })
});
