import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { hasAccess } from '@additive-apps/auth/utils/roles-map';

export default class InstanceAuditlogRoute extends Route {
  @service currentUser;
  @service router;

  beforeModel() {
    super.beforeModel(...arguments);

    if (!hasAccess('member', this.currentUser?.user?.role)) {
      this.router.transitionTo('instance');
    }
  }
}
