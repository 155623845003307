import Component from '@ember/component';
import { computed } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 * Component to handle one single shipping price<->countries pair.
 *
 * It greps its errors out of changeset and shows them.
 * To grep its related error out of the changeset errors array
 * we use the "arrayIndex" prop, which indicates the array position
 * which gets validated and will be inside the errors-array
 *
 */
export default Component.extend({
  uiAppSettings: service(),

  classNames: ['av-shipping-price', 'relative'],
  classNameBindings: ['isReadOnly:readOnly'],

  arrayIndex: null,
  changeset: null,
  countries: null,
  price: null,
  isReadOnly: false,

  _price: oneWay('price'),

  options: computed('countries.[]', {
    get() {
      if (!this.countries) {
        return [];
      }

      return [...this.countries];
    }
  }),

  /**
   * Changeset.error.prices looks like this:
   * __ARRAYINDEX__ is the index of value inside prices array. Can be multiple
   *
   *```js
   * {
   *   price: {
   *     __ARRAYINDEX__: [ERROR_MESSAGE],
   *     __ARRAYINDEX__: [ERROR_MESSAGE],
   *   },
   *   countries: {
   *     __ARRAYINDEX__: [ERROR_MESSAGE]
   *   }
   * }
   *```
   */
  _errors: computed('arrayIndex', 'changeset.error.prices.@each.{countries,price}', {
    get() {
      if (!this.changeset || isNaN(parseInt(this.arrayIndex))) {
        return [];
      }

      const errors = this.changeset.get('error.prices');
      if (errors && errors.validation && errors.validation[0]) {
        return {
          countries:
            (errors.validation[0].countries && errors.validation[0].countries[this.arrayIndex]) ||
            [],
          price: (errors.validation[0].price && errors.validation[0].price[this.arrayIndex]) || []
        };
      }

      return {};
    }
  }),

  /**
   * Prepare select pre-selected array to work properly
   */
  preSelected: computed('_price.countries.[]', 'options.[]', {
    get() {
      if (!this.options || !this._price || !this._price.countries) {
        return [];
      }

      const priceCountries = this._price.countries;
      const priceCount = priceCountries.length;
      let preSelected = [];

      /**
       * The only purpose of the is to do the minimal amount of work.
       * Chances are that the selected array is much smaller than countries array,
       * but never bigger than countries
       */
      this.options.some((country) => {
        if (priceCountries.indexOf(country.code) > -1) {
          preSelected.push(country);
        }

        /* Iterate all existing countries, as long we did not found any element */
        return preSelected.length === priceCount;
      });

      return preSelected;
    }
  }),

  onPriceUpdate() {},
  onPriceDelete() {},

  actions: {
    onCountryChange(country, checked) {
      let newCountries = this._price.countries;

      if (!Array.isArray(newCountries)) {
        newCountries = [newCountries];
      }

      const index = newCountries.indexOf(country.code);
      if (checked) {
        /* Only push if not allready inside */
        index === -1 && newCountries.push(country.code);
      } else {
        if (index >= 0) {
          const preElements = newCountries.slice(0, index);
          const postElements = newCountries.slice(index + 1);
          newCountries = [...preElements, ...postElements];
        }
      }

      this.onPriceUpdate({ countries: newCountries, price: this._price.price });
    },
    onPriceChange(price) {
      this.onPriceUpdate({ countries: this._price.countries, price: price });
    }
  }
});
