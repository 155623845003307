import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),
  tagName: '',

  /**
   * Whether the stay, treatment and product are enabled or not
   * @property isProfessionalPlan
   * @type {Boolean}
   * @default false
   */
  isProfessionalPlan: false,

  onClose() {},

  actions: {
    goTo(path, type) {
      if (type) {
        this.router.transitionTo(path, { queryParams: { vt: type } });
      } else {
        this.router.transitionTo(path);
      }
      this.onClose();
    }
  }
});
