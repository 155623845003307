import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { hasAccess } from '@additive-apps/auth/utils/roles-map';
import { getOnBoarding } from 'additive-voucher/utils/constants';

// add instance-routes here
const instanceRoutes = [
  {
    path: 'instance.dashboard',
    intlKey: 'routes.dashboard.title',
    svg: 'home'
  },
  {
    path: 'instance.vouchers',
    intlKey: 'routes.vouchers.title',
    svg: 'gift'
  },
  {
    path: 'instance.orders',
    intlKey: 'routes.orders.title',
    svg: 'cart'
  },
  {
    path: 'instance.widgets',
    intlKey: 'routes.widgets.navTitle',
    svg: 'style'
  },
  {
    path: 'instance.auditlog',
    intlKey: 'routes.auditLog.title',
    svg: 'audit-log',
    requiredRole: 'member'
  },
  {
    path: 'instance.settings',
    intlKey: 'routes.settings.title',
    svg: 'settings'
  }
];

export default class ApplicationController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service session;
  @service store;
  @service uiAppSettings;
  @service uiLocale;
  @service uiState;

  queryParams = ['onboarding'];

  onboarding = null;

  get instanceRoutes() {
    return instanceRoutes.filter((route) => {
      return !route.requiredRole || hasAccess(route.requiredRole, this.currentUser?.user?.role);
    });
  }

  get helpUrl() {
    const locale = this.uiLocale?.locale || 'de';

    return `https://knowledge.additive.eu/${locale}/knowledge/additive-${
      locale === 'en' ? 'vouchers' : 'gutscheine'
    }`;
  }

  get onboardingOptions() {
    return getOnBoarding(this.intl);
  }

  @action
  transitionToOrganizationRoute(organization) {
    this.currentUser.currentOrganization = organization;
    this.currentUser.user = null;
    this.store.unloadAll('app');
    this.store.unloadAll('customer');
    this.store.unloadAll('order');
    this.store.unloadAll('package');
    this.store.unloadAll('payment-type');
    this.store.unloadAll('room-type');
    this.store.unloadAll('shipping');
    this.store.unloadAll('product-category');
    this.store.unloadAll('treatment-category');
    this.store.unloadAll('voucher');
    this.store.unloadAll('widget');
    this.store.unloadAll('template');

    // unload categories after voucher to prevent reloading through unload
    this.store.unloadAll('category');

    // mm related models
    this.store.unloadAll('folder');
    this.store.unloadAll('medium');
    this.uiAppSettings.reset();

    /*
      we have always to pass the id and not the object or
      the instance-routes model-hook won't trigger again
    */
    this.router.transitionTo('instance', organization.id);
  }

  @action
  finishOnboarding() {
    this.onboarding = null;
  }
}
