import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

/* TODO: Add me to an addon */
export default Factory.extend({
  email: faker.internet.email(),
  fullName: () => `${faker.name.firstName()} ${faker.name.lastName()}`,
  isAdditive: true,
  isPartner: true,
  isSuperAdmin: false,
  isSupport: false,
  role: 'member',
  language: 'de'
});
