import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { arg } from 'ember-arg-types';
import { array, object, boolean, func } from 'prop-types';

const SUCCESS_DEFAULT = [
  {
    key: 'standard',
    value: {
      color: '#24B474',
      contrastColor: '#FFFFFF'
    }
  }
];
const FAILURE_DEFAULT = [
  {
    key: 'standard',
    value: {
      color: '#f6374e',
      contrastColor: '#FFFFFF'
    }
  }
];

/**
 * @class colors
 * @module av-widget-configuration
 */
export default class AVlWidgetConfiguratorColors extends Component {
  /**
   * The selectable options
   *
   * @property selectOptions
   * @type {Array}
   */
  @arg(array)
  selectOptions = null;

  /**
   * The styles object
   *
   * @property styles
   * @type {Object}
   */
  @arg(object)
  styles = null;

  /**
   * Whether the options should be read-only
   *
   * @property isReadOnly
   * @type {Boolean}
   */
  @arg(boolean)
  isReadOnly = false;

  @arg(func)
  onUpdate = () => {};

  /**
   * Extends the default color options with a reset for the success color
   *
   * @computed _successColorOptions
   * @private
   */
  get _successColorOptions() {
    return this.selectOptions.concat(SUCCESS_DEFAULT);
  }

  /**
   * Extends the default color options with a reset for the failure color
   *
   * @computed _failureColorOptions
   * @private
   */
  get _failureColorOptions() {
    return this.selectOptions.concat(FAILURE_DEFAULT);
  }

  onUpdate() {}

  @action
  update(key, val, sync, colorOption) {
    // if custom color then colorOption is true
    if (colorOption === true) {
      set(val, 'sync', false);
    } else {
      set(val, 'syncedColor', val.color);
      set(val, 'syncedContrastColor', val.contrastColor);
      set(val, 'sync', true);
    }

    this.onUpdate(key, val);
  }

  @action
  toggleSync(key, sync) {
    this.onUpdate(key, { sync: !sync });
  }
}
