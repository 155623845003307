import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { htmlSafe } from '@ember/string';

import { LAYOUT_PORTRAIT } from 'additive-voucher/utils/constants';

/**
 * Wrapping a voucher preview in an iframe
 *
 * @class voucher-preview-iframe
 */
export default Component.extend({
  classNames: ['voucher-preview-iframe', 'relative', 'mw-100', 'flex items-start justify-start'],
  classNameBindings: [
    'isReadOnly:voucher-preview-iframe__is-read-only',
    '_isLoading:voucher-preview-iframe__is-loading:'
  ],

  /**
   * Sets a class according to the specified layout type. Allowed values are 'portrait' and 'landscape'
   *
   * @property layoutType
   * @type {String}
   * @default portrait
   */
  layoutType: LAYOUT_PORTRAIT,

  /**
   * The max width of the iframe in pixels
   *
   * @property maxWidth
   * @type {Int}
   * @default null
   */
  maxWidth: null,

  /**
   * Boolean indicating if the iframe is interactive or not
   *
   * @property isReadOnly
   * @type {Boolean}
   * @default false
   */
  isReadOnly: false,

  /**
   * The url of the voucher preview
   *
   * @property url
   * @type {String}
   * @default null
   */
  url: null,

  /**
   * Boolean indicating if the content of the iframe is loaded or not
   *
   * @property _isLoading
   * @type {Boolean}
   * @default true
   * @private
   */
  _isLoading: true,

  /**
   * Private property used to detect changes on the url
   *
   * @property _previousUrl
   * @type {String}
   * @default null
   */
  _previousUrl: null,

  /**
   * The max-width style
   *
   * @computed _maxWidthStyle
   * @type {String}
   * @private
   */
  _widthWrapperClassName: computed('layoutType', {
    get() {
      return `voucher-template-preview-width voucher-template-preview-width__${this.layoutType} relative`;
    }
  }),

  /**
   * The max-width style
   *
   * @computed _maxWidthStyle
   * @type {String}
   * @private
   */
  _maxWidthStyle: computed('maxWidth', {
    get() {
      return this.maxWidth ? htmlSafe(`max-width: ${this.maxWidth}px;`) : '';
    }
  }),

  init() {
    this._super(...arguments);

    set(this, '_previousUrl', this.url);
  },

  didInsertElement() {
    this._super(...arguments);

    // adds event listener for iframe onload
    if (document && this.element) {
      const iframe = document.getElementById(`preview-iframe-${this.elementId}`);
      iframe &&
        iframe.addEventListener('load', () => {
          set(this, '_isLoading', false);
        });
    }
  },

  didUpdateAttrs() {
    this._super(...arguments);

    // set _isLoading true if url changed
    if (this.url !== this._previousUrl) {
      set(this, '_isLoading', true);
      set(this, '_previousUrl', this.url);
    }
  }
});
