import Component from '@ember/component';
import { A } from '@ember/array';
import { set, computed, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import { productsValidation } from 'additive-voucher/validations/order';

/**
 *  The dialog that can be used to select the products of the voucher. The products can be filtered
 *  using the category dropdown at the top.
 *  Since the selected products should be returned as an array, they are internally stored as such and
 *  therefore no changeset is used.
 *
 * @class new-order-dialog/products-select-dialog
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  store: service(),
  uiAppSettings: service(),
  currentUser: service(),
  uiDialog: service(),

  /**
   * the order changeset that is passed by the HOC
   *
   * @property orderChangeset
   * @type {Changeset}
   * @default null
   */
  orderChangeset: null,

  /**
   * the product categories that can be used to filter
   *
   * @property _categories
   * @type {[Object]}
   * @default undefined
   */
  _categories: undefined,

  /**
   * whether the form has been touched
   *
   * @property _isTouched
   * @type {Boolean}
   * @default false
   * @private
   */
  _isTouched: false,

  /**
   * show error if no count is set
   *
   * @property _isNoCountError
   * @type {Boolean}
   * @default false
   * @private
   */
  _isNoCountError: false,

  /**
   * the selected the category used to filter the products
   *
   * @property _selectedCategory
   * @type {Object}
   * @default null
   * @private
   */
  _selectedCategory: null,

  /**
   * the products changeset
   *
   * @property _changeset
   * @type {[Object]}
   * @default undefined
   * @private
   */
  _changeset: undefined,

  /**
   * whether the products fetch task is running
   *
   * @computed _isLoading
   * @type {Boolean}
   */
  _isLoading: computed.alias('fetchProducts.isRunning'),

  /**
   * the sum of all the selected product prices multiplied by their count
   *
   * @computed _totalAmount
   * @type {Number}
   */
  _totalAmount: computed('_changeset.products.@each.count', {
    get() {
      if (this._changeset) {
        return this._changeset
          .get('products')
          .reduce((sum, product) => sum + (product.count || 0) * product.price, 0);
      }
      return 0;
    }
  }),

  /**
   * the filtered products that are shown in the dialog
   *
   * @computed _filteredProducts
   * @type {[Object]}
   */
  _filteredProducts: computed('_changeset.products.[]', '_selectedCategory.id', {
    get() {
      if (this._selectedCategory && this._selectedCategory.id !== 'all') {
        return this._changeset
          .get('products')
          .filter((product) => product.category === this._selectedCategory.id);
      }

      return this._changeset && this._changeset.get('products');
    }
  }),

  /**
   * Called when the user clicks on the close icon
   *
   * @function onClose
   */
  onClose() {},

  /**
   * Called when valid form is submitted
   *
   * @function onSubmit
   * @param {[Object]} products
   */
  onSubmit() {},

  /**
   * fetches the available products of the voucher
   *
   * @type {Task}
   * @function fetchProducts
   */
  fetchProducts: task(function* () {
    const voucherId = this.orderChangeset && this.orderChangeset.get('voucher.id');
    if (voucherId) {
      const adapter = this.store.adapterFor('voucher');
      const voucherUrl = adapter.buildURL('voucher', voucherId);
      const language = this.orderChangeset.get('language') || 'de';

      const response = yield this.authenticatedFetch.fetch(`${voucherUrl}/products`, {
        headers: {
          'Accept-Language': language
        }
      });

      const json = yield response.json();

      // only needed properties are kept and count is added
      const products =
        json &&
        json.products.map((product) => {
          return {
            id: product.id,
            title: product.title,
            price: product.price,
            category: product.productCategory,
            count: null
          };
        });

      // set the count properties in case there are already preselected products
      const initialProducts = this.orderChangeset.get('voucher.products');
      if (initialProducts) {
        initialProducts.forEach((initialProduct) => {
          const product = products.find((product) => product.id === initialProduct.id);
          product && set(product, 'count', initialProduct.count);
        });
      }

      // initialiaze changeset with all available products
      const validation = productsValidation(this.intl);
      const changeset = new Changeset({ products }, lookupValidator(validation), validation);
      set(this, '_changeset', changeset);
    }
  }).on('didInsertElement'),

  /**
   * fetches the categories of the products
   *
   * @type {Task}
   * @function fetchCategories
   */
  fetchCategories: task(function* () {
    const voucherProductCategoryIds =
      this.orderChangeset && this.orderChangeset.get('voucher.productCategories');
    if (voucherProductCategoryIds) {
      // fetch all product categories
      const productCategories = yield this.store.findAll('product-category');

      // filter categories to just include categories of current voucher
      const voucherProductCategories = ((productCategories && A(productCategories)) || []).filter(
        (category) => voucherProductCategoryIds.indexOf(category.id) >= 0
      );

      // add "all" option
      const selectedCategory = { title: this.intl.t('global.all'), id: 'all' };
      const categories = [selectedCategory].concat(voucherProductCategories);
      setProperties(this, { _categories: categories, _selectedCategory: selectedCategory });
    }
  }).on('didInsertElement'),

  /**
   * validates the selected products and invokes the callback
   *
   * @type {Task}
   * @function submitProducts
   */
  submitProducts: task(function* () {
    set(this, '_isTouched', true);

    yield this._changeset.validate();

    if (this._changeset.get('isInvalid')) {
      return;
    }

    const products = this._changeset
      .get('products')
      .filter((product) => product.count && product.count > 0);

    if (products && products.length > 0) {
      this.onSubmit(products);
      this.onClose();
    } else {
      // if no count is set, set error
      set(this, '_isNoCountError', true);
    }
  }),

  actions: {
    /**
     * closes the dialog
     *
     * @function onClose
     */
    onClose() {
      if (this._changeset.isDirty) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          this._changeset.rollback();
          this.onClose();
        });
      } else {
        this.onClose();
      }
    },

    /**
     * passes the validated products to the callback
     *
     * @function onSubmit
     */
    onSubmit() {
      this.submitProducts.perform();
    },

    /**
     *  resets the count error
     *
     * @onInputChange
     */
    onInputChange() {
      set(this, '_isNoCountError', false);
    }
  }
});
