import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';

/**
 * Validates typography item
 *
 * Should later be extended to validate correct hex-colors and the size format to
 * @param {Service} intl
 */
export const fontObjectValidations = (intl) => {
  return {
    fontFamily: [
      validatePresence({ presence: true, message: intl.t('global.errors.required') }),
      validateFormat({ type: 'string', message: intl.t('global.errors.invalidFormat') })
    ],
    fontSize: [
      validatePresence({ presence: true, message: intl.t('global.errors.required') }),
      validateFormat({ type: 'string', message: intl.t('global.errors.invalidFormat') })
    ],
    lineHeight: [
      validatePresence({ presence: true, message: intl.t('global.errors.required') }),
      validateFormat({ type: 'string', message: intl.t('global.errors.invalidFormat') })
    ]
  };
};

/**
 * Validates typography item
 *
 * Should later be extended to validate correct hex-colors and the size format to
 * @param {Service} intl
 */
export const typographyValidations = (intl) => {
  return {
    fontFamily: [
      validatePresence({ presence: true, message: intl.t('global.errors.required') }),
      validateFormat({ type: 'string', message: intl.t('global.errors.invalidFormat') })
    ]
  };
};
