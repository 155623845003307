import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { categoryValidation } from 'additive-voucher/validations/category';

export default class InstanceVouchersCategoriesCategory extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service uiPaths;

  categoryValidation = null;

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  constructor() {
    super(...arguments);

    this.categoryValidation = categoryValidation(this.intl);

    // set options for media selection
    this.multimedia.setSelectionOptions({
      maxCount: 1,
      type: 'image',
      warningMessage: this.intl.t('global.multimedia.imageWarning')
    });
  }

  /**
   * transitions to multimedia route
   *
   * @function openMultimedia
   */
  @action
  openMultimedia() {
    this.router.transitionTo('instance.vouchers.categories.category.additive-multimedia-engine');
  }

  /**
   * transitions back to categories route
   *
   * @function closeMultimedia
   */
  @action
  closeMultimedia() {
    this.router.transitionTo('instance.vouchers.categories.category.index');
  }

  /**
   * adds a given resource to the category
   *
   * @function addMedium
   */
  @action
  addMedium(changeset, _, [item]) {
    changeset.set('image', item);
  }

  /**
   * removes resource from category
   *
   * @function onResourceRemove
   */
  @action
  onResourceRemove(changeset) {
    changeset.set('image', null);
    changeset.execute();
  }

  /**
   * Save category
   *
   * @function onSave
   */
  @action
  onSave(record) {
    return record.save();
  }

  /**
   * Close category dialog
   *
   * @function onClose
   */
  @action
  onClose() {
    this.router.transitionTo('instance.vouchers.categories');
  }
}
