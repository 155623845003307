import Component from '@ember/component';
import { computed } from '@ember/object';

/**
 * This component shows the order element to select a roomType if the voucher is a stay voucher
 * When a room type is selected the selected room type is shown, along with the edit icon.
 *
 * @class new-order-dialog/order-element-rooms
 */
export default Component.extend({
  /**
   * The complete order changeset
   *
   * @property orderChangeset
   * @type {Object}
   */
  orderChangeset: null,

  /**
   * whether the element to select the rooms should be shown
   *
   * @computed isStayVooucher
   * @type {Boolean}
   */
  isStayVoucher: computed.equal('orderChangeset.voucher.type', 'stay'),

  /**
   * the callback triggered when the room type is selected
   *
   * @function onRoomTypeSelect
   */
  onRoomTypeSelect() {}
});
