import ENV from 'additive-voucher/config/environment';
import Controller from '@ember/controller';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { htmlSafe } from '@ember/string';
import fetch from 'fetch';
import { loadFont } from '@additive-apps/utils/utils/fonts';

import merge from 'lodash.merge';

import Changeset from 'ember-changeset';

const DEFAULT_INSTANCE = 'testhotel-post-com';

const options = {
  selectorId: 'voucher-preview',
  instance: DEFAULT_INSTANCE,
  locale: 'de',
  config: {
    typography: {
      embedFonts: false,
      names: {
        primary: {
          normal: '',
          light: '',
          bold: ''
        },
        secondary: {
          normal: '',
          light: '',
          bold: ''
        }
      }
    },
    categories: { defaultView: false },
    scrollOnInit: false
  }
};

import { StyleValues } from './defaults';
import { alias, equal } from '@ember/object/computed';

import { getColorOptions, getFontOptions } from 'additive-voucher/utils/constants/styles';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  router: service(),
  store: service(),
  uiPaths: service(),
  uiAppSettings: service(),
  uiState: service(),
  uiToast: service(),

  /**
   * The available colors of the widget
   *
   * @property colorOptions
   * @type {Array}
   * @default null
   */
  colorOptions: null,

  /**
   * The typography defined in the corporate design
   *
   * @property corporateDesignTypography
   * @type {Object}
   * @default null
   */
  corporateDesignTypography: null,

  /**
   * The background color of the voucher-widget preview.
   * We use the selected ambient color as the background color of the widget preview.
   *
   * @property previewBgColor
   * @type {String}
   */
  previewBgColor: null,

  /**
   * The currently selected language of the widget
   *
   * @property selectedLanguage
   * @type {String}
   */
  selectedLanguage: options.locale,

  /**
   * The currently selected organization. It's either "placeholder" content
   * with the instance "testhotel-post-com" or the own organization
   *
   * @property selectedOrg
   * @type {Object}
   */
  selectedOrg: null,

  /**
   * The previous background color of the voucher-widget preview.
   *
   * @property _prevPreviewBgColor
   * @type {String}
   * @private
   */
  _prevPreviewBgColor: null,

  /**
   * Default language of the widget
   *
   * @property defaultLanguage
   * @type {String}
   */
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),

  /**
   * whether the data is being fetched
   *
   * @computed isLoading
   * @type {Boolean}
   */
  isLoading: alias('setup.isRunning'),

  /**
   * Available languages of the widget
   *
   * @property languages
   * @type {Array}
   */
  languages: alias('uiAppSettings.languages.contentLanguages'),

  /**
   * The id of the organization
   *
   * @property orgId
   * @type {String}
   */
  orgId: alias('currentUser.currentOrganization.id'),

  /**
   * Whether the current user has role "viewer"
   *
   * @property isViewer
   * @type {Boolean}
   */
  isViewer: alias('currentUser.isViewer'),

  // Initialize content of select (placeholder content / own  content)
  availableOrgs: computed('orgId', function () {
    const orgs = [
      {
        name: this.intl.t('widgets.configurator.general.placeholderContent'),
        value: options.instance
      },
      { name: this.intl.t('widgets.configurator.general.ownContent'), value: this.orgId }
    ];

    return orgs;
  }),

  /**
   * The htmlSafe styles of widget-preview
   * @property previewBackgroundStyle
   * @type {String}
   */
  previewBackgroundStyle: computed('previewBgColor', {
    get() {
      return htmlSafe(`background-color: ${this.previewBgColor}`);
    }
  }),

  /**
   * Whether current organization is testhotel-post-com
   *
   * @property isTestInstance
   * @type {String}
   */
  _isTestInstance: equal('orgId', DEFAULT_INSTANCE),

  init() {
    this._super(...arguments);
  },

  /* Fetch vouchers. If vouchers for the current organization exist then use the content
   * of the organization otherwise use placeholder content
   */
  _fetchVouchers: task(function* () {
    try {
      let tasks = [];

      const url = this.uiPaths.pathsByRouteName('instance.vouchers', this.orgId).api().url;
      tasks.push(
        fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/vnd.additive+json; version=2',
            'Accept-Language': options.locale,
            'Content-Type': 'application/json'
          }
        })
      );

      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[WIDGET CONFIGURATOR] Error in response');
      }

      const vouchers = yield response.json();

      // Use own content if vouchers exist otherwise use placeholder content
      if (vouchers.vouchers && Array.isArray(vouchers.vouchers) && vouchers.vouchers.length > 0) {
        set(this, 'selectedOrg', {
          name: this.intl.t('widgets.configurator.general.ownContent'),
          value: this.orgId
        });

        options.instance = this.orgId;
      } else {
        set(this, 'selectedOrg', {
          name: this.intl.t('widgets.configurator.general.placeholderContent'),
          value: options.instance
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  setup: task(function* () {
    const basicTabs = [
      { title: this.intl.t('widgets.configurator.general.title'), name: 'general' },
      { title: this.intl.t('widgets.configurator.colors.title'), name: 'colors' },
      { title: this.intl.t('widgets.configurator.typography.title'), name: 'typography' },
      { title: this.intl.t('widgets.configurator.tracking.title'), name: 'tracking' }
    ];

    set(this, 'basicTabs', basicTabs);
    set(this, 'activeTab', basicTabs[0]);
    set(this, 'selectedLanguage', options.locale);

    try {
      let tasks = [];

      const request = yield this.authenticatedFetch.fetch(
        `${ENV.APP.apiBaseHost}/${this.orgId}/corporate-design`
      );

      // load corporate design definition
      tasks.push(request);
      // fetch vouchers
      tasks.push(this._fetchVouchers.perform());
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        // handle widget teardown
        return;
      }

      const { corporateDesign } = yield response.json();
      // destruct config and styles from the current widget-model
      const { config, styles, tracking } = this.model;

      const { colors, fonts } = corporateDesign;
      const fontOptions = getFontOptions(fonts);
      const colorOptions = getColorOptions(colors);

      if (corporateDesign.fonts) {
        yield this.loadFonts(corporateDesign.fonts);
      }

      set(this, 'fonts', fonts);
      set(this, 'fontOptions', fontOptions);
      set(this, 'colorOptions', colorOptions);
      // build the available font-options

      let style = merge(
        {},
        {
          colors: StyleValues.colors,
          typography: StyleValues.typography,
          globals: StyleValues.globals,
          container: StyleValues.container,
          components: StyleValues.components
        },
        {
          colors: corporateDesign.colors,
          typography: corporateDesign.typography
        },
        {
          colors: styles.colors,
          typography: styles.typography,
          globals: styles.globals,
          container: styles.container,
          components: styles.components
        }
      );

      set(this, 'corporateDesignTypography', corporateDesign.typography);

      /* Initialize background-color of widget preview */
      const { sync, color, syncedColor } = style.colors.ambient;
      if (sync) {
        set(this, 'previewBgColor', syncedColor);
      } else {
        set(this, 'previewBgColor', color);
      }
      set(this, '_prevPreviewBgColor', color);
      set(this, 'styles', style);

      this.model.set('styles', style);
      const changeset = new Changeset(this.model);
      set(this, 'changeset', changeset);

      const widgetOptions = merge({}, options, Array.isArray(config) ? {} : { config }, {
        tracking
      });
      set(this, 'options', widgetOptions);

      /* Dynamic import of voucher-widget */
      const widget = yield import('@additive-apps/voucher-widget');
      set(this, 'instance', widget);
    } catch (error) {
      return;
    }
  }),

  loadFonts(fonts) {
    const fontPromises = [];
    Object.keys(fonts).forEach((key) => {
      Object.keys(fonts[key]).forEach((type) => {
        fonts[key][type] && fontPromises.push(loadFont(`${key}${type}`, fonts[key][type].url));
      });
    });
    Promise.all(fontPromises).catch((error) => {
      throw new Error('[WIDGET CONFIGURATOR] Could not load font', error);
    });

    return Promise.allSettled(fontPromises);
  },

  save: task(function* () {
    try {
      yield this.changeset.save();
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (error) {
      // TODO
      return error;
    }
  }),

  actions: {
    back() {
      if (this.changeset.get('isDirty')) {
        set(this, 'isDiscardChangesDialog', true);
      } else {
        // TODO: Move instance.unmount() into additive-configurator
        if (this.instance && this.instance.unmount) {
          // Destroy current widget instance
          this.instance.unmount(this.options.selectorId);
        }
        this.router.transitionTo('instance.widgets.index');
        set(this, 'instance', null);
        set(this, 'styles', null);
        set(this, 'options', null);
        set(this, 'selectedLanguage', 'de');
        options.instance = DEFAULT_INSTANCE;
      }
    },
    changeLanguage(fnc, lang) {
      typeof fnc === 'function' && fnc('locale', lang, 'options');
    },
    changeOrg(fnc, org) {
      set(this, 'selectedOrg', org);
      typeof fnc === 'function' && fnc('instance', org.value, 'options');
    },
    toggleDetail() {
      this.uiState.getState('widget-detail').toggle();
    },
    confirmDiscard() {
      // rollback changeset
      const changeset = this.changeset;
      changeset && changeset.rollback();
      this.model?.rollbackAttributes();
      set(this, 'isDiscardChangesDialog', false);
      this.send('back');
    },
    onUpdate(onUpdate, key, val) {
      /* We use the ambient color as background color of the widget preview */
      if (key === 'colors.ambient') {
        if (val.sync) {
          /* Use synced color */
          set(this, 'previewBgColor', this.styles.colors.ambient.syncedColor);
        } else {
          if (val.color) {
            /* Use custom color */
            set(this, 'previewBgColor', val.color);
            set(this, '_prevPreviewBgColor', val.color);
          } else {
            /* Sync was deactivated, use previous background-color */
            set(this, 'previewBgColor', this._prevPreviewBgColor);
          }
        }
      }

      const data = onUpdate(key, val);

      this.changeset.set('options', data.options);
      this.changeset.set('styles', data.styles);
    }
  }
});
