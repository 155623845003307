import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';

import validateIf from '@additive-apps/utils/validators/validate-if';

export const customerValidation = (intl, isNew = false) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    givenName: validatePresence({ presence: true, message: presenceMessage }),
    familyName: validatePresence({ presence: true, message: presenceMessage }),
    email: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({ type: 'email', message: emailMessage })
    ],
    companyName: validateIf({
      validate: [validatePresence({ presence: true, message: presenceMessage })],
      if: (key, newValue, oldValue, changes, content) => {
        return getProperty('isCompany', changes, content);
      },
      firstErrorMessage: true
    }),
    companyVatId: validateIf({
      validate: [validatePresence({ presence: true, message: presenceMessage })],
      if: (key, newValue, oldValue, changes, content) => {
        return getProperty('isCompany', changes, content);
      },
      firstErrorMessage: true
    }),
    companyRecipientCode: validateIf({
      validate: [validatePresence({ presence: true, message: presenceMessage })],
      if: (key, newValue, oldValue, changes, content) => {
        return (
          getProperty('isCompany', changes, content) &&
          getProperty('addressCountry', changes, content) === 'IT' &&
          ('companyRecipientCode' in changes || isNew)
        );
      },
      firstErrorMessage: true
    })
  };
};

const getProperty = (property, changes, content) => {
  if (property in changes) {
    return changes?.[property];
  }
  return content?.[property];
};
