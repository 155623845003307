import ApplicationSerializer from './application';
import { centsToAmount, amountToCents } from 'additive-voucher/utils/currency-serialization';

/* Transform deep value "voucher.amount" into integer amount*/
const normalizeOrder = (order) => {
  if (order && order.voucher && order.voucher.amount) {
    order.voucher.amount = centsToAmount(order.voucher.amount);
    order.voucher.minAmount = centsToAmount(order.voucher.minAmount);
    order.voucher.maxAmount = centsToAmount(order.voucher.maxAmount);
  }
  return order;
};

export default class OrderSerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'query' || requestType === 'findAll') {
      payload.orders = payload.orders.map((order) => normalizeOrder(order));
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

    let order = normalizeOrder(payload.order);
    return super.normalizeResponse(store, primaryModelClass, { order }, id, requestType);
  }

  serialize() {
    let json = super.serialize(...arguments);

    /* Transform deep value "voucher.amount" into float amount*/
    if (json.voucher && json.voucher.amount) {
      const voucher = Object.assign({}, json.voucher, {
        amount: amountToCents(json.voucher.amount),
        minAmount: amountToCents(json.voucher.minAmount),
        maxAmount: amountToCents(json.voucher.maxAmount)
      });
      json.voucher = voucher;
    }

    // delete payment type if its of type `online` because the API expects value null
    if (json.paymentType && json.paymentType === 'online') {
      delete json.paymentType;
    }

    return json;
  }
}
