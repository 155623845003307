import ENV from 'additive-voucher/config/environment';
import Controller from '@ember/controller';

import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';

export default Controller.extend({
  authenticatedFetch: service(),
  intl: service(),
  router: service(),
  uiDialog: service(),
  uiFilter: service(),
  uiToast: service(),
  currentUser: service(),

  isDownloadDialog: false,

  isViewer: computed.alias('currentUser.isViewer'),

  /**
   * Returns the last segment of the current URL.
   * @computed _currentRouteIndex
   * @return {String} The last segment of the current URL.
   */
  _currentRouteIndex: computed('router.currentURL', {
    get() {
      return this.router.currentURL.split('/').pop();
    }
  }),
  /**
   * Deletes a single voucher entity
   *
   * @param {Object} order
   * @type {Task}
   * @function _deleteOrder
   */
  deleteOrder: task(function* (order) {
    try {
      let tasks = [];

      // delete voucher and keep the task running for at least 250ms
      tasks.push(order.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);
      yield this.uiFilter.loadModel('orders');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success'),
        duration: 2000
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop(),

  /**
   * fetches the download link for the orders considering the selected filter options
   *
   * @type {Task}
   * @function generateDownloadLink
   */
  generateDownloadLink: task(function* () {
    const currentPath = this.router.urlFor('instance.orders');
    const _baseUrl = `${ENV.APP.apiBaseHost}${currentPath}`;
    let queryParameters = '';

    const dataFilter = this.uiFilter.getQPFilter('orders');
    let orderFilterData =
      dataFilter.filter && dataFilter.filter.data && dataFilter.filter.data.query;

    // build query parameter string from object
    if (orderFilterData && typeof orderFilterData === 'object') {
      const urlSearchParams = new URLSearchParams();

      Object.keys(orderFilterData).forEach((key) => {
        // ignore pagination filters on export
        if (['page', 'per_page'].includes(key)) {
          return;
        }

        urlSearchParams.append(key, orderFilterData[key]);
      });

      queryParameters = urlSearchParams.toString();
    }

    const request = yield this.authenticatedFetch.fetch(`${_baseUrl}/export?${queryParameters}`);
    if (request.ok) {
      set(this, 'isDownloadDialog', true);
    } else {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }),

  actions: {
    deleteOrder(order) {
      this.uiDialog.showConfirm(
        this.intl.t('orders.deleteOrder.title'),
        this.intl.t('orders.deleteOrder.description', {
          name: order.identifier,
          htmlSafe: true
        }),
        () => this.deleteOrder.perform(order),
        this.intl.t('global.actions.delete'),
        true,
        true
      );
    },
    deleteVoucher(order) {
      const { intl } = this;
      this.uiDialog.showConfirm(
        intl.t('vouchers.voucher.deleteVoucher.title'),
        intl.t('vouchers.voucher.deleteVoucher.description', {
          name: order && order.name,
          htmlSafe: true
        }),
        () => {
          this.deleteOrder.perform(order);
        },
        intl.t('global.actions.delete'),
        true,
        true
      );
    }
  }
});
