import { validatePresence } from 'ember-changeset-validations/validators';
import { validateNumeral } from '@additive-apps/utils/validators/numeral';
import validateEach from '@additive-apps/utils/validators/validate-each';

export const shippingValidations = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const numberMessage = intl.t('errors.number');

  return {
    name: validatePresence({ presence: true, message: presenceMessage }),
    description: validatePresence({ presence: true, message: presenceMessage }),
    prices: validateEach({
      countries: validatePresence({ presence: true, message: presenceMessage }),
      price: [
        validateNumeral({ positive: true, message: numberMessage }),
        validatePresence({ presence: true, message: presenceMessage })
      ]
    })
  };
};
