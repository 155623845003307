import Component from '@ember/component';
import { task, all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { centsToAmount } from 'additive-voucher/utils/currency-serialization';

const AMOUNT_METRICS = ['amountSumMonthly', 'amountSumYearly'];

export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  uiAppSettings: service(),
  uiPaths: service(),

  /**
   * the current organization slug
   *
   * @property organizationSlug
   * @type {String}
   * @default null
   */
  organizationSlug: null,

  /**
   * the current selected period
   *
   * @property period
   * @type {Object}
   * @default null
   */
  period: null,

  /**
   * the organization slug of which the chart-data was fetched,
   * used to detect whether the slug has changes
   *
   * @property _previousOrganization
   * @type {String}
   * @default null
   * @private
   */
  _previousOrganization: null,

  /**
   * the period which was used to fetch the chart-data,
   * used to detect whether the period changed
   *
   * @property _previousPeriod
   * @type {Object}
   * @default null
   * @private
   */
  _previousPeriod: null,

  /**
   * the table data
   *
   * @property orderSummary
   * @type {Object}
   * @default null
   */
  orderSummary: null,

  /**
   * whether an error occured during the fetch task
   *
   * @property _isKeyIndicatorsError
   * @type {Boolean}
   * @default false
   */
  _isKeyIndicatorsError: false,

  init() {
    this._super(...arguments);
    set(this, '_previousOrganization', this.organizationSlug);
    set(this, '_previousPeriod', this.period);
  },

  didUpdateAttrs() {
    this._super();
    // reload indicator data on organization change
    if (this.organizationSlug !== this._previousOrganization) {
      this.loadKeyIndicators.perform();
      set(this, '_previousOrganization', this.organizationSlug);
    }

    // reload key indicators on period change
    if (JSON.stringify(this.period) !== JSON.stringify(this._previousPeriod)) {
      this.loadKeyIndicators.perform();
      set(this, '_previousPeriod', this.period);
    }
  },

  /**
   * <slug>/dashboard/key-indicators
   *
   * Loads the organisations key indicatory data
   *
   * @param ordersCountMonthly
   * @param ordersCountYearly
   * @param amountSumMonthly
   * @param amountSumYearly
   *
   * @throws {Error}
   */
  loadKeyIndicators: task(function* () {
    try {
      let tasks = [];
      const url = this.uiPaths
        .pathsByRouteName('instance.dashboard', this.organizationSlug)
        .api().url;
      // load key-indicators
      if (this.period) {
        tasks.push(
          this.authenticatedFetch.fetch(`${url}/dashboard/key-indicators?year=${this.period.value}`)
        );
      } else {
        tasks.push(this.authenticatedFetch.fetch(`${url}/dashboard/key-indicators`));
      }
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[DASHBOARD] Error in response');
      }

      set(this, '_isKeyIndicatorsError', false);
      const orderSummary = yield response.json();

      AMOUNT_METRICS.forEach((property) => {
        const prop = orderSummary[property].current.value;
        if (prop) {
          orderSummary[property].current.value = centsToAmount(prop);
          if (orderSummary[property].compare) {
            orderSummary[property].compare.value = centsToAmount(
              orderSummary[property].compare.value
            );
          }
        }
      });

      set(this, 'orderSummary', orderSummary);
    } catch (error) {
      set(this, '_isKeyIndicatorsError', true);
      throw new Error('[DASHBOARD] Could not load key-indicators');
    }
  }).on('init')
});
