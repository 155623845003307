import { validateNumeral } from '@additive-apps/utils/validators/numeral';
import {
  validatePresence,
  validateFormat,
  validateLength
} from 'ember-changeset-validations/validators';

export const packageValidations = (intl) => {
  const maxLengthName = intl.t('errors.maxLength', { maxLength: 40 });
  const maxLengthDescription = intl.t('errors.maxLength', { maxLength: 120 });
  const presenceMessage = intl.t('errors.required');
  const greaterZeroMessage = intl.t('errors.greaterZero');
  const amountFormatMessage = intl.t('errors.amountFormat');

  return {
    name: [
      validateLength({ max: 40, message: maxLengthName }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    description: [
      validateLength({ max: 120, message: maxLengthDescription }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    price: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    shippings: validateLength({ min: 1, message: presenceMessage })
  };
};
