import Route from '@ember/routing/route';
import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import {
  showWarningDialogBeforeClose,
  removeWarningDialogBeforeClose
} from '@additive-apps/ui/utils/close-tab-utils';

import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Route.extend(FullWidthMixin, {
  intl: service(),
  multimedia: service(),
  router: service(),

  renderTemplate() {
    this.render('instance/widgets/index/widget', {
      into: 'application'
    });
  },

  setupController(controller) {
    this._super(...arguments);

    showWarningDialogBeforeClose(
      this.router,
      this.router.currentRouteName?.slice(0, -6),
      () => controller.changeset.isDirty,
      () => set(controller, 'isDiscardChangesDialog', true),
      true
    );
  },
  model(params) {
    return this.store.findRecord('widget', params.widget_id);
  },
  afterModel(model) {
    if (model.isV1) {
      this.transitionTo('instance.widgets.index');
    } else {
      this._super(...arguments);
    }
  },
  resetController(controller) {
    this._super(...arguments);

    removeWarningDialogBeforeClose(this.router);

    const tabs = controller.basicTabs;
    set(controller, 'activeTab', tabs[0]);
  }
});
