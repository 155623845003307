import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';

export const widgetValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const mustStartWithLetter = intl.t('errors.mustStartWithLetter');
  const formatMessage = intl.t('errors.invalidWidgetName');

  return {
    name: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[A-Za-z].*?$/,
        message: mustStartWithLetter
      }),
      validateFormat({
        regex: /^[a-zA-Z][\w :.-]*$/,
        message: formatMessage
      })
    ]
  };
};
