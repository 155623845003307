import Controller from '@ember/controller';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';
import { set, computed } from '@ember/object';

export default Controller.extend({
  authenticatedFetch: service(),
  intl: service(),
  uiFilter: service(),
  uiPaths: service(),
  uiToast: service(),
  uiDialog: service(),
  router: service(),
  currentUser: service(),

  queryParams: ['category', 'type'],
  category: null,
  type: null,

  isViewer: computed.alias('currentUser.isViewer'),

  /**
   * Returns the last segment of the current URL.
   * @computed _currentRouteIndex
   * @return {String} The last segment of the current URL.
   */
  _currentRouteIndex: computed('router.currentURL', {
    get() {
      return this.router.currentURL.split('/').pop();
    }
  }),
  /**
   * Deletes a single voucher entity
   *
   * @param {Object} voucher
   * @type {Task}
   * @function _deleteVoucher
   * @private
   */
  _deleteVoucher: task(function* (voucher) {
    try {
      let tasks = [];

      // delete voucher and keep the task running for at least 250ms
      tasks.push(voucher.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);
      yield this.uiFilter.reset('vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success'),
        duration: 2000
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop(),

  /**
   * Bulk deletes voucher entities, for a given ids-array, in case the delete operation
   * was successfull the list gets reloaded.
   *
   * @param {Array} ids
   * @type {Task}
   * @function _bulkDeleteVouchers
   * @private
   */
  _bulkDeleteVouchers: task(function* (ids = []) {
    try {
      let tasks = [];
      if (ids.length === 0) {
        throw new Error('[VOUCHER BULK] Ids param empty');
      }
      const url = this.uiPaths.pathsByRouteName('instance.vouchers').api().url;

      // save voucher and keep the task running for at least 250ms
      tasks.push(
        yield this.authenticatedFetch.fetch(`${url}/bulk`, {
          method: 'DELETE',
          body: JSON.stringify({ ids })
        })
      );
      tasks.push(timeout(250));

      yield all(tasks);
      // reset all filters and reload list
      yield this.uiFilter.reset('vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.bulkDelete', { count: ids.length }),
        duration: 2000
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop(),

  actions: {
    deleteVoucher(voucher) {
      const { intl } = this;
      this.uiDialog.showConfirm(
        intl.t('vouchers.voucher.deleteVoucher.title'),
        htmlSafe(
          intl.t('vouchers.voucher.deleteVoucher.description', {
            name: voucher && voucher.name
          })
        ),
        () => {
          this._deleteVoucher.perform(voucher);
        },
        intl.t('global.actions.delete'),
        true,
        true
      );
    },
    bulkDeleteVouchers(ids = []) {
      const { intl } = this;
      this.uiDialog.showConfirm(
        intl.t('vouchers.voucher.bulkDeleteVouchers.title'),
        htmlSafe(intl.t('vouchers.voucher.bulkDeleteVouchers.description')),
        () => {
          this._bulkDeleteVouchers.perform(ids);
        },
        intl.t('vouchers.voucher.bulkDeleteVouchers.action'),
        true,
        true
      );
    },
    transitionToVoucherDetail(id) {
      // Reset category query param before routing to detail
      set(this, 'category', null);
      this.router.transitionTo('instance.vouchers.index.voucher', id);
    }
  }
});
