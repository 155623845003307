import Component from '@ember/component';
import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set, setProperties } from '@ember/object';
import { or, alias } from '@ember/object/computed';
import { isPresent, isNone } from '@ember/utils';

/**
 * This component lets the user select a voucher out of the existing vouchers.
 * Vouchers are fetched privately without fallback and only the public ones.
 *
 * The user can filter by category and language (select).
 *
 * It pre-selects the given voucher and category out of the order-changeset.
 *
 * @class new-order-dialog/voucher-select-dialog
 */
export default Component.extend({
  store: service(),
  router: service(),
  uiAppSettings: service(),

  /**
   * The complete order changeset
   *
   * @property orderChangeset
   * @type {Object}
   */
  orderChangeset: null,

  /**
   * The existing public categories
   *
   * @computed _categories
   * @type {[Object]}
   * @private
   */
  _categories: null,

  /**
   * The existing public vouchers
   *
   * @computed _vouchers
   * @type {[Object]}
   * @private
   */
  _vouchers: null,

  /**
   * The current selected category
   *
   * @computed _selectedCategory
   * @type {Object}
   * @private
   */
  _selectedCategory: null,

  /**
   * The vouchers filtered by current selected category
   *
   * @computed _vouchersPerCategory
   * @type {[Object]}
   * @private
   */
  _vouchersPerCategory: null,

  /**
   * The current selected voucher
   *
   * @computed _selectedVoucher
   * @type {[Object]}
   * @private
   */
  _selectedVoucher: null,

  /**
   * The organization default language
   *
   * @computed languages
   * @type {[String]}
   */
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),

  /**
   * Global languages the organization has enabled
   *
   * @computed languages
   * @type {[String]}
   */
  languages: alias('uiAppSettings.languages.contentLanguages'),

  /**
   * whether the fetch tasks are running
   *
   * @computed _isLoading
   * @type {Boolean}
   * @private
   */
  _isLoading: or('_fetchCategories.isRunning', '_fetchVouchers.isRunning'),

  /**
   * whether the list elements are loading
   *
   * @computed _isListElementsLoading
   * @type {Boolean}
   * @private
   */
  _isListElementsLoading: or('_isLoading', '_listElements.isRunning'),

  /**
   * Fetch public categories
   */
  _fetchCategories: task(function* () {
    try {
      const categories = yield this.store.findAll('category') || [];
      set(this, '_categories', categories.toArray());

      if (
        isNone(this._selectedCategory) &&
        isPresent(this.orderChangeset.get('voucher.categoryId'))
      ) {
        const selectedCategoryId = this.orderChangeset.get('voucher.categoryId');
        const selectedCategory = categories.filter((category) => {
          return selectedCategoryId === category.id;
        });
        /* When api returned categories contains the currenty selected category, use that */
        set(this, '_selectedCategory', selectedCategory.length === 1 ? selectedCategory[0] : null);
      } else {
        const firstCategory = this._categories[0];
        set(this, '_selectedCategory', firstCategory);
      }

      /* Preselect first category, when selectedCategory not already defined */
      isNone(this._selectedCategory) && set(this, '_selectedCategory', categories[0]);
    } catch (error) {
      /* throw new Error(
        `[new-order-dialog/voucher-select-dialog] Could not fetch categories: ${error}`
      ); */
    }
  }),

  /**
   * Fetch public vouchers
   */
  _fetchVouchers: task(function* () {
    try {
      const response = yield this.store.query('voucher', { no_fallback: 1, per_page: 100 });

      /**
       * As we dont use ember data for public request it also does not
       * use model-props with its transformations/serializations,
       * so normalize amount props manually
       */
      const vouchers = response.map((voucher) => {
        const json = voucher.toJSON();

        return Object.assign({}, json, {
          id: voucher.id,
          amount: json.amount,
          discountedAmount: json.discountedAmount || 0
        });
      });

      set(this, '_vouchers', vouchers);
    } catch (error) {
      // TODO: Show error state
      throw new Error(`[new-order-dialog/voucher-select-dialog] Could not fetch vouchers ${error}`);
    }
  }),

  /**
   * Task to fetch categories and vouchers
   * and provide vouchers of current selected category
   */
  _listElements: task(function* () {
    try {
      let tasks = [];

      /* Only fetch when no models exists */
      !this._categories && tasks.push(this._fetchCategories.perform());
      !this._vouchers && tasks.push(this._fetchVouchers.perform());

      tasks.push(timeout(250));

      yield all(tasks);

      if (!Array.isArray(this._vouchers) || this._vouchers.length === 0) {
        return;
      }

      set(
        this,
        '_vouchersPerCategory',
        this._vouchers.filter((voucher) => {
          return voucher.category === this._selectedCategory.id;
        })
      );
    } catch (error) {
      /* throw new Error(`[new-order-dialog/voucher-select-dialog] Could not fetch models ${error}`); */
    }
  }).on('init'),

  init() {
    this._super(...arguments);

    /* Pre-set selected voucher when whe already have a selected voucher */
    if (this.orderChangeset && this.orderChangeset.get('voucher.id')) {
      set(this, '_selectedVoucher', this.orderChangeset.get('voucher'));
    } else {
      this.uiAppSettings.setLocale(this.defaultLanguage);
    }
  },

  onClose() {},
  onSubmit() {},

  actions: {
    onCategoryChange(newCat) {
      setProperties(this, {
        _selectedVoucher: null,
        _selectedCategory: newCat
      });

      this._listElements.perform();
    },
    onSubmit() {
      if (!this._selectedVoucher) {
        return;
      }

      this.onSubmit(this._selectedVoucher);

      set(this, '_selectedVoucher', null);
    },
    onChangeLanguage(lang) {
      this.uiAppSettings.setLocale(lang);
      this.orderChangeset.set('language', lang);

      // reset properties
      setProperties(this, {
        _selectedVoucher: null,
        _selectedCategory: null,
        _categories: null,
        _vouchers: null,
        _vouchersPerCategory: null
      });

      // reload categories and vouchers with new language
      this._listElements.perform();
    }
  }
});
