import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Route.extend({
  intl: service(),
  uiFilter: service(),

  setupController(controller) {
    this._super(...arguments);

    this.uiFilter.register('orders', 'order', {
      queryParams: {
        state: {
          defaultValue: ''
        },
        type: {
          defaultValue: [],
          multiple: true
        }
      }
    });

    const intl = this.intl;

    set(controller, 'stateOptions', [
      { value: 'issued', name: intl.t('orders.order.state.issued') },
      { value: 'ordered', name: intl.t('orders.order.state.ordered') },
      { value: 'paid', name: intl.t('orders.order.state.paid') },
      { value: 'gift', name: intl.t('orders.order.state.gift') },
      { value: 'redeemed', name: intl.t('orders.order.state.redeemed') },
      { value: 'partial_redeemed', name: intl.t('orders.order.state.partial_redeemed') },
      { value: 'canceled', name: intl.t('orders.order.state.canceled') },
      { value: 'expired', name: intl.t('orders.order.state.expired') }
    ]);

    set(controller, 'typeOptions', [
      { value: 'value', name: intl.t('orders.order.type.value') },
      { value: 'service', name: intl.t('orders.order.type.service') },
      { value: 'product', name: intl.t('orders.order.type.product') },
      { value: 'treatment', name: intl.t('orders.order.type.treatment') },
      { value: 'stay', name: intl.t('orders.order.type.stay') },
      { value: 'gift', name: intl.t('orders.order.type.gift') }
    ]);
  },

  resetController() {
    this._super(...arguments);

    this.uiFilter.unregister('orders');
  }
});
