import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Controller.extend({
  router: service(),
  uiAppSettings: service(),
  uiFilter: service(),
  currentUser: service(),

  /**
   * returns true if the active route is the widgets list route
   * @computed isWidgetsRoute
   * @return {Boolean} whether the widgets route is active or not
   */
  isWidgetsRoute: computed('router.currentRouteName', {
    get() {
      return this.router.isActive('instance.widgets.index');
    }
  }),

  isProfessionalPlan: computed.alias('uiAppSettings.isProfessionalPlan'),
  isViewer: computed.alias('currentUser.isViewer'),
  widgetCount: computed.alias('_widgetsFilter.data.length'),

  /**
   * If true the user can't create an other widget and we show him a
   * an upgrade message.
   * @computed hasUpgradeMessage
   */
  hasUpgradeMessage: computed('isProfessionalPlan', 'widgetCount', {
    get() {
      return !this.isProfessionalPlan && this.widgetCount === 1;
    }
  }),

  /**
   * Reference to the underlying QueryParams-Instance.
   * @computed _filterObject
   * @return {QueryParameters}
   * @private
   */
  _widgetsFilter: computed({
    get() {
      const dataFilter = this.uiFilter.getQPFilter('widgets');
      return dataFilter && dataFilter.filter;
    }
  })
});
