import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  language: () => faker.random.arrayElement(['de', 'it', 'en']),
  currency: () => 'EUR',
  title: () => faker.lorem.words(6),
  text: () => faker.lorem.paragraph(),
  layout: () => faker.random.arrayElement(['portrait', 'landscape']),
  templateId: () => faker.lorem.slug(),
  sourceType: 'widget',
  sourceUrl: () => faker.internet.url(),
  image: null,
  state: () => faker.random.arrayElement(['ordered', 'paid', 'redeemed']),
  voucher: {
    id: () => faker.random.uuid(),
    name: () => faker.lorem.words(3),
    type: () => faker.random.arrayElement(['value', 'product', 'service', 'treatment', 'stay']),
    validityPeriod: 'one_year'
  },
  customer: {
    id: () => faker.random.uuid(),
    email: () => faker.internet.email,
    gender: () => faker.random.arrayElement(['m', 'f']),
    givenName: () => faker.name.firstName(),
    familyName: () => faker.name.lastName(),
    telephone: () => faker.phone.phoneNumber(),
    streetAddress: () => faker.address.streetAddress(),
    postalCode: () => faker.address.zipCode(),
    addressLocality: () => faker.address.city(),
    addressCountry: () => faker.address.countryCode(),
    isCompany: false
  },
  paymentType: 'bank_transfer',
  amountWithoutDiscount: () => faker.random.number(),
  amountWithDiscount: () => faker.random.number(),
  amountToPay: () => faker.random.number(),
  amountLeft: () => faker.random.number(),
  identifier: () => '2022-00008',
  previewUrl: null,
  pdfUrl: null,
  orderedAt: '2022-03-21T11:12:43+00:00',
  expiresAt: '2023-03-21',
  redeemCode: () => `${faker.random.alphaNumeric(4)}-${faker.random.alphaNumeric(4)}`,
  shipping: {
    id: () => faker.random.uuid(),
    name: 'E-Mail',
    type: 'email',
    price: 0
  }
});
