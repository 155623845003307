import RESTSerializer from '@ember-data/serializer/rest';
import { isNone } from '@ember/utils';

export default class ApplicationSerializer extends RESTSerializer {
  serialize() {
    var json = super.serialize(...arguments);

    Object.keys(json).forEach((key) => isNone(json[key]) && delete json[key]);

    return json;
  }

  serializeIntoHash(data, type, record, options) {
    Object.assign(data, this.serialize(record, options));
  }
}
