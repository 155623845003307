import ApplicationSerializer from './application';

export default class CategorySerializer extends ApplicationSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    if (!json.image) {
      json.image = null;
    }

    return json;
  }
}
