export default {
  time: {
    hhmm: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    }
  },
  date: {
    ddmmyyyy: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    long: {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    },
    short: {
      day: '2-digit',
      month: 'short'
    }
  },
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    CHF: {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};
