import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Route.extend({
  intl: service(),
  uiFilter: service(),

  setupController(controller) {
    this._super(...arguments);

    this.uiFilter.register('vouchers', 'voucher', {
      queryParams: {
        category: {
          defaultValue: ''
        },
        type: {
          defaultValue: [],
          multiple: true
        }
      }
    });

    this.uiFilter.register('categories', 'category', {
      limit: 40,
      filterable: false,
      searchable: false,
      paginated: false
    });

    const intl = this.intl;

    set(controller, 'typeOptions', [
      { value: 'value', name: intl.t('vouchers.types.value') },
      { value: 'service', name: intl.t('vouchers.types.service') },
      { value: 'product', name: intl.t('vouchers.types.product') },
      { value: 'treatment', name: intl.t('vouchers.types.treatment') },
      { value: 'stay', name: intl.t('vouchers.types.stay') }
    ]);
  },

  resetController() {
    this._super(...arguments);

    this.uiFilter.unregister('vouchers');
    this.uiFilter.unregister('categories');
  }
});
