import Model, { attr } from '@ember-data/model';

export default class WidgetModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') selector;
  @attr('string', { defaultValue: '2' }) version;
  @attr('string') templateId;
  @attr() availableLanguages;

  @attr({
    defaultValue() {
      return {};
    }
  })
  styles;

  @attr({
    defaultValue() {
      return {};
    }
  })
  config;

  @attr({
    defaultValue() {
      return {};
    }
  })
  tracking;

  get state() {
    return (this.availableLanguages || []).indexOf(this.language) > -1 ? 'success' : 'error';
  }

  get isV1() {
    return this.version === '1';
  }
}
