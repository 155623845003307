import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';

export default Controller.extend({
  currentUser: service(),
  router: service(),

  _readonly: not('currentUser.isManager'),

  actions: {
    backToList() {
      this.router.transitionTo('instance.settings.shipping-package');
    }
  }
});
