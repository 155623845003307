import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"pv2\">\n  <NewOrderDialog::OrderElement\n    @label={{t \"dashboard.newOrderDialog.orderTotalAmount.label\"}}\n    @hasContent={{false}}\n    @isReadOnly={{true}}\n    as |element|\n  >\n    <element.right @classNames=\"font-lg\">\n      {{ui-format-currency\n        this.totalAmount\n        this.uiAppSettings.currency\n        this.currentUser.user.language\n      }}\n    </element.right>\n  </NewOrderDialog::OrderElement>\n</div>", {"contents":"<div class=\"pv2\">\n  <NewOrderDialog::OrderElement\n    @label={{t \"dashboard.newOrderDialog.orderTotalAmount.label\"}}\n    @hasContent={{false}}\n    @isReadOnly={{true}}\n    as |element|\n  >\n    <element.right @classNames=\"font-lg\">\n      {{ui-format-currency\n        this.totalAmount\n        this.uiAppSettings.currency\n        this.currentUser.user.language\n      }}\n    </element.right>\n  </NewOrderDialog::OrderElement>\n</div>","moduleName":"additive-voucher/components/new-order-dialog/order-total-amount.hbs","parseOptions":{"srcName":"additive-voucher/components/new-order-dialog/order-total-amount.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { arg } from 'ember-arg-types';
import { number } from 'prop-types';

/**
 * This component represents a order-element
 * with the total amount of the order.
 *
 * It formats the given amount-number as currency.
 *
 * @class NewOrderDialogOrderTotalAmount
 */
export default class NewOrderDialogOrderTotalAmount extends Component {
  @service uiAppSettings;
  @service currentUser;
  /**
   * The totalAmount
   *
   * @property totalAmount
   * @type {Number}
   */
  @arg(number)
  @tracked
  totalAmount = 0;
}
