import Component from '@ember/component';
import { computed, set } from '@ember/object';
import Changeset from 'ember-changeset';
import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';

/**
 * Detail navbar for an order object.
 *
 * Currently it allows adding and editing a notice to an order.
 *
 *
 * @class detail-navbar
 * @module av-order
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  uiPaths: service(),
  uiToast: service(),

  tagName: '',

  /**
   * The current order model
   *
   * @property order
   * @type {Object}
   * @private
   */
  order: null,

  /**
   * Array holding all the history-entries made
   * for the current order object
   *
   * @property history
   * @type {Array}
   */
  history: null,

  /**
   * The property is only used as a reference in order to trigger
   * the didUpdateAttry after a new history entry is made.
   *
   * @property amountLeft
   * @type {Integer}
   */
  amountLeft: null,

  /**
   * Whether the navdrawer is read only
   *
   * @property isReadOnly
   * @type {Boolean}
   */
  isReadOnly: false,

  /**
   * Internal changeset, it's only used for the orders note
   *
   * @property _changeset
   * @type {Object}
   * @private
   */
  _changeset: null,

  customerName: computed('order.{identifier,customer.givenName,customer.familyName}', {
    get() {
      const order = this.order;

      if (order.customer && (order.customer.givenName || order.customer.familyName)) {
        return `${order.customer.givenName || ''} ${order.customer.familyName || ''}`;
      }

      return `${order.identifier}`;
    }
  }),

  didUpdateAttrs() {
    this._super(...arguments);
    this.loadHistory.perform();
  },

  /**
   * Clousure that closes the right sidenav
   * @function onClose
   */
  onClose() {},

  /**
   * Adds/Updates an orders notice
   *
   * @function loadHistory
   * @type {Task}
   * @throws {Error}
   */
  loadHistory: task(function* () {
    try {
      const tasks = [];

      const baseUrl = this.uiPaths.pathsByRouteName('instance.orders.order').api(this.order.id).url;

      // load last orders
      tasks.push(this.authenticatedFetch.fetch(`${baseUrl}/history`));
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response) {
        throw new Error('[ORDER - HISTORY] Error in response');
      } else {
        const { history } = yield response.json();
        set(this, 'history', history);
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }).on('init'),

  /**
   * Adds/Updates an orders notice
   *
   * @function saveNotice
   * @type {Task}
   * @throws {Error}
   */
  saveNotice: task(function* () {
    try {
      let tasks = [];

      // load last orders
      tasks.push(this._changeset.save());
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response) {
        throw new Error('[ORDER - NOTICE] Error in response');
      } else {
        set(this, 'isNoteDialog', false);
        this.onClose();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),
  actions: {
    openNoteDialog() {
      const changeset = new Changeset(this.order);
      set(this, '_changeset', changeset);
      set(this, 'isNoteDialog', true);
    }
  }
});
