import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { action } from '@ember/object';
import { array, boolean, func, object } from 'prop-types';

/**
 * @class fonts
 * @module av-widget-configuration
 */
export default class AvWidgetConfigurationFontsComponent extends Component {
  /**
   * Whether the options should be read-only
   *
   * @argument isReadOnly
   * @type {Boolean}
   */
  @arg(boolean)
  isReadOnly = false;

  /**
   * the available font options
   *
   * @argument fontOptions
   * @type {Array}
   */
  @arg(array)
  fontOptions = [];

  /**
   * The styles object
   *
   * @property styles
   * @type {Object}
   */
  @arg(object)
  styles = null;

  /**
   * A list of typography keys to use
   *
   * @argument typographyKeys
   * @type {object}
   */
  @arg(array)
  typographyKeys = null;

  /**
   * whether a warning should be shown on selected custom font
   *
   * @argument showCustomFontWarning
   * @type {Boolean}
   * @default false
   */
  @arg(boolean)
  showCustomFontWarning = false;

  /**
   * the current widget changeset
   *
   * @argument changeset
   * @type {object}
   */
  @arg(object)
  @tracked
  changeset = null;

  /**
   * the current widget options
   *
   * @argument options
   * @type {object}
   */
  @arg(object)
  @tracked
  options = null;

  /**
   * The typography defined in the corporate design
   *
   * @argument corporateDesignTypography
   * @type {object}
   */
  @arg(object)
  @tracked
  corporateDesignTypography = null;

  /**
   * the available fonts for non standard fonts
   * example for a font key like `primary.bold`
   *
   * @argument typographyKeys
   * @type {object}
   */
  @arg(object)
  @tracked
  fonts = null;

  /**
   * whether the fontname dialog is visible
   *
   * @argument isFontNameDialog
   * @type {boolean}
   */
  @tracked
  isFontNameDialog = false;

  /**
   * the selected font for setting the font name.
   *
   * @argument selectedFont
   * @type {object}
   */
  @tracked
  selectedFont = null;

  /**
   * the selected font type (primary/secondary)
   *
   * @argument selectedFontType
   * @type {string}
   */
  @tracked
  selectedFontType = null;

  @arg(func)
  onUpdate() {}

  @action
  showFontNameDialog(type) {
    if (!this.fonts[type]) {
      return;
    }

    this.isFontNameDialog = true;
    this.selectedFont = this.fonts[type];
    this.selectedFontType = type;
  }

  @action
  onCloseFontNameDialog() {
    this.isFontNameDialog = false;
    this.selectedFont = null;
    this.selectedFontType = null;
  }

  @action
  toggle(key, value) {
    this.changeset.set(`config.typography.${key}`, value);
    this.changeset.execute();
  }
}
