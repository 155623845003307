import ENV from 'additive-voucher/config/environment';
import AuthorizationAdapter from '@additive-apps/auth/adapters/application';
import { dasherize } from '@ember/string';

import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default AuthorizationAdapter.extend({
  currentUser: service(),
  uiAppSettings: service(),

  host: ENV.APP.apiBaseHost,

  currentLocale: computed.alias('uiAppSettings.currentLocale'),

  headers: computed('currentLocale', 'session.data.authenticated.access_token', {
    get() {
      const headers = {
        Accept: 'application/vnd.additive+json; version=2',
        'Accept-Language': this.currentLocale || 'de',
        'Content-Type': 'application/json'
      };

      return Object.assign({}, this._super('headers'), headers);
    }
  }),

  namespace: computed('currentUser.currentOrganization.id', {
    get() {
      return `${this.currentUser?.currentOrganization?.id}`;
    }
  }),

  pathForType() {
    return dasherize(this._super(...arguments));
  }
});
