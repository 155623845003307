import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import { CUSTOM_VALIDITY_PERIOD } from 'additive-voucher/validations/order';

// With validityPeriod set to custom we have to check, that validityPeriodCustomDate is set properly
export default function validateValidityPeriodCustomDate(options = {}) {
  return (key, newValue, oldValue, changes, content) => {
    const currentValidityPeriod =
      (changes && changes.validityPeriod) || (content && content.validityPeriod);
    if (currentValidityPeriod !== CUSTOM_VALIDITY_PERIOD) {
      // when validityPeriod is not custom we do not have to check anything
      return true;
    }

    // TODO: use datepicker and afterwards make sure manually setted date in input is proper formatted. Currently forced by html input type="date"
    const value = new Date(newValue);
    if (value && inFuture(value)) {
      return true;
    }

    return buildMessage(key, { type: 'specificValue', newValue, context: options });
  };
}

const inFuture = (date) => {
  return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
};
