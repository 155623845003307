import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

/**
 * HOC to output the price<->countries pairs
 */
export default Component.extend({
  uiLocale: service(),
  localization: service(),

  tagName: '',

  changeset: null,
  isReadOnly: false,
  _allCountries: null,

  _prices: computed('changeset.{prices.[]}', {
    get() {
      if (!this.changeset) {
        return [];
      }

      return this.changeset.get('prices');
    }
  }),

  init() {
    this._super(...arguments);

    const locale = this.uiLocale && this.uiLocale.locale;

    this.localization.getCountries(locale || 'de').then((countries) => {
      set(this, '_allCountries', countries || []);
    });
  },

  onPriceUpdate() {},
  onPriceDelete() {}
});
