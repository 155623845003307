import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),

  renderTemplate() {
    this.render('instance/orders/order', {
      into: 'instance/orders',
      outlet: 'detail'
    });
  },

  model(params) {
    return this.store.findRecord('order', params.order_id);
  }
});
