import ENV from 'additive-voucher/config/environment';

const DEFAULTS = {
  contentLanguages: ['de', 'en', 'it'],
  defaultLanguage: 'de'
};

const accountApiRequests = (server, orgSlug) => {
  server.get(`${ENV.APP.accountApiHost}/api/organizations`, (schema) => {
    return schema.organizations.all();
  });

  server.get(`${ENV.APP.accountApiHost}/api/organizations/${orgSlug}`, (schema) => {
    return schema.organizations.findBy({ id: orgSlug });
  });

  server.get(`${ENV.APP.accountApiHost}/api/organizations/${orgSlug}/apps`, () => {
    return {
      apps: [
        {
          id: 2,
          name: 'voucher',
          planName: 'starter_monthly',
          active: true,
          trialEndsAt: '3018-09-11'
        },
        {
          id: 3,
          name: 'social-wall',
          planName: 'starter_monthly',
          trialEndsAt: '2010-09-11',
          active: true
        }
      ]
    };
  });
};

export const starter = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'starter_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};

export const professional = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'professional_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};

export const enterprise = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'enterprise_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};
