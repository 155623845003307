import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  authenticatedFetch: service(),
  store: service(),
  uiAppSettings: service(),

  model(params) {
    this._super(...arguments);

    return this.store.findRecord('category', params.category_id);
  }
});
