import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  uiFilter: service(),

  setupController() {
    this._super(...arguments);

    this.uiFilter.register('widgets', 'widget', { paginated: false });
    this.uiFilter.register('templates', 'template', { paginated: false });
  },

  resetController() {
    this._super(...arguments);

    this.uiFilter.unregister('widgets');
    this.uiFilter.unregister('templates');
  }
});
