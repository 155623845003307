import { getOwner } from '@ember/application';
import { isEmpty } from '@ember/utils';
import {
  is as planIs,
  PLAN_STARTER,
  PLAN_PROFESSIONAL,
  PLAN_ENTERPRISE
} from '@additive-apps/utils/utils/plans';

import PlanError from 'additive-voucher/plan-management/plan-error';

/**
 * Plan restrictions for payment types are:
 *
 * -----------
 * Starter:
 *  - Only bank_transfer & PayPal
 * Professional:
 *  - Starter + one additional
 * Enterprise:
 *  - No restrictions, can use all payment types
 * -----------
 *
 * The restrictions are for enabled payment types only,
 * creating is possibile without any plan-restriction.
 *
 * @function canAddPaymentType
 * @param {Object} toAddPaymentType The payment type the user wants to add
 * @param {Object} self An object with an owner
 */
export const canAddPaymentType = (toAddPaymentType, self) => {
  const store = getOwner(self).lookup('service:store');
  const currentPlan = _currentPlan(self);

  /* if bank-tranfser and paypal wants to be added, allow it as this is allowed for any plan  */
  if (['bank_transfer', 'paypal'].indexOf(toAddPaymentType.type) >= 0) {
    return true;
  }

  /* ENTERPRISE */
  if (planIs(PLAN_ENTERPRISE, currentPlan)) {
    return true;
  }

  /* PROFESSIONAL */
  /* only enabled payment-types are needed for check */
  const paymentTypes = store
    .peekAll('payment-type')
    .filterBy('enabled', true)
    .rejectBy('type', 'cash');

  if (planIs(PLAN_PROFESSIONAL, currentPlan)) {
    /**
     * As bank_transfer and paypal is allowed by starter,
     * we ignore them and get only the extra payment types, beside those two
     */
    const extraPaymentTypes = paymentTypes.filter(
      (pt) => ['bank_transfer', 'paypal', 'cash'].indexOf(pt.type) === -1
    );

    if (extraPaymentTypes.length >= 1) {
      throw new PlanError('plan_allowed_payment_types_exceeded');
    }
  }

  /* STARTER */
  if (
    planIs(PLAN_STARTER, currentPlan) &&
    ['bank_transfer', 'paypal'].indexOf(toAddPaymentType.type) === -1
  ) {
    throw new PlanError('starter_not_allowed_payment_type');
  }

  return true;
};

/**
 * Return the current org-app plan by uiAppSettings service
 *
 * @function _currentPlan
 * @param {Object} self An object with an owner
 * @private
 */
const _currentPlan = (self) => {
  const uiAppSettings = getOwner(self).lookup('service:ui-app-settings');
  const plan = uiAppSettings && uiAppSettings.planName;

  if (isEmpty(plan)) {
    throw new Error('[plan-management] Plan not available');
  }

  return plan;
};
