import Controller from '@ember/controller';
import { set } from '@ember/object';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  currentUser: service(),
  router: service(),

  isNewDialog: false,

  _isMember: not('currentUser.isManager'),

  actions: {
    newRoute(route, param) {
      this.router.transitionTo(route, param);
      set(this, 'isNewDialog', false);
    }
  }
});
