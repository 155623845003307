/**
 * server is the default scenario for normal app-use (development, staging)
 */
export default function(server) {
  server.passthrough('https://staging.account.additive-apps.tech/**');
  server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
  server.passthrough('https://api.staging.voucher.additive-apps.tech/**');
  server.passthrough('https://helper.additive-apps.tech/**');

  server.passthrough(`${server.urlPrefix}/${server.namespace}/`);
}
