import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

/**
 * Takes a voucher, builds the correct pricing depending on type,
 * discount and amount and formats to german locale
 * @class av-amount-display
 * @module voucher-helpers
 */
export default Helper.extend({
  intl: service(),

  compute([voucher, currency]) {
    if (voucher && voucher.amount > 0) {
      const { isDiscounted, amount, discountedAmount, discountedPercentage } = voucher;

      // we need to divide by 1 in order to assert the value used by intl is a valid number
      const formattedAmount = (amount / 1).toLocaleString('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      const formattedDiscount = ((discountedAmount || 0) / 1).toLocaleString('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      if (discountedPercentage === 100) {
        return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithIncentive', {
          amount: formattedAmount
        });
      } else if (isDiscounted) {
        return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithDiscount', {
          amount: formattedAmount,
          discount: formattedDiscount || 0
        });
      }

      return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithoutDiscount', {
        amount: formattedAmount
      });
    }

    return '';
  }
});
