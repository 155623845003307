import Component from '@ember/component';

export default Component.extend({
  onConfirm() {},
  onClose() {},

  actions: {
    issueOrder() {
      this.onConfirm('issued', true);
      this.onClose();
    }
  }
});
