import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import { computed } from '@ember/object';
import { assert } from '@ember/debug';
import { task, timeout, all } from 'ember-concurrency';

/**
 * Global, reusable component which manages delete on ed-models.
 *
 * TODO:
 *  - Define default behaviour, i.e. for errors, toasts, etc.
 *  - Move to UI when done
 *  - Use in all apps
 *
 * @class ui-delete-dialog
 */
export default Component.extend({
  intl: service(),
  uiDialog: service(),

  tagName: '',

  /**
   * The actual ed-model to be deleted
   * @property item
   * @type {Object}
   */
  item: null,

  /**
   * The dialogs title
   * @property title
   * @type {String}
   */
  title: '',

  /**
   * Description for the delete-dialog.
   * @property description
   * @type {String}
   */
  description: '',

  isLoading: computed.alias('deleteItem.isRunning'),

  /**
   * htmlSafe description
   * @computed _description
   * @type {String}
   */
  _description: computed('description', {
    get() {
      return htmlSafe(this.description || '');
    }
  }),

  init() {
    this._super(...arguments);
    assert(`You need to pass a valid ed-model as item.`, this.item);
  },

  /**
   * Called when the user clicks on the close icon
   * @function onClose
   * @return {Any}
   */
  onClose() {},

  /**
   * Called after the ed-model gets successfully deleted
   * @function onDelete
   * @return {Any}
   */
  onDelete() {},

  deleteItem: task(function* () {
    try {
      const tasks = [this.item.destroyRecord(), timeout(250)];

      yield all(tasks);

      yield this.onDelete();
      /* TODO: When toast is finally done this.uiToast */
    } catch (error) {
      if (error?.errors?.length > 0) {
        const errorData = error.errors[0];

        // handle conflict error
        if (errorData.status === 409) {
          const { intl } = this;

          if (this.item.constructor.modelName === 'widget') {
            this.uiDialog.showConflictError(
              intl.t('global.deleteWidget.conflictError.title'),
              intl.t('global.deleteWidget.conflictError.description'),
              errorData.dependencies
            );
            return;
          }
        }
      }

      throw new Error('[NEW-ORDER] fetch payment-types', error);
    } finally {
      this.onClose();
    }
  }).drop()
});
