import ENV from 'additive-voucher/config/environment';
import Controller from '@ember/controller';

import { task, timeout, all } from 'ember-concurrency';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';

import Changeset from 'ember-changeset';
import { validateFormat } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { reject } from 'rsvp';

import { syncServices } from 'additive-voucher/utils/constants';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiAppSettings: service(),
  uiDialog: service(),
  uiToast: service(),

  isLoading: computed.alias('loadGeneral.isRunning'),
  organizationId: computed.alias('currentUser.currentOrganization.id'),
  isMember: computed.not('currentUser.isManager'),

  loadGeneral: task(function* () {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      const request = yield this.authenticatedFetch.fetch(`${url}/settings`);
      // save voucher and keep the task running for at least 250ms
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] general', response);
      } else {
        // destructure response
        const { settings } = yield response.json();

        const generalResource = Object.assign({}, settings);

        const validation = {
          email: validateFormat({
            type: 'email',
            message: this.intl.t('errors.email'),
            allowBlank: true
          }),
          redeemCodePrefix: validateFormat({
            regex: /^[A-Z]{0,4}$/,
            message: this.intl.t('errors.unlockCode.prefix'),
            allowBlank: true
          })
        };

        const { name, address, email, phone, redeemCodePrefix } = settings;
        const changeset = new Changeset(
          {
            name: name.value,
            address: address.value,
            email: email.value,
            phone: phone.value,
            redeemCodePrefix: redeemCodePrefix
          },
          lookupValidator(validation),
          validation
        );

        set(this, 'generalResource', generalResource);
        set(this, 'syncServices', syncServices);
        set(this, 'changeset', changeset);
      }

      const notificationSettingsResponse = yield this.authenticatedFetch.fetch(
        `${url}/notification-settings`
      );
      const { notificationSettings } = yield notificationSettingsResponse.json();
      const notificationChangeset = new Changeset(
        {
          ...notificationSettings
        },
        true
      );

      set(this, 'notificationChangeset', notificationChangeset);
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  /**
   * Updates the general-settings
   *
   * @param {Object} changeset the changeset
   * @param {Boolean} forceUpdate if true the update will be executed regardless of the changeset internal state
   * @type {Task}
   * @function updateSettings
   */
  updateSettings: task(function* (changeset, forceUpdate = false) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      yield changeset.validate();

      // reject if there are errors or no changes have been made
      if (!forceUpdate && (changeset.get('isInvalid') || changeset.get('isPristine'))) {
        return reject();
      }

      const changes = changeset.get('changes');

      // save data to underlying object
      yield changeset.save();
      const settingsPayload = yield changeset.get('data');

      const body = Object.assign({}, this.generalResource);

      body?.name && set(body, 'name.value', settingsPayload.name);
      body?.address && set(body, 'address.value', settingsPayload.address);
      body?.email && set(body, 'email.value', settingsPayload.email);
      body?.phone && set(body, 'phone.value', settingsPayload.phone);
      set(body, 'redeemCodePrefix', settingsPayload.redeemCodePrefix);

      if (['email', 'phone'].indexOf(changes?.[0]?.key) !== -1) {
        set(body, `${changes[0].key}.sync`, !changes[0].value);
      }

      const request = yield this.authenticatedFetch.fetch(`${url}/settings`, {
        headers: { 'Accept-Language': this.currentLanguage || 'de' },
        method: 'PUT',
        body: JSON.stringify(body)
      });
      // save voucher and keep the task running for at least 250ms
      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error(`[SETTINGS - UPDATE] general ${response}`);
      } else {
        const { settings } = yield response.json();

        syncServices.forEach((syncService) => {
          set(this, `generalResource.${syncService}Sync`, settings[`${syncService}Sync`]);
          set(this, `generalResource.${syncService}User`, settings[`${syncService}User`]);
          set(
            this,
            `generalResource.${syncService}HasPassword`,
            settings[`${syncService}HasPassword`]
          );
        });

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  toggleSync: task(function* (name) {
    try {
      yield timeout(250);
      const synced = this.generalResource[name].sync;
      set(this, `generalResource.${name}.sync`, !synced);
      yield this.updateSettings.perform(this.changeset, true);
    } catch (error) {
      throw new Error(`[SETTINGS - SYNC] general sync ${error}`);
    }
  }),

  toggleNotificationSettings: task(function* (property) {
    try {
      this.notificationChangeset.set(property, !this.notificationChangeset.get(property));

      const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;
      const body = Object.keys(this.notificationChangeset.get('data')).reduce((acc, key) => {
        acc[key] = this.notificationChangeset.get(key);
        return acc;
      }, {});

      const response = yield this.authenticatedFetch.fetch(`${url}/notification-settings`, {
        headers: { 'Accept-Language': this.currentLanguage || 'de' },
        method: 'PUT',
        body: JSON.stringify(body)
      });

      if (!response?.ok) throw response;

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success')
      });
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  actions: {
    forceUpdateSettings() {
      return this.updateSettings.perform(this.changeset, true);
    }
  }
});
