import Route from '@ember/routing/route';

import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import { inject as service } from '@ember/service';

import { expirationFormats } from 'additive-voucher/utils/constants';
import { baseValidation, detailValidation } from 'additive-voucher/validations/voucher';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  showWarningDialogBeforeClose,
  removeWarningDialogBeforeClose
} from '@additive-apps/ui/utils/close-tab-utils';

export default class InstanceVouchersIndexVoucherEditRoute extends Route.extend(FullWidthMixin) {
  @service multimedia;
  @service intl;
  @service uiAppSettings;
  @service router;
  @service store;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  renderTemplate() {
    // TODO: Check why renderint into application skips the applications beforeModel-hook
    this.render('instance/vouchers/index/voucher/edit', {
      into: 'application'
    });
  }

  model() {
    const model = super.model(...arguments);

    if (model.language === this.currentLocale) {
      return model;
    }

    // reset multilingual properties
    model.name = null;
    model.description = null;
    model.services = null;
    model.language = this.currentLocale;

    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const intl = this.intl;
    const EXPIRATION_FORMATS = expirationFormats(intl);

    controller.expirations = EXPIRATION_FORMATS;
    controller.selectedExpiration = model.validityPeriod;

    const baseValidator = baseValidation(intl, model.type);
    const detailValidator = detailValidation(intl, model.type);

    const baseChangeset = new Changeset(model, lookupValidator(baseValidator), baseValidator);
    const detailChangeset = new Changeset(model, lookupValidator(detailValidator), detailValidator);

    controller.baseChangeset = baseChangeset;
    controller.detailChangeset = detailChangeset;

    this.multimedia.setSelectionOptions({
      type: 'image',
      maxCount: 10,
      minWidth: 800,
      recommendedWidth: 1200,
      warningMessage: this.intl.t('vouchers.voucher.multimedia.imageWarning')
    });

    showWarningDialogBeforeClose(
      this.router,
      this.router.currentRouteName?.slice(0, -6),
      () => controller.getChangesets()?.isDirty,
      () => controller && (controller.isDiscardChangesDialog = true),
      true
    );
  }

  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);

    removeWarningDialogBeforeClose(this.router);

    if (isExiting && transition.targetName !== 'error') {
      this.uiAppSettings.setLocale(this.defaultLanguage);
      controller.model.rollbackAttributes();
    }

    this.store.unloadAll('product-category');

    this.multimedia.resetSelectionOptions();
  }
}
