import Model, { attr, belongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class VoucherModel extends Model {
  @service uiAppSettings;

  @attr('string') name;
  @attr('string') type;
  @attr('string') language;
  @attr('string') description;
  @attr('string') previewUrl;

  @attr amount;

  @attr interests;
  @attr travelMotivations;
  @attr travelTimes;

  // Wait for API to normalize serialization so we can user the actual relationship
  @attr images;
  @attr availableLanguages;
  @attr publishedLanguages;
  @attr('string') publishedState;
  @belongsTo('category', { async: true }) category;

  // voucher validity
  @attr('string') validityPeriod;
  @attr('string') validityPeriodCustomDate;

  //service
  @attr('string') services;

  // value
  @attr minAmount;
  @attr maxAmount;

  // product
  @attr({ defaultValue: () => [] }) productCategories;

  // treatment
  @attr({ defaultValue: () => [] }) treatmentCategories;

  // stay
  @attr({ defaultValue: () => [] }) roomTypes;
  @attr('number') personCount;
  @attr('number') minStays;

  // locked vouchers
  @attr('boolean') isLocked;
  @attr('string') unlockCode;

  // discounted voucher
  @attr('boolean') isDiscounted;
  @attr('string') discountedAmount;
  @attr('number') discountedPercentage;
  @attr('boolean') hasDiscountPeriod;
  @attr('string') discountPeriodStart;
  @attr('string') discountPeriodEnd;
  @attr('string') publicFrom;
  @attr('string') publicUntil;
  @attr('boolean') isFree;

  // for sort
  @attr('number') rating;

  // voucher is only public for a limited time
  @attr('boolean') timeLimited;

  get hasImage() {
    return this.images?.length > 0;
  }

  get primaryImage() {
    return this.images?.firstObject;
  }

  get discountPercentage() {
    const { discountedAmount, amount } = this;
    if (discountedAmount && amount) {
      return Math.round(((amount - discountedAmount) / amount) * 100);
    }
    return 0;
  }

  @tracked
  _allInterestTags = [];

  @action
  setAllInterestTags() {
    this._allInterestTags = this.interests?.concat(this.travelMotivations, this.travelTimes);
  }
}
