import {
  validatePresence,
  validateNumber,
  validateFormat,
  validateInclusion
} from 'ember-changeset-validations/validators';
import { validateNumeral } from '@additive-apps/utils/validators/numeral';
import validateEach from '@additive-apps/utils/validators/validate-each';
import { validateHTMLLength } from '@additive-apps/utils/validators/html-length';

import validateValidityPeriodCustomDate from 'additive-voucher/validators/validate-validity-period-custom-date';

export const orderValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const maxLengthTitle = intl.t('errors.maxLength', { maxLength: 40 });

  return {
    voucher: function (key, newValue) {
      if (!newValue?.name) {
        return presenceMessage;
      }

      if (!newValue?.roomType && newValue?.type === 'stay') {
        return presenceMessage;
      }

      if (!newValue?.products?.length && newValue?.type === 'product') {
        return presenceMessage;
      }

      if (!newValue?.treatments?.length && newValue?.type === 'treatment') {
        return presenceMessage;
      }

      return true;
    },
    customer: function (key, newValue) {
      if (!newValue.email || !newValue.givenName || !newValue.familyName) {
        return presenceMessage;
      }

      return true;
    },
    paymentType: function (key, newValue, oldValue, changes) {
      if (changes && changes.gift) {
        return true;
      }
      return validatePresence({ presence: true, message: presenceMessage })(...arguments);
    },
    title: validateHTMLLength({ max: 40, message: maxLengthTitle }),
    text: [validatePresence({ presence: true, message: presenceMessage })],
    image: validatePresence({ presence: true, message: presenceMessage })
  };
};

export const customVoucherValidation = (intl) => {
  const amountFormatMessage = intl.t('errors.amountFormat');
  const dateMessage = intl.t('errors.date');
  const greaterZeroMessage = intl.t('errors.greaterZero');
  const maxLengthName = intl.t('errors.maxLength', { maxLength: 50 });
  const presenceMessage = intl.t('errors.required');

  return {
    name: [
      validateHTMLLength({ max: 50, message: maxLengthName }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    amount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    services: validatePresence({ presence: true, message: presenceMessage }),
    validityPeriod: validateInclusion({
      list: EXISTING_VALIDITY_PERIODS,
      message: presenceMessage
    }),
    validityPeriodCustomDate: validateValidityPeriodCustomDate({ message: dateMessage })
  };
};

export const roomTypeValidation = (intl, hasFixedPersonCount) => {
  const presenceMessage = intl.t('errors.required');
  const numberMessage = intl.t('errors.number');

  return {
    id: validatePresence({ presence: true, message: presenceMessage }),
    personCount: validateNumber({
      positive: true,
      integer: true,
      allowBlank: hasFixedPersonCount,
      message: numberMessage
    }),
    stays: validatePresence({ presence: true, message: presenceMessage })
  };
};

export const treatmentsValidation = (intl) => {
  const numberMessage = intl.t('errors.number');

  return {
    treatments: validateEach({
      count: validateNumber({
        positive: true,
        integer: true,
        allowBlank: true,
        message: numberMessage
      })
    })
  };
};

export const productsValidation = (intl) => {
  const numberMessage = intl.t('errors.number');

  return {
    products: validateEach({
      count: validateNumber({
        positive: true,
        integer: true,
        allowBlank: true,
        message: numberMessage
      })
    })
  };
};

export const redeemVoucherValidation = (intl) => {
  const amountFormatMessage = intl.t('errors.amountFormat');
  const greaterZeroMessage = intl.t('errors.greaterZero');
  const presenceMessage = intl.t('errors.required');

  return {
    redeemCode: validatePresence({ presence: true, message: presenceMessage }),
    amount: [
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage,
        allowBlank: true
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ]
  };
};

export const CUSTOM_VALIDITY_PERIOD = 'custom';
export const EXISTING_VALIDITY_PERIODS = [
  'one_year',
  'two_years',
  'three_years',
  'five_years',
  'end_of_year',
  'end_of_next_year',
  'three_years_end_of_year',
  'indefinitely',
  CUSTOM_VALIDITY_PERIOD
];
