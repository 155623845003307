import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Route.extend({
  intl: service(),

  resetController(controller) {
    this._super(...arguments);

    // Reset period select
    set(controller, 'period', {
      name: this.intl.t(`dashboard.periodSelection.last_12_months`),
      value: 'last_12_months'
    });
  }
});
