import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { set, setProperties } from '@ember/object';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import isChangeset from 'ember-changeset/utils/is-changeset';
import { isEmpty } from '@ember/utils';

/**
 * Modalform with standard customer fields.
 *
 * Expected `customer` POJO/Changeset (Both are valid):
 *
 *```js
 *{
 *  gender: 'm|f', //default 'm'
 *  givenName: '',
 *  familyName: '',
 *  email: '',
 *  telephone: '',
 *  addressCountry: '',
 *  addressLocality: '',
 *  postalCode: '',
 *  streetAddress: '',
 *  isCompany: '',
 *  companyName: '',
 *  companyVatId: '',
 *}
 *```
 *
 * It allows passing a validation
 *
 * @class av-customer-form-modal
 */
export default Component.extend({
  localization: service(),
  intl: service(),
  uiLocale: service(),

  tagName: '',

  /**
   * The customer object (empty model or pre-filled)
   *
   * @property customer
   * @type {Object}
   */
  customer: null,

  /**
   * The modal subtitle
   *
   * @property subtitle
   * @type {?String}
   */
  subtitle: '',

  /**
   * A optional customer changeset validation
   *
   * @property validation
   * @type {?Function}
   */
  validation: null,

  /**
   * whether this form creates a new customer
   *
   * @property isNew
   * @type {Boolean}
   */
  isNew: false,

  /**
   * The fetched countries from localizations service
   *
   * @property _countries
   * @type {[Object]}
   * @private
   */
  _countries: null,

  /**
   * The gender options
   *
   * @property _genderOptions
   * @type {[Object]}
   * @private
   */
  _genderOptions: null,

  /**
   * Whether discard changes dialog is shown
   *
   * @property _isDiscardChangesDialog
   * @type {Boolean}
   * @private
   */
  _isDiscardChangesDialog: false,

  /**
   * Whether the input field for companies are shown or not
   *
   * @property _isCompanyEnabled
   * @type {Boolean}
   * @private
   */
  _isCompanyEnabled: false,

  /**
   * Whether form has been submitted
   *
   * @property _submitTouched
   * @type {Boolean}
   * @private
   */
  _submitTouched: false,

  /**
   * The internal changeset object with validation (if given)
   *
   * @computed _changeset
   * @type {Object}
   * @private
   */
  _changeset: computed('customer.data', 'isNew', 'validation', {
    get() {
      /* If changeset has been passed, get data out of changeset */
      const data = isChangeset(this.customer) ? this.customer.data : this.customer;

      /* Use 'm' as gender default, if not provided or empty */
      const dataWithDefault = Object.assign({}, data, { gender: data['gender'] || 'none' });

      let changeset;
      if (isEmpty(this.validation)) {
        changeset = new Changeset(dataWithDefault);
      } else {
        const validation = this.validation(this.intl, this.isNew);
        changeset = new Changeset(dataWithDefault, lookupValidator(validation), validation);
      }

      return changeset;
    }
  }),

  init() {
    this._super(...arguments);

    const intl = this.intl;

    set(this, '_genderOptions', [
      {
        label: intl.t('components.av-customer-form-modal.properties.genders.none'),
        value: 'none'
      },
      {
        label: intl.t('components.av-customer-form-modal.properties.genders.male'),
        value: 'm'
      },
      {
        label: intl.t('components.av-customer-form-modal.properties.genders.female'),
        value: 'f'
      }
    ]);

    const locale = this.uiLocale && this.uiLocale.locale;

    /* Fetch countries */
    this.localization
      .getCountries(locale || 'de')
      .catch((error) => {
        /* TODO: modal-service show unexpectedError Dialog */
        throw new Error('[av-customer-form-modal] fetch countries', error);
      })
      .then((countries) => {
        let _countries = countries.slice();
        _countries.unshift({
          code: '',
          name: intl.t('components.av-customer-form-modal.properties.noCountry')
        });
        set(this, 'countries', _countries);
      });
  },

  /**
   * Called when the user clicks on the close icon
   * @function onClose
   * @return {Any}
   */
  onClose() {},
  /**
   * Called when valid form gets submitted
   * @function onSubmit
   * @param {Object} formData A POJO with form-data
   * @return {Promise}
   */
  onSubmit() {},

  actions: {
    changeCountry(country) {
      set(this, '_changeset.addressCountry', country.code);
    },

    onClose() {
      if (this._changeset.isDirty) {
        set(this, '_isDiscardChangesDialog', true);
      } else {
        this.onClose();
      }
    },

    async onSubmit() {
      const changeset = this._changeset;
      set(this, '_submitTouched', true);

      // unset company-relevant fields if checkbox was unchecked
      if (!changeset.get('isCompany')) {
        setProperties(changeset, {
          companyName: null,
          companyVatId: null
        });
      }

      try {
        await changeset.validate();

        if (changeset.get('isValid')) {
          changeset.execute();
          return this.onSubmit(changeset.data);
        }
      } catch (error) {
        throw new Error('[av-customer-form-modal] onSubmit promise error', error);
      }
    }
  }
});
