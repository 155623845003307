import Model, { attr } from '@ember-data/model';

export default class TemplateModel extends Model {
  @attr('string') name;
  @attr('string') file;
  @attr('string') image;
  @attr('boolean') default;
  @attr('string') previewUrl;
  @attr() colors;
  @attr() typography;
}
