import ENV from 'additive-voucher/config/environment';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { boolean, object } from 'prop-types';

/**
 * @class tracking
 * @module av-widget-configuration
 */
export default class AvWidgetConfigurationTrackingComponent extends Component {
  @service uiLocale;

  /**
   * Whether the options should be read-only
   *
   * @argument isReadOnly
   * @type {Boolean}
   */
  @arg(boolean)
  isReadOnly = false;

  /**
   * the changeset
   *
   * @argument changeset
   * @type {object}
   */
  @arg(object)
  @tracked
  changeset = null;

  get guideUrl() {
    const language = this.uiLocale.locale || 'de';
    return `${ENV.APP.cdnHost}/${ENV.APP.awsBucket}/manuals/tracking-guide-${language}.pdf`;
  }

  @action
  openGuide() {
    window.open(this.guideUrl, '_blank');
  }
}
