import Transform from '@ember-data/serializer/transform';

import { centsToAmount, amountToCents } from 'additive-voucher/utils/currency-serialization';

export default class PriceTransform extends Transform {
  deserialize(serialized) {
    return serialized && centsToAmount(serialized);
  }

  serialize(deserialized) {
    return deserialized && amountToCents(deserialized);
  }
}
