import { equal } from '@ember/object/computed';
import Component from '@ember/component';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence, validateLength } from 'ember-changeset-validations/validators';

import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

const DEFAULT_VALIDATION = (intl, options) => {
  const presenceMessage = intl.t('errors.required');

  const validations = [];
  validations.push(validatePresence({ presence: true, message: presenceMessage }));
  if (options.maxlength) {
    const maxlengthMessage = intl.t('errors.maxLength', { maxLength: options.maxlength });
    validations.push(validateLength({ max: options.maxlength, message: maxlengthMessage }));
  }

  let returnObject = {};
  returnObject[options.propertyName] = validations;

  return returnObject;
};

/**
 * Component to render a simple dialog with an input field.
 * By passing the options you controll the view/behavior of this dialog.
 *
 * By default it has a required validation, but you can controll it by passing a validation
 *
 * @class new-order-dialog/simple-change-dialog
 */
export default Component.extend({
  tagName: '',

  intl: service(),

  /**
   * The changeset
   *
   * @property changeset
   * @type {Object}
   */
  changeset: null,

  /**
   * Whether the form has been submitted minimum once,
   * to show errors if exists
   *
   * @property submitTouched
   * @type {Boolean}
   */
  submitTouched: false,

  /**
   * Pass a options hash with following fields:
   *    - initialValue  The initial value
   *    - label         The label to describe property
   *    - propertyName  The name of the property
   *    - type          Which type should be used "text" (html-input) or "select" (html-select)
   *    - selectOptions Options for type "select" (optional when not type "select")
   *    - maxlength     The max count of the characters
   *
   * @property options
   * @type {Object}
   */
  options: undefined,

  /**
   * To overwrite default validation
   *
   * @function validation
   */
  validation: null,

  /**
   * Whether the input should be a text-field
   *
   * @property isTypeText
   * @type {Boolean}
   * @private
   */
  isTextarea: equal('options.propertyName', 'text'),

  /**
   * Whether the input should be a text-field
   *
   * @property isTypeText
   * @type {Boolean}
   * @private
   */
  isTypeText: equal('options.type', 'text'),
  /**
   * Whether the input should be a select-field
   *
   * @property isTypeSelect
   * @type {Boolean}
   * @private
   */
  isTypeSelect: equal('options.type', 'select'),

  /**
   * The current value.
   *
   * @computed value
   * @type {Any}
   * @private
   */
  value: computed('changeset', 'options.propertyName', {
    get() {
      return this.changeset.get(this.options?.propertyName);
    },
    set(_, value) {
      this.changeset.set(this.options?.propertyName, value);
      return value;
    }
  }),

  /**
   * The error message if exists.
   *
   * @computed errorMessage
   * @type {String}
   * @private
   */
  errorMessage: computed('changeset', 'options.propertyName', 'submitTouched', 'value', {
    get() {
      return this.changeset.get(`error.${this.options?.propertyName}.validation`);
    }
  }),

  init() {
    this._super(...arguments);

    this.options?.type === undefined && set(this, 'options.type', 'text');

    const intl = this.intl;
    const validationProp = this.options?.validation;
    const options = this.options;
    const validation =
      (validationProp && validationProp(intl)) || DEFAULT_VALIDATION(intl, options);

    let model = {};
    model[options.propertyName] = this.options?.initialValue || '';

    const changeset = new Changeset(model, lookupValidator(validation), validation);

    set(this, 'changeset', changeset);
  },

  onSubmit() {},
  onClose() {},

  actions: {
    onClose() {
      this.onClose();
    },
    submit() {
      const changeset = this.changeset;
      changeset.validate().then(() => {
        if (changeset.isValid) {
          this.onSubmit(this.options?.propertyName, changeset);
        } else {
          set(this, 'submitTouched', true);
        }
      });
    }
  }
});
