import ApplicationSerializer from './application';

import { PAYMENT_TYPES_MAP } from 'additive-voucher/utils/constants';

export default class PaymentTypeSerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    /**
     * Add empty credentials to model if missing,
     * as our logic needs this but api strips empty keys away
     */
    if (payload.paymentTypes || payload.paymentType) {
      const paymentTypes = payload.paymentTypes || [payload.paymentType];
      const paymentTypesWithDefault = paymentTypes.map((apiPaymentType) => {
        apiPaymentType.credentials = Object.assign(
          {},
          PAYMENT_TYPES_MAP[apiPaymentType.type].credentials,
          apiPaymentType.credentials
        );
        return apiPaymentType;
      });

      return super.normalizeResponse(
        store,
        primaryModelClass,
        { paymentTypes: paymentTypesWithDefault },
        id,
        requestType
      );
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
