import ENV from 'additive-voucher/config/environment';

import { Dashboard } from './routes/dashboard';
import { Settings } from './routes/settings';

export default function () {
  this.urlPrefix = ENV.APP.apiBaseHost;
  /* Allow any organization-id */
  this.namespace = '**';

  Dashboard(this);
  Settings(this);
}
