import Route from '@ember/routing/route';

export default Route.extend({
  resetController(controller, isExiting) {
    if (isExiting) {
      // reset qps when exiting
      controller.set('category', null);
      controller.set('type', null);
    }
  }
});
