import Controller from '@ember/controller';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import { categoryValidation } from 'additive-voucher/validations/category';

export default Controller.extend({
  intl: service(),
  multimedia: service(),
  router: service(),
  store: service(),
  uiFilter: service(),

  categoryValidation: null,

  init() {
    this._super(...arguments);

    const catValidation = categoryValidation(this.intl);
    set(this, 'categoryValidation', catValidation);
  },

  onSave: task(function* (record) {
    const categoryModel = yield record.save();
    this.uiFilter.addItem('categories', categoryModel);
    this.router.transitionTo('instance.vouchers.categories.category', categoryModel.id);
  }),

  actions: {
    /**
     * transitions to multimedia route
     *
     * @function openMultimedia
     */
    openMultimedia() {
      this.router.transitionTo('instance.vouchers.categories.create.additive-multimedia-engine');
    },

    /**
     * transitions back to categories route
     *
     * @function closeMultimedia
     */
    closeMultimedia() {
      this.router.transitionTo('instance.vouchers.categories.create.index');
    },
    /**
     * adds a given resource to the category
     *
     * @function addMedium
     */
    addMedium(changeset, _, [item]) {
      changeset.set('image', item);
    },

    /**
     * removes resource from category
     *
     * @function onResourceRemove
     */
    onResourceRemove(changeset) {
      changeset.set('image', null);
      changeset.execute();
    },

    onClose() {
      this.router.transitionTo('instance.vouchers.categories');
    }
  }
});
