import Controller from '@ember/controller';
import { set, computed } from '@ember/object';
import { LAYOUT_PORTRAIT, LAYOUTS } from 'additive-voucher/utils/constants';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { generatePreviewPath } from 'additive-voucher/utils/template-utils';
import ENV from 'additive-voucher/config/environment';
import merge from 'lodash.merge';

import { getColorOptions, getFontOptions } from 'additive-voucher/utils/constants/styles';

import Changeset from 'ember-changeset';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  router: service(),
  uiDialog: service(),

  orgId: computed.alias('currentUser.currentOrganization.id'),
  orgName: computed.alias('currentUser.currentOrganization.name'),
  isViewer: computed.alias('currentUser.isViewer'),

  /**
   * The typography defined in the corporate design
   *
   * @property corporateDesignTypography
   * @type {Object}
   * @default null
   */
  corporateDesignTypography: null,

  instance: null,
  path: null,

  init() {
    this._super(...arguments);

    set(this, 'layoutType', LAYOUT_PORTRAIT);
    set(this, 'layoutOptions', LAYOUTS);
  },

  setup: task(function* () {
    const basicTabs = [
      { title: this.intl.t('widgets.configurator.colors.title'), name: 'colors' },
      { title: this.intl.t('widgets.configurator.typography.title'), name: 'typography' }
    ];

    set(this, 'basicTabs', basicTabs);
    set(this, 'activeTab', basicTabs[0]);

    try {
      // load corporate design definition
      const response = yield this.authenticatedFetch.fetch(
        `${ENV.APP.apiBaseHost}/${this.orgId}/corporate-design`
      );

      if (!response || !response.ok) {
        // handle widget teardown
        return;
      }
      const { corporateDesign } = yield response.json();
      // destruct config and styles from the current widget-model

      // build the available color-options
      set(this, 'corporateDesignTypography', corporateDesign.typography);

      const { colors, fonts } = corporateDesign;
      const fontOptions = getFontOptions(fonts);
      const colorOptions = getColorOptions(colors);

      set(this, 'fonts', fonts);
      // build the available font-options
      set(this, 'fontOptions', fontOptions);
      // build the available color-options
      set(this, 'colorOptions', colorOptions);

      const changeset = new Changeset(this.model);
      set(this, 'changeset', changeset);
      // workaround in order to work with the configurator
      set(this, 'instance', {
        init() {}
      });
    } catch (error) {
      return;
    }
  }),

  previewUrl: computed('model.previewUrl', 'layoutType', 'path', {
    get() {
      return `${this.model.previewUrl}?layout=${this.layoutType}&${this.path}`;
    }
  }),

  save: task(function* () {
    try {
      yield this.changeset.save();
      this.router.transitionTo('instance.widgets.templates.template');
    } catch (error) {
      this.uiDialog.showError();
    }
  }),

  updatePreview() {
    const path = generatePreviewPath(
      this.changeset.get('colors'),
      this.changeset.get('typography'),
      this.corporateDesignTypography
    );
    set(this, 'path', path);
  },

  actions: {
    updateColors(type, val) {
      const { changeset } = this;
      const color = changeset.get(`colors.${type}`).content;
      changeset.set(`colors.${type}`, merge({}, color, val));
      changeset.execute();

      this.updatePreview();
    },
    updateFonts(type, val) {
      const { changeset } = this;
      const typography = changeset.get(type).content;
      changeset.set(type, merge({}, typography, val));
      changeset.execute();

      this.updatePreview();
    },
    toggleSync(type) {
      const { changeset } = this;
      const color = changeset.get(`colors.${type}`).content;
      changeset.set(`colors.${type}`, merge({}, color, { sync: !color.sync }));
      changeset.execute();

      this.updatePreview();
    },
    back() {
      if (!this.changeset.get('isPristine')) {
        return set(this, 'isDiscardChangesDialog', true);
      }
      this.router.transitionTo('instance.widgets.templates.template');
    },
    discardChanges() {
      this.changeset.rollback();
      // rollback model attributes since the changes are executed onto the model
      this.model.rollbackAttributes();

      set(this, 'isDiscardChangesDialog', false);
      this.router.transitionTo('instance.widgets.templates.template');
    }
  }
});
