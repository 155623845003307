import { validatePresence } from 'ember-changeset-validations/validators';

export const getValidationByType = (type, intl) => {
  switch (type) {
    case 'bank_transfer':
      return bankTransferValidation(intl);
    case 'concardis':
      return concardisValidation(intl);
    case 'nexi':
      return nexiValidation(intl);
    case 'paypal':
      return paypalValidation(intl);
    case 'sia_vpos':
      return siaVposValidation(intl);
    case 'six_payments':
      return sixPaymentsValidation(intl);
    case 'gastro_pool':
      return singleFieldValidation(intl, 'key');
    case 'gastro_pool_v3':
      return singleFieldValidation(intl, 'key');
    case 'oppwa':
      return oppwaValidation(intl);
    case 'hogast':
      return singleFieldValidation(intl, 'key');
    case 'hogast_v3':
      return singleFieldValidation(intl, 'key');
    case 'vpos':
      return singleFieldValidation(intl, 'id');
    case 'stripe':
      return stripeValidation(intl);
    case 'klarna':
      return klarnaValidation(intl);
    default:
      throw new Error(`[getValidationByType] type '${type}' not implemented yet`);
  }
};

const bankTransferValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    iban: validatePresence({ presence: true, message: presenceMessage }),
    bankName: validatePresence({ presence: true, message: presenceMessage })
  };
};

const paypalValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    username: validatePresence({ presence: true, message: presenceMessage }),
    password: validatePresence({ presence: true, message: presenceMessage }),
    signature: validatePresence({ presence: true, message: presenceMessage })
  };
};

const singleFieldValidation = (intl, fieldName) => {
  const presenceMessage = intl.t('errors.required');

  return {
    [fieldName]: validatePresence({ presence: true, message: presenceMessage })
  };
};

const oppwaValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    credentials: {
      token: validatePresence({ presence: true, message: presenceMessage }),
      entityId: validatePresence({ presence: true, message: presenceMessage })
    }
  };
};

const concardisValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    merchantId: validatePresence({ presence: true, message: presenceMessage }),
    apiKey: validatePresence({ presence: true, message: presenceMessage })
  };
};

const nexiValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    correlationId: validatePresence({ presence: true, message: presenceMessage }),
    apiKey: validatePresence({ presence: true, message: presenceMessage })
  };
};

const siaVposValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    shopId: validatePresence({ presence: true, message: presenceMessage }),
    apiStartKey: validatePresence({ presence: true, message: presenceMessage }),
    apiResultKey: validatePresence({ presence: true, message: presenceMessage })
  };
};

const sixPaymentsValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    apiUser: validatePresence({ presence: true, message: presenceMessage }),
    customerId: validatePresence({ presence: true, message: presenceMessage }),
    terminalId: validatePresence({ presence: true, message: presenceMessage }),
    apiPassword: validatePresence({ presence: true, message: presenceMessage })
  };
};

const stripeValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    publishableKey: validatePresence({ presence: true, message: presenceMessage }),
    secretKey: validatePresence({ presence: true, message: presenceMessage })
  };
};

const klarnaValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    username: validatePresence({ presence: true, message: presenceMessage }),
    password: validatePresence({ presence: true, message: presenceMessage })
  };
};
