import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { resolve } from 'rsvp';

const DEFAULT_VALIDATION = (intl, propertyName) => {
  const presenceMessage = intl.t('errors.required');

  let returnObject = {};
  returnObject[propertyName] = validatePresence({ presence: true, message: presenceMessage });

  return returnObject;
};

/**
 * Dialog form to handle updates on 'title' and 'text' of orders
 * Validates the presence of 'text' and returns an object containing both properties on submit
 * Does not perform any API requests
 *
 * @class av-order-texts-dialog
 */
export default Component.extend({
  intl: service(),
  uiDialog: service(),

  tagName: '',

  /**
   * Object containing the texts of an order
   *
   * @property texts
   * @type {Object}
   * @default null
   * @public
   */
  texts: null,

  init() {
    this._super(...arguments);

    const validation = DEFAULT_VALIDATION(this.intl, 'text');
    const orderTextsChangeset = new Changeset(this.texts, lookupValidator(validation), validation);
    set(this, '_orderTextsChangeset', orderTextsChangeset);
  },

  /**
   * Called when the user clicks on the close icon
   *
   * @function onClose
   * @return {Any}
   */
  onClose() {},

  /**
   * Called on submit after successful validation
   *
   * @function onSubmit
   * @return {Any}
   */
  onSubmit() {},

  actions: {
    validate(changeset) {
      changeset.validate();
    },
    onSubmit() {
      const changeset = this._orderTextsChangeset;
      set(this, '_isFormTouched', true);

      const submitPromise = changeset.validate().then(() => {
        if (changeset.get('isValid')) {
          changeset.execute();
          return this.onSubmit(changeset.data);
        }

        return resolve();
      });

      submitPromise.catch((error) => {
        throw new Error(`[av-order-texts-dialog] onSubmit promise error ${error}`);
      });

      return submitPromise;
    },
    onClose() {
      const changeset = this._orderTextsChangeset;
      if (changeset.isDirty) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          changeset.rollback();
          this.onClose();
        });
        return;
      }

      this.onClose();
    }
  }
});
