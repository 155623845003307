export const generatePreviewPath = (colors, typography, corporateDesignTypography) => {
  const path = Object.keys(colors).reduce(
    (path, type) =>
      path +
      `colors[${type}][color]=${encodeURIComponent(
        colors[type][colors[type].sync ? 'syncedColor' : 'color']
      )}&colors[${type}][contrast_color]=${encodeURIComponent(
        colors[type][colors[type].sync ? 'syncedContrastColor' : 'contrastColor']
      )}&`,
    ''
  );

  const typo = Object.keys(typography).reduce((path, type) => {
    const syncedTypographyType = typography[type].syncedTypography;
    const syncedTypography = corporateDesignTypography[syncedTypographyType];

    return (
      path +
      `typography[${type}][font_family]=${encodeURIComponent(
        typography[type].sync && syncedTypography
          ? syncedTypography['fontFamily']
          : typography[type]['fontFamily']
      )}&typography[${type}][serif]=${encodeURIComponent(
        typography[type].sync && syncedTypography
          ? syncedTypography['serif']
          : typography[type]['serif']
      )}&`
    );
  }, '');

  return `${path.trim()}${typo.trim()}`;
};
