import Component from '@ember/component';
import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';

/**
 * Component which fetches widgets and shows a inline-notification.
 *
 * TODO: Remove me when all v1 widgets are gone
 *
 * @class av-widget-v1-notification
 */
export default Component.extend({
  intl: service(),
  router: service(),
  store: service(),

  tagName: '',

  notificationText: null,

  /**
   * Ember-data stores widgets
   *
   * @type {Widget[]} widgets
   * @default null
   */
  _widgets: null,

  /**
   * Whether the state of the notification is error or not.
   * It is error when the organization has only V1 widgets
   *
   * @computed _isError
   * @type {Boolean}
   * @private
   */
  _isError: computed('_widgets.@each.version', {
    get() {
      const widgetsAsArray = this._widgets.toArray();

      let v1Count = 0;

      widgetsAsArray.forEach((widget) => {
        if (widget.version === '1') {
          v1Count++;
        }
      });

      return v1Count > 0 && widgetsAsArray.length === v1Count;
    }
  }),

  /**
   * Whether notification should be shown
   *
   * @computed _show
   * @type {Boolean}
   * @private
   */
  _show: computed('_widgets.@each.version', {
    get() {
      if (!this._widgets) {
        return false;
      }

      /* Check if minimum 1 widget with version 1 exists */
      return this._widgets.toArray().some((widget) => widget.version === '1');
    }
  }),

  init() {
    this._super(...arguments);

    if (!this.notificationText) {
      set(this, 'notificationText', this.intl.t('components.avWidgetV1Notification.defaultText'));
    }
  },

  /**
   * Load widgets using ember-data store
   *
   * @type {Task}
   * @function _loadWidgets
   */
  _loadWidgets: task(function* () {
    let widgets = this.store.peekAll('widget');

    if (widgets.length > 0) {
      set(this, '_widgets', widgets);
      return;
    }

    let tasks = [];

    tasks.push(this.store.findAll('widget', { reload: true }));
    tasks.push(timeout(400));

    [widgets] = yield all(tasks);

    set(this, '_widgets', widgets);
  }).on('didInsertElement'),

  actions: {
    transitionToWidgets() {
      this.router.transitionTo('instance.widgets');
    }
  }
});
