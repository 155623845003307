import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { bind, scheduleOnce } from '@ember/runloop';

import { LAYOUT_PORTRAIT, LAYOUTS } from 'additive-voucher/utils/constants';

export default class InstanceWidgetsTemplatesTemplateController extends Controller {
  @service currentUser;
  @service intl;
  @service router;
  @service uiDialog;

  @tracked
  isMobile = false;

  @tracked
  layoutType = LAYOUT_PORTRAIT;

  layoutOptions = LAYOUTS;

  get orgName() {
    return this.currentUser?.currentOrganization?.name;
  }

  get hasConfigurator() {
    return !this.isCustomTemplate;
  }

  get isCustomTemplate() {
    return this.model.name.indexOf('custom') > -1;
  }

  get previewUrl() {
    return `${this.model.previewUrl}?layout=${this.layoutType}`;
  }

  constructor() {
    super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);

    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);

    scheduleOnce('afterRender', this, this.resizeListener);
  }

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  @action
  edit(template) {
    if (this.isMobile) {
      this.uiDialog.showError(
        this.intl.t('errors.desktopOnly.title'),
        this.intl.t('errors.desktopOnly.template')
      );
    } else {
      this.router.transitionTo('instance.widgets.templates.template.edit', template);
    }
  }

  @action
  back() {
    this.router.transitionTo('instance.widgets.templates');
  }
}
