import Component from '@ember/component';

/**
 * This component represents the "content"
 * on the center of the order-element.
 *
 * When no content exists it renders a "action label"
 * to motivate the user to fill it in.
 *
 * @class new-order-dialog/order-element-content
 */
export default Component.extend({
  classNames: ['content', 'font-sm'],

  /**
   * The content
   *
   * @property content
   * @type {String}
   */
  content: undefined,

  /**
   * The action label.
   * Shown when no content exists
   *
   * @property actionLabel
   * @type {String}
   */
  actionLabel: ''
});
