import Component from '@ember/component';
import { set } from '@ember/object';

/**
 * @class colors
 * @module av-template-configuration
 */
export default Component.extend({
  tagName: '',

  isReadOnly: false,

  onUpdate() {},

  actions: {
    update(key, val, sync) {
      if (sync) {
        set(val, 'sync', false);
      }
      this.onUpdate(key, val);
    },
    toggleSync(key, sync) {
      this.onUpdate(key, { sync: !sync });
    }
  }
});
