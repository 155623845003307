import Transform from '@ember-data/serializer/transform';

import cloneDeep from 'lodash.clonedeep';
import { centsToAmount, amountToCents } from 'additive-voucher/utils/currency-serialization';

/**
 * Makes sure "price" is of type int, as api expects this
 */
export default class ShippingPricesTransform extends Transform {
  deserialize(serialized) {
    const modifiedSerialized = (Array.isArray(serialized) ? serialized : []).map((priceElement) => {
      const newPriceElement = cloneDeep(priceElement);
      newPriceElement.price = centsToAmount(parseInt(priceElement.price));

      return newPriceElement;
    });

    return modifiedSerialized;
  }

  /* Somehow 'price' setted as number will be casted to string, as api wants a number, recast before serialization */
  serialize(deserialized) {
    const modifiedDeserialized = (Array.isArray(deserialized) ? deserialized : []).map(
      (priceElement) => {
        const newPriceElement = cloneDeep(priceElement);
        newPriceElement.price = amountToCents(parseInt(priceElement.price));

        return newPriceElement;
      }
    );

    return modifiedDeserialized;
  }
}
