import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  uiAppSettings: service(),

  beforeModel() {
    this._super(...arguments);
    // TODO: Refactor app so that the plan check has already been done before entering child routes
    if (!this.uiAppSettings.isProfessionalPlan) {
      this.transitionTo('instance.settings');
    }
  }
});
