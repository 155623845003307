import Component from '@ember/component';

/**
 * This component represents the "action"
 * on the right side of the order-element.
 *
 * Could be empty as well,
 * in this case it only functions as a placeholder.
 *
 * @class new-order-dialog/order-element-action
 */
export default Component.extend({
  classNames: ['right'],

  /**
   * Whether it should show the edit-icon.
   *
   * @property showEdit
   * @type {Boolean}
   */
  showEdit: false,

  /**
   * On edit click
   *
   * @function onEdit
   */
  onEdit() {}
});
