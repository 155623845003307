import Model, { attr } from '@ember-data/model';

export default class CustomerModel extends Model {
  @attr('string') email;
  @attr('string', { defaultValue: 'm' }) gender;
  @attr('string') givenName;
  @attr('string') familyName;
  @attr('string') telephone;
  @attr('string') streetAddress;
  @attr('string') postalCode;
  @attr('string') addressLocality;
  @attr('string') addressCountry;
  @attr('boolean') isCompany;
  @attr('string') companyName;
  @attr('string') companyVatId;
}
