import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  router: service(),
  store: service(),
  uiDialog: service(),
  uiToast: service(),

  tagName: '',

  order: null,
  selectedPaymentType: null,

  onClose() {},

  /**
   * Loads all payment-types the organization has.
   *
   * @type {Task}
   * @function getPaymentTypes
   * @param {[Object]} paymentTypes the type of the notification to send
   */
  getPaymentTypes: task(function* () {
    return (yield this.store.findAll('payment-type')).filter(
      (paymentType) => paymentType.enabled === true
    );
  }).restartable(),

  /**
   * Marks an order as paid with given payment-type
   *
   * @type {Task}
   * @function markAsPayed
   * @param {String} payment_type the selected payment-type
   */
  markAsPayed: task(function* (payment_type) {
    try {
      let body = JSON.stringify({ state: 'paid', payment_type });

      const adapter = this.store.adapterFor('order');
      const baseUrl = adapter.buildURL('order', this.order.id);

      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/state`, {
        method: 'PUT',
        body
      });

      if (response && response.ok) {
        const order = yield response.json();
        this.store.pushPayload('order', order);

        yield this.onClose();

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } else {
        const { errors } = yield response.json();
        const error = errors && errors['payment_type'] && 'payment_type';
        throw new Error(`${error}`);
      }
    } catch (error) {
      if (error && error.message === 'payment_type') {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          description: this.intl.t('orders.order.paymentDialog.paymentNotSupported'),
          type: 'error'
        });
      }
    }
  }).restartable()
});
