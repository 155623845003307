import Component from '@ember/component';

import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { redeemVoucherValidation } from 'additive-voucher/validations/order';
import { amountToCents } from 'additive-voucher/utils/currency-serialization';

export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  store: service(),
  uiToast: service(),

  /**
   * Toggle to partially redeem an order
   * @property _isPartiallyEnabled
   * @default false
   * @type {Boolean}
   * @private
   */
  _isPartiallyEnabled: false,
  /**
   * The changeset the hold all data relvant for redeeming
   * an order.
   *
   * @property _changeset
   * @default null
   * @type {Object}
   * @private
   */
  _changeset: null,

  onClose() {},

  init() {
    this._super(...arguments);
    const validation = redeemVoucherValidation(this.intl);
    const changeset = new Changeset(
      { redeemCode: '', comment: '', amount: null },
      lookupValidator(validation),
      validation
    );
    set(this, '_changeset', changeset);
  },

  updateState: task(function* () {
    try {
      const changeset = this._changeset;
      yield changeset.validate();

      const { redeemCode, amount, comment } = changeset;

      // reset the changesets amount
      if (!this._isPartiallyEnabled) {
        changeset.set('amount', null);
      }

      /*
       * in case the switch is enabled and there is no amount
       * push an error to the changeset
       */
      if (this._isPartiallyEnabled && !amount) {
        changeset.addError('amount', this.intl.t('errors.required'));
      }

      if (changeset.get('isInvalid')) {
        set(this, 'isTouched', true);
        return;
      }

      let body = JSON.stringify({
        state: 'redeemed',
        redeemCode,
        amount: amount ? amountToCents(amount) : null,
        comment
      });

      const adapter = this.store.adapterFor('order');
      const baseUrl = adapter.buildURL('order', this.order.id);
      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/state`, {
        method: 'PUT',
        body
      });

      if (response && response.ok) {
        const order = yield response.json();
        this.store.pushPayload('order', order);

        yield this.onClose();

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } else {
        const { errors } = yield response.json();
        throw new Error(`[ORDER ACTIONS] wrong_redeem_code ${errors}`);
      }
    } catch (error) {
      this._changeset &&
        this._changeset.addError('redeemCode', this.intl.t('errors.invalidRedeemCode'));
    }
  }).restartable()
});
