import ENV from 'additive-voucher/config/environment';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';

export default Component.extend({
  tagName: '',
  uiAppSettings: service(),
  currentUser: service(),

  orgSlug: alias('currentUser.currentOrganization.id'),
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),
  languages: alias('uiAppSettings.languages.contentLanguages'),

  init() {
    this._super(...arguments);
    set(this, 'selectedLanguage', this.defaultLanguage);
  },

  /**
   * Only as temporary solution as the api didn't implemented the actual redirect
   */
  _code: computed('orgSlug', 'selectedLanguage', 'widget.{id,selector}', {
    get() {
      return `<div id="${this.widget.selector}" class="aa-voucher-widget"></div><script async src="${ENV.APP.publicHost}/${this.orgSlug}/widgets/${this.widget.id}/${this.selectedLanguage}"></script>
      `;
    }
  })
});
