import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

export const syncServiceEmailValidations = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    email: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({ type: 'email', message: emailMessage })
    ]
  };
};
