import {
  validateInclusion,
  validateFormat,
  validateLength,
  validatePresence,
  validateNumber
} from 'ember-changeset-validations/validators';

import { validateDateDependency, validateDate } from '@additive-apps/utils/validators/date';
import { validateNumeral } from '@additive-apps/utils/validators/numeral';
import validateIf from '@additive-apps/utils/validators/validate-if';

import {
  VOUCHER_TYPES,
  VOUCHER_VALIDITY_PERIODS,
  SERVICE,
  VALUE,
  STAY,
  PRODUCT,
  TREATMENT
} from 'additive-voucher/utils/constants';

/**
 * Shared validation, for all available voucher types.
 *
 * @param {Service} intl reference to the intl service
 * @param {String} type the type of the voucher
 */
export const baseValidation = (intl, type) => {
  const presenceMessage = intl.t('errors.required');
  const maxLengthNameMessage = intl.t('errors.maxLength', { maxLength: 50 });

  /* General validation for all voucher types */
  const generalValidation = {
    name: [
      validateLength({ max: 50, message: maxLengthNameMessage }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    category: validatePresence({ presence: true, message: presenceMessage }),
    type: validateInclusion({ list: VOUCHER_TYPES, message: presenceMessage }),
    validityPeriod: validateInclusion({ list: VOUCHER_VALIDITY_PERIODS, message: presenceMessage }),
    validityPeriodCustomDate: validateDate({
      allowBlank: true,
      format: 'yyyy-MM-dd'
    }),
    unlockCode: [
      validateFormat({
        regex: /^[A-Z0-9-]*$/,
        message: intl.t('errors.unlockCode.format'),
        allowBlank: true
      }),
      validateLength({
        min: 5,
        max: 15,
        message: intl.t('errors.unlockCode.length', { min: 5, max: 15 }),
        allowBlank: true
      })
    ],
    images: validateLength({
      max: 10,
      message: intl.t('errors.images')
    })
  };

  /* Service voucher specific validation */
  const serviceValidation = {
    services: validatePresence({ presence: true, message: presenceMessage })
  };

  switch (type) {
    case SERVICE:
      return { ...generalValidation, ...serviceValidation };
    default:
      return generalValidation;
  }
};

/**
 * Validation for the amount and all related discount properties.
 *
 * i.e. the presence validator for the discountedAmount get applied "on-code",
 * as the validation should only be executed if the `isDiscounted` is set.
 *
 * @param {Service} intl reference to the intl service
 */
export const discountValidation = (intl) => {
  const amountFormatMessage = intl.t('errors.amountFormat');
  const discountPeriodMessage = intl.t('errors.discountPeriod');
  const discountedAmountMessage = intl.t('errors.discountedAmount');
  const invalidDateRangeMessage = intl.t('errors.invalidDateRange');
  const presenceMessage = intl.t('errors.required');
  const greaterZeroMessage = intl.t('errors.greaterZero');

  return {
    amount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    discountedAmount: [
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage,
        allowBlank: true
      }),
      validateNumeral({
        lt: 'amount',
        allowBlank: true,
        message: discountedAmountMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    discountPeriodStart: [
      validateIf({
        validate: validateDate({
          allowBlank: false,
          format: 'yyyy-MM-dd',
          message: invalidDateRangeMessage
        }),
        if: { hasDiscountPeriod: true },
        firstErrorMessage: true
      })
    ],
    discountPeriodEnd: [
      validateIf({
        validate: validatePresence({ presence: true, message: invalidDateRangeMessage }),
        if: { hasDiscountPeriod: true },
        firstErrorMessage: true
      }),
      validateDateDependency({
        type: 'after',
        format: 'yyyy-MM-dd',
        on: 'discountPeriodStart',
        allowBlank: true,
        message: discountPeriodMessage
      })
    ]
  };
};

/**
 * Value-Vouchers validation for the amount and the related min- max- props.
 *
 * @param {Service} intl reference to the intl service
 */
export const amountsValidation = (intl) => {
  const amountFormatMessage = intl.t('errors.amountFormat');
  const minAmountMessage = intl.t('errors.minAmount');
  const maxAmountMessage = intl.t('errors.maxAmount');
  const presenceMessage = intl.t('errors.required');
  const greaterZeroMessage = intl.t('errors.greaterZero');

  return {
    amount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    minAmount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage,
        allowBlank: true
      }),
      validateNumeral({
        lte: 'amount',
        message: minAmountMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ],
    maxAmount: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({
        regex: /^[0-9]*.?[0-9]{1,2}$/,
        message: amountFormatMessage,
        allowBlank: true
      }),
      validateNumeral({
        gte: 'amount',
        message: maxAmountMessage
      }),
      validateNumeral({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })
    ]
  };
};

/**
 * Product-vouchers validation strategy
 *
 * @param {Service} intl reference to the intl service
 */
export const productsValidation = (intl) => {
  const productCategoriesMessage = intl.t('errors.productCategories');

  return {
    productCategories: validateLength({
      min: 1,
      message: productCategoriesMessage
    })
  };
};

/**
 * Treatment-vouchers validation strategy
 *
 * @param {Service} intl reference to the intl service
 */
export const treatmentsValidation = (intl) => {
  const treatmentCategoriesMessage = intl.t('errors.treatmentCategories');

  return {
    treatmentCategories: validateLength({
      min: 1,
      message: treatmentCategoriesMessage
    })
  };
};

/**
 * Stay-vouchers validation strategy
 *
 * @param {Service} intl reference to the intl service
 */
export const stayValidation = (intl) => {
  const roomTypesMessage = intl.t('errors.roomTypes');
  const minStaysMessage = intl.t('errors.required');

  return {
    personCount: validateNumber({ gt: 0, lt: 3, allowBlank: true }),
    minStays: validateNumber({ gt: 0, lt: 8, allowBlank: false, message: minStaysMessage }),
    roomTypes: validateLength({ min: 1, message: roomTypesMessage })
  };
};

/**
 * Returns the voucher-detail validations-schema depending
 * on the provided voucher-type.
 *
 * @param {Service} intl reference to the intl service
 * @param {String} intl reference to the intl service
 */
export const detailValidation = (intl, type) => {
  switch (type) {
    case VALUE:
      return amountsValidation(intl);
    case PRODUCT:
      return productsValidation(intl);
    case TREATMENT:
      return treatmentsValidation(intl);
    case STAY:
      return stayValidation(intl);
    case SERVICE:
    default:
      return discountValidation(intl);
  }
};
