import Component from '@ember/component';
import { set, setProperties } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { task, timeout, all } from 'ember-concurrency';
import { resolve } from 'rsvp';

import { syncServiceEmailValidations } from 'additive-voucher/validations/sync-service-email';

export default Component.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiPaths: service(),
  uiToast: service(),

  tagName: '',

  /**
   * the sync service
   *
   * @argument syncService
   * @type {String}
   * @default null
   */
  syncService: null,

  /**
   * the changeset
   *
   * @property _changeset
   * @type {Object}
   * @default null
   * @private
   */
  _changeset: null,

  /**
   * whether the input is touched
   *
   * @property _isTouched
   * @type {Boolean}
   * @default false
   * @private
   */
  _isTouched: false,

  /**
   * the user generated on sync
   *
   * @property _user
   * @type {Object}
   * @default null
   * @private
   */
  _user: null,

  _organizationSlug: alias('currentUser.currentOrganization.id'),

  init() {
    this._super(...arguments);

    const validation = syncServiceEmailValidations(this.intl);
    const changeset = new Changeset({ email: '' }, lookupValidator(validation), validation);

    set(this, '_changeset', changeset);
  },

  loadSyncSettings: task(function* () {
    try {
      let tasks = [];
      const url = this.uiPaths
        .pathsByRouteName('instance.settings', this._organizationSlug)
        .api().url;

      // load sync-settings
      if (this.syncService === 'asa') {
        tasks.push(this.authenticatedFetch.fetch(`${url}/${this.syncService}/user`));
      } else {
        tasks.push(this.authenticatedFetch.fetch(`${url}/${this.syncService}`));
      }
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error(`[${this.syncService.toUpperCase()}] Error fetching sync related data`);
      }

      const { user } = yield response.json();

      set(this, '_user', user);
    } catch (error) {
      return resolve();
    }
  }).on('init'),

  sendEmail: task(function* () {
    try {
      const changeset = this._changeset;
      yield changeset.validate();

      if (changeset.get('isInvalid')) {
        set(this, '_isTouched', true);
      } else {
        let tasks = [];

        const options = {
          method: 'PUT',
          body: JSON.stringify({ email: changeset.get('email') })
        };

        const url = this.uiPaths
          .pathsByRouteName('instance.settings', this._organizationSlug)
          .api().url;

        // send sync instruction to email
        if (this.syncService === 'asa') {
          tasks.push(this.authenticatedFetch.fetch(`${url}/${this.syncService}/user`, options));
        } else {
          tasks.push(this.authenticatedFetch.fetch(`${url}/${this.syncService}`, options));
        }
        tasks.push(timeout(400));

        const [response] = yield all(tasks);

        if (!response || !response.ok) {
          throw new Error(`[${this.syncService.toUpperCase()}] Error on sending email`);
        }

        const { user } = yield response.json();

        set(this, '_user', user);

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });

        this.onClose();
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  willDestroyElement() {
    this._super(...arguments);

    setProperties(this, {
      _user: null,
      _isTouched: false
    });
  }
});
