import Component from '@ember/component';

import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import Changeset from 'ember-changeset';
import { task } from 'ember-concurrency';

import { isPast } from 'date-fns';

export default Component.extend({
  intl: service(),
  store: service(),
  uiToast: service(),

  tag: '',

  _changeset: null,

  onClose() {},

  init() {
    this._super(...arguments);

    const _orderChangeset = new Changeset(this.order);
    set(this, '_changeset', _orderChangeset);

    const date = new Date();

    set(this, 'initialDate', isPast(this.order.expiresAt) ? date : this.order.expiresAt);
    set(this, 'minDate', date);
  },
  updateExpiration: task(function* () {
    try {
      yield this._changeset.save();
      yield this.onClose();

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
});
