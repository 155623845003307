import Model, { attr } from '@ember-data/model';

export default class ShippingModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') type;
  @attr('boolean') enabled;
  @attr('boolean') default;
  @attr('string') language;
  @attr() availableLanguages;
  @attr('shipping-prices') prices;

  get priceRange() {
    const allPrices = (Array.isArray(this.prices) ? this.prices : []).map((price) => price.price);
    if (allPrices.length === 0) {
      return { start: null, end: null };
    }

    allPrices.sort();

    const startPrice = allPrices[0];
    const endPrice = allPrices[allPrices.length - 1];

    return {
      start: startPrice,
      end: startPrice === endPrice ? null : endPrice
    };
  }
}
