import Model, { attr } from '@ember-data/model';

import { htmlSafe } from '@ember/string';

import { LAYOUTS } from 'additive-voucher/utils/constants';
import { orderToState } from 'additive-voucher/utils/constants/order';

export default class OrderModel extends Model {
  // amounts
  @attr('price') amountForPackage;
  @attr('price') amountToPay;
  @attr('price') amountWithDiscount;
  @attr('price') amountWithoutDiscount;
  @attr('price') amountLeft;

  @attr('string') identifier;
  @attr('string') language;
  @attr('string') currency;
  @attr('string') note;
  @attr('string') paymentType;
  @attr('string') text;
  @attr('string') title;
  @attr('string') state;
  @attr('string') previewUrl;
  @attr('string') pdfUrl;
  @attr('string') redeemCode;

  @attr('string') templateId;
  @attr('string') shippingId;
  @attr('string', {
    defaultValue() {
      return LAYOUTS[0];
    }
  })
  layout;

  @attr('string', { defaultValue: 'frontend' }) sourceType;
  @attr('string') sourceUrl;
  @attr('boolean', { defaultValue: false }) gift;

  @attr('string') orderedAt;
  @attr('string') expiresAt;

  @attr() customer;
  @attr() voucher;
  @attr() shipping;
  @attr() package;
  @attr() image;

  get amountRedeemed() {
    return this.amountWithoutDiscount - this.amountLeft;
  }

  get address() {
    /* TODO */
    let personAddress = '';

    const { streetAddress, addressRegion, addressLocality, postalCode, addressCountry } =
      this.customer;

    streetAddress && (personAddress = `${streetAddress}<br/>`);

    if (addressLocality) {
      postalCode
        ? (personAddress = `${personAddress}${postalCode}, ${addressLocality}<br/>`)
        : (personAddress = `${personAddress}${addressLocality}<br/>`);
    } else {
      postalCode && (personAddress = `${personAddress}${postalCode}<br/>`);
    }

    if (addressCountry) {
      addressRegion
        ? (personAddress = `${personAddress}${addressRegion}, ${addressCountry}<br/>`)
        : (personAddress = `${personAddress}${addressCountry}<br/>`);
    } else {
      addressRegion && (personAddress = `${personAddress}${addressRegion}<br/>`);
    }

    return personAddress ? htmlSafe(personAddress) : false;
  }

  get isPaid() {
    return this.state === 'paid';
  }

  get isCanceled() {
    return this.state === 'canceled';
  }

  get globalState() {
    return orderToState(this.state);
  }
}
