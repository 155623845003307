/**
 * format an amount to cents
 *
 * @param {Number} amount
 */
export const amountToCents = (amount) => {
  if (!amount || isNaN(parseFloat(amount))) {
    return amount;
  }
  const floatAmount = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
  return floatAmount * 100;
};
/**
 * transform an amount in cents into an float
 *
 * @param {Number} cents
 */
export const centsToAmount = (cents) => {
  if (!cents || isNaN(parseFloat(cents))) {
    return cents;
  }

  return cents / 100;
};
