import Controller from '@ember/controller';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';

export default Controller.extend({
  router: service(),
  store: service(),
  uiAppSettings: service(),
  uiFilter: service(),
  currentUser: service(),

  /**
   * Whether the FAQ dialog is shown or not
   *
   * @property isFaqDialog
   * @type {Boolean}
   * @default false
   */
  isFaqDialog: false,

  /**
   * Array of keys for FAQs
   *
   * @property faqKeys
   * @type {Array}
   * @default null
   */
  faqKeys: null,

  isProfessionalPlan: computed.alias('uiAppSettings.isProfessionalPlan'),
  isViewer: computed.alias('currentUser.isViewer'),

  /**
   * If the search-/filterbar is open the intro should be hidden
   * @computed isIntroVisible
   * @return {Boolean} whether the intro is visiblke or not
   */
  isIntroVisible: computed.not('_vouchersFilter.isOpen'),

  /**
   * Returns the name of the used filter on a per route base,
   * in our case only for the vouchers list and we disable search and filter
   * completely for the categories.
   *
   * @computed filter
   * @return {String|Null} the global filter group name
   */
  filter: computed('isVouchersRoute', {
    get() {
      return this.isVouchersRoute ? 'vouchers' : null;
    }
  }),

  /**
   * returns true if the active route is the vouchers list route
   * @computed isVouchersRoute
   * @return {Boolean} whether the vouchers route is active or not
   */
  isVouchersRoute: computed('router.currentRouteName', {
    get() {
      return this.router.isActive('instance.vouchers.index');
    }
  }),

  /**
   * Reference to the underlying QueryParams-Instance.
   * @computed _filterObject
   * @return {QueryParameters}
   * @private
   */
  _vouchersFilter: computed('filter', 'isVouchersRoute', {
    get() {
      const dataFilter = this.uiFilter.getQPFilter('vouchers');
      return dataFilter && dataFilter.filter;
    }
  }),

  init() {
    this._super(...arguments);

    set(this, 'faqKeys', [
      'createVoucher',
      'voucherTypes',
      'voucherSort',
      'voucherUnlock',
      'voucherInhouse',
      'voucherWidget',
      'voucherTemplate',
      'publishVoucher'
    ]);

    this.loadCategories = bind(this, this.loadCategories);
  },

  loadCategories() {
    return this.store.findAll('category').then((categories) =>
      categories.map((category) => {
        return { value: category.id, name: category.name };
      })
    );
  }
});
