import faker from 'faker';

export const Dashboard = (server) => {
  server.get('/dashboard/key-indicators', () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);

    return {
      ordersCountMonthly: {
        type: 'single',
        current: { value: faker.random.number(), type: 'monthly', date: `${year}-${month}` }
      },
      amountSumMonthly: {
        type: 'single',
        current: { value: faker.random.number(), type: 'monthly', date: `${year}-${month}` }
      },
      ordersCountYearly: {
        type: 'single',
        current: { value: faker.random.number(), type: 'yearly', date: `${year}` }
      },
      amountSumYearly: {
        type: 'compare',
        current: { type: 'yearly', value: faker.random.number(), date: `${year}` },
        compare: { type: 'yearly', value: faker.random.number(), date: `${year - 1}` },
        change: `${faker.random.number({ precision: 2 })}`
      }
    };
  });

  server.get('/dashboard/orders-chart', () => {
    const today = new Date();

    const labels = [];
    const ordered = [];
    const paid = [];
    const redeemed = [];
    const compareValues = [];

    for (let i = 11; i >= 0; i--) {
      let year = today.getFullYear();
      let month = today.getMonth() - i;
      if (month < 0) {
        month += 12;
        year--;
      }

      month = `0${month + 1}`.slice(-2);
      labels.push(`${year}-${month}`);

      ordered.push(faker.random.number());
      paid.push(faker.random.number());
      redeemed.push(faker.random.number());
      compareValues.push(faker.random.number({ precision: 3 }));
    }

    return {
      ordersChart: {
        type: 'compare',
        labels,
        datasets: [
          {
            label: 'ordered',
            format: 'number',
            currency: null,
            data: ordered,
            compare: compareValues,
            tooltipData: null
          },
          {
            label: 'paid',
            format: 'number',
            currency: null,
            data: paid,
            compare: compareValues,
            tooltipData: null
          },
          {
            label: 'redeemed',
            format: 'number',
            currency: null,
            data: redeemed,
            compare: compareValues,
            tooltipData: null
          }
        ],
        comparison: { label: 'last_year' }
      }
    };
  });

  server.get('/dashboard/ordered-amount-chart', () => {
    const today = new Date();

    const labels = [];
    const orderedAmounts = [];
    const paidAmounts = [];
    const redeemedAmounts = [];
    const compareValues = [];

    for (let i = 11; i >= 0; i--) {
      let year = today.getFullYear();
      let month = today.getMonth() - i;
      if (month < 0) {
        month += 12;
        year--;
      }

      month = `0${month + 1}`.slice(-2);
      labels.push(`${year}-${month}`);

      orderedAmounts.push(faker.random.number());
      paidAmounts.push(faker.random.number());
      redeemedAmounts.push(faker.random.number());
      compareValues.push(faker.random.number({ precision: 3 }));
    }

    return {
      orderedAmountChart: {
        type: 'compare',
        labels,
        datasets: [
          {
            label: 'ordered_amount',
            format: 'currency',
            currency: 'EUR',
            data: orderedAmounts,
            compare: compareValues,
            tooltipData: null
          },
          {
            label: 'paid_amount',
            format: 'currency',
            currency: 'EUR',
            data: paidAmounts,
            compare: compareValues,
            tooltipData: null
          },
          {
            label: 'redeemed_amount',
            format: 'currency',
            currency: 'EUR',
            data: redeemedAmounts,
            compare: compareValues,
            tooltipData: null
          }
        ],
        comparison: { label: 'last_year' }
      }
    };
  });

  server.get('/dashboard/last-orders', (schema) => {
    return schema.orders.all().slice(0, 10);
  });
};
