import ENV from 'additive-voucher/config/environment';

import Controller from '@ember/controller';

import { task, timeout, all } from 'ember-concurrency';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { reject } from 'rsvp';

import Changeset from 'ember-changeset';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiAppSettings: service(),
  uiToast: service(),

  isLoading: computed.alias('loadTexts.isRunning'),
  organizationId: computed.alias('currentUser.currentOrganization.id'),
  // global languages the organization has enabled
  languages: computed.alias('uiAppSettings.languages.contentLanguages'),

  _readonly: computed.not('currentUser.isManager'),

  loadTexts: task(function* (locale) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      const request = yield this.authenticatedFetch.fetch(`${url}/texts`, {
        headers: { 'Accept-Language': locale || 'de' }
      });

      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        reject();
      } else {
        // destructure response
        const {
          texts: {
            language,
            availableLanguages,
            emailValediction,
            voucherGreeting,
            voucherTermsOfUse
          }
        } = yield response.json();
        // set the current langauge and all published Languages
        set(this, 'currentLanguage', language);
        set(this, 'availableLanguages', availableLanguages);

        const textResource = {
          emailValediction,
          voucherGreeting,
          voucherTermsOfUse
        };

        const changeset = new Changeset(textResource);

        set(this, 'textResource', textResource);
        set(this, 'changeset', changeset);
      }
    } catch (error) {
      throw new Error('[SETTINGS] texts', error);
    }
  }),
  /**
   * Updates the text-settings
   *
   * @type {Task}
   * @function updateTexts
   */
  updateTexts: task(function* (changeset) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;
    // create snapshot
    let snapshot = changeset.snapshot();

    try {
      let tasks = [];
      // save data to underlying object
      yield changeset.save();
      const textPayload = yield changeset.get('data');

      const request = yield this.authenticatedFetch.fetch(`${url}/texts`, {
        headers: { 'Accept-Language': this.currentLanguage || 'de' },
        method: 'PUT',
        body: JSON.stringify(textPayload)
      });

      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        changeset.rollback();
        reject();
      } else {
        // destructure response
        // TODO: Wait for api and tec. concept to be finalized
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      changeset.restore(snapshot);
      throw new Error('[SETTINGS] update texts', error);
    }
  }),
  actions: {
    changeLanguage(lang) {
      this.loadTexts.perform(lang);
    }
  }
});
