import Controller from '@ember/controller';

import { task, timeout, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Controller.extend({
  currentUser: service(),
  intl: service(),
  router: service(),
  uiDialog: service(),
  uiToast: service(),

  isViewer: alias('currentUser.isViewer'),

  activeCategory: null,
  isDeleteDialog: false,

  deleteCategory: task(function* (category) {
    let tasks = [];

    if (category.voucherCount > 0) {
      // show error toast
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    } else {
      try {
        // save category and keep the task running for at least 250ms
        tasks.push(category.destroyRecord());
        tasks.push(timeout(250));
        yield all(tasks);
        set(this, 'activeCategory', null);
        set(this, 'isDeleteDialog', false);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        // show error toast
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }).drop(),
  actions: {
    deleteCategory(category) {
      const { intl } = this;
      this.uiDialog.showConfirm(
        intl.t('vouchers.category.deleteCategory.title'),
        intl.t('vouchers.category.deleteCategory.description', {
          name: category && category.name,
          htmlSafe: true
        }),
        () => {
          this.deleteCategory.perform(category);
        },
        intl.t('global.actions.delete'),
        true,
        true
      );
    },
    editCategory(id) {
      this.router.transitionTo('instance.vouchers.categories.category', id);
    },
    filterVoucher(category) {
      this.router.transitionTo('instance.vouchers', { queryParams: { category: category } });
    }
  }
});
