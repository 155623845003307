import Component from '@ember/component';
import { set, setProperties, computed } from '@ember/object';
import { task, all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import format from 'date-fns/format';
import { CHART_COLORS } from 'additive-voucher/utils/dashboard-utils';
import { centsToAmount } from 'additive-voucher/utils/currency-serialization';

/**
 * This component fetches the data and displays the charts in the dashboard. The data of the charts
 * is updated everytime the organization slug changes.
 *
 * @class av-dashboard/charts
 */
export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  uiLocale: service(),
  uiPaths: service(),

  /**
   * the current organization slug
   *
   * @property organizationSlug
   * @type {String}
   * @default null
   */
  organizationSlug: null,

  /**
   * the selected period
   *
   * @property period
   * @type {Object}
   * @default null
   */
  period: null,

  /**
   * the organization slug of which the chart-data was fetched,
   * used to detect whether the slug has changes
   *
   * @property _previousOrganization
   * @type {String}
   * @default null
   * @private
   */
  _previousOrganization: null,

  /**
   * the period which was used to fetch the chart-data,
   * used to detect whether the period changed
   *
   * @property _previousPeriod
   * @type {Object}
   * @default null
   * @private
   */
  _previousPeriod: null,

  /**
   * the chart datasets of the ordered amount chart
   *
   * @property _amountData
   * @type {Object}
   * @default null
   */
  _amountData: null,

  /**
   * the chart datasets of the orders chart
   *
   * @property _ordersData
   * @type {Object}
   * @default null
   */
  _ordersData: null,

  /**
   * whether the task to load the chartdata is running
   *
   * @computed isLoading
   * @type {Boolean}
   */
  isLoading: computed.alias('fetchChartData.isRunning'),

  init() {
    this._super(...arguments);

    set(this, 'chartTabs', [
      { title: this.intl.t('dashboard.charts.ordersChart.title'), name: 'orders' },
      { title: this.intl.t('dashboard.charts.orderedAmountChart.title'), name: 'amount' }
    ]);
    set(this, 'activeTab', this.chartTabs[0]);

    set(this, '_previousOrganization', this.organizationSlug);
    set(this, '_previousPeriod', this.period);
  },

  didUpdateAttrs() {
    this._super();
    // reload chart data on organization change
    if (this.organizationSlug !== this._previousOrganization) {
      this.fetchChartData.perform();
      set(this, '_previousOrganization', this.organizationSlug);
    }

    // reload chart data on period change
    if (JSON.stringify(this.period) !== JSON.stringify(this._previousPeriod)) {
      this.fetchChartData.perform();
      set(this, '_previousPeriod', this.period);
    }
  },

  /**
   * fetches the datasets for the charts
   *
   * @type {Task}
   * @function fetchChartData
   */
  fetchChartData: task(function* () {
    const tasks = [];
    const baseUrl = this.uiPaths
      .pathsByRouteName('instance.dashboard', this.organizationSlug)
      .api().url;

    const period = this.period ? `?year=${this.period.value}` : '';
    const ordersUrl = `${baseUrl}/dashboard/orders-chart${period}`;
    const orderedAmountUrl = `${baseUrl}/dashboard/ordered-amount-chart${period}`;

    // load both charts
    try {
      tasks.push(this.authenticatedFetch.fetch(ordersUrl));
      tasks.push(this.authenticatedFetch.fetch(orderedAmountUrl));
      tasks.push(timeout(450));

      const [ordersResponse, orderedAmountResponse] = yield all(tasks);

      // orders chart
      const { ordersChart } = yield ordersResponse.json();
      if (ordersChart && ordersChart.datasets) {
        // translate the labels of each dataset
        const datasets = ordersChart.datasets.map((dataset, i) => {
          dataset.label = this.intl.t(`dashboard.charts.labels.${dataset.label}`);
          return Object.assign({}, dataset, CHART_COLORS[i]);
        });

        // format the date labels
        const labels = ordersChart.labels.map((label) =>
          format(new Date(label), 'MMM yyyy', { locale: this.uiLocale.dateFnsLocale })
        );
        ordersChart.datasets = datasets;
        ordersChart.labels = labels;
      }

      // orders amount chart
      const { orderedAmountChart } = yield orderedAmountResponse.json();
      if (orderedAmountChart && orderedAmountChart.datasets) {
        // translate the labels of each dataset
        const datasets = orderedAmountChart.datasets.map((dataset, i) => {
          dataset.label = this.intl.t(`dashboard.charts.labels.${dataset.label}`);
          // format currency
          const data = dataset.data.map((cents) => {
            return centsToAmount(cents);
          });
          dataset.data = data;
          return Object.assign({}, dataset, CHART_COLORS[i]);
        });

        // format the date labels
        const labels = orderedAmountChart.labels.map((label) =>
          format(new Date(label), 'MMM yyyy', { locale: this.uiLocale.dateFnsLocale })
        );
        orderedAmountChart.datasets = datasets;
        orderedAmountChart.labels = labels;
      }

      setProperties(this, { _ordersData: ordersChart, _amountData: orderedAmountChart });
    } catch (error) {
      throw new Error('[DASHBOARD/CHARTS] Error in response', error);
    }
  }).on('init')
});
