// type keys
export const VALUE = 'value';
export const SERVICE = 'service';
export const PRODUCT = 'product';
export const TREATMENT = 'treatment';
export const STAY = 'stay';

export const VOUCHER_TYPES = [VALUE, SERVICE, PRODUCT, TREATMENT, STAY];
export const VOUCHER_VALIDITY_PERIODS = [
  'end_of_year',
  'end_of_next_year',
  'one_year',
  'two_years',
  'three_years',
  'three_years_end_of_year',
  'five_years',
  'indefinitely',
  'custom'
];

/* PROPERTIES */
/**
 * Returns all available expiration formats
 *
 * @param {Ember.Service} intl, translation service instance
 */
export const expirationFormats = (intl) => {
  return [
    {
      value: 'one_year',
      name: intl.t('vouchers.expirationFormats.one_year')
    },
    {
      value: 'two_years',
      name: intl.t('vouchers.expirationFormats.two_years')
    },
    {
      value: 'three_years',
      name: intl.t('vouchers.expirationFormats.three_years')
    },
    {
      value: 'five_years',
      name: intl.t('vouchers.expirationFormats.five_years')
    },
    {
      value: 'end_of_year',
      name: intl.t('vouchers.expirationFormats.end_of_year')
    },
    {
      value: 'end_of_next_year',
      name: intl.t('vouchers.expirationFormats.end_of_next_year')
    },
    {
      value: 'three_years_end_of_year',
      name: intl.t('vouchers.expirationFormats.three_years_end_of_year')
    },
    {
      value: 'indefinitely',
      name: intl.t('vouchers.expirationFormats.indefinitely')
    },
    {
      value: 'custom',
      name: intl.t('vouchers.expirationFormats.custom')
    }
  ];
};

/* settings */
export const PAYMENT_TYPES_MAP = {
  bank_transfer: {
    hasLogo: false,
    credentials: {
      bankName: null,
      iban: null,
      bic: null
    }
  },
  gastro_pool: {
    hasLogo: true,
    credentials: {
      key: null
    }
  },
  gastro_pool_v3: {
    hasLogo: true,
    credentials: {
      key: null
    }
  },
  /* Hobex */
  oppwa: {
    hasLogo: true,
    credentials: {
      token: null,
      entityId: null
    }
  },
  hogast: {
    hasLogo: true,
    credentials: {
      key: null
    }
  },
  hogast_v3: {
    hasLogo: true,
    credentials: {
      key: null
    }
  },
  paypal: {
    hasLogo: true,
    credentials: {
      username: null,
      password: null,
      signature: null
    }
  },
  vpos: {
    hasLogo: true,
    credentials: {
      id: null
    }
  },
  concardis: {
    hasLogo: true,
    credentials: {
      merchantId: null,
      apiKey: null
    }
  },
  nexi: {
    hasLogo: true,
    credentials: {
      correlationId: null,
      apiKey: null
    }
  },
  sia_vpos: {
    hasLogo: true,
    credentials: {
      shopId: null,
      apiStartKey: null,
      apiResultKey: null
    }
  },
  six_payments: {
    hasLogo: true,
    credentials: {
      apiUser: null,
      customerId: null,
      terminalId: null,
      apiPassword: null
    }
  },
  stripe: {
    hasLogo: true,
    credentials: {
      secretKey: null,
      publishableKey: null
    }
  },
  klarna: {
    hasLogo: true,
    credentials: {
      username: null,
      password: null
    }
  },
  cash: {
    hasLogo: false,
    credentials: {}
  }
};

export const syncServices = ['asa', 'hgv'];

/* template layouts */
export const LAYOUT_LANDSCAPE = 'landscape';
export const LAYOUT_PORTRAIT = 'portrait';
export const LAYOUTS = [LAYOUT_PORTRAIT, LAYOUT_LANDSCAPE];
export const getOnBoarding = (intl) => {
  return {
    1: [
      {
        title: intl.t('onboarding.1.title'),
        description: intl.t('onboarding.1.description'),
        illustration: 'voucher-logo',
        isFirstStep: true
      },
      {
        title: intl.t('onboarding.2.title'),
        description: intl.t('onboarding.2.description'),
        illustration: 'voucher-widget',
        theme: 'dark'
      },
      {
        title: intl.t('onboarding.3.title'),
        description: intl.t('onboarding.3.description'),
        illustration: 'inhouse-widget',
        theme: 'blue'
      },
      {
        title: intl.t('onboarding.4.title'),
        description: intl.t('onboarding.4.description'),
        illustration: 'connections',
        actionLabel: intl.t('onboarding.actions.go'),
        isLastStep: true
      }
    ]
  };
};
