import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiCard class=\"av-template-card\" @onClick={{this.onClick}} ...attributes as |card|>\n  <card.header as |header|>\n    <header.content as |content|>\n      <content.image @url={{@template.image}} />\n    </header.content>\n  </card.header>\n  <card.footer as |footer|>\n    <footer.status @state={{if @template.default \"success\" \"grey\"}} />\n    <footer.content as |content|>\n      <content.title\n        @value={{t (concat \"widgets.template.types.\" @template.name) orgName=this.orgName}}\n      />\n    </footer.content>\n    <footer.menu as |menu|>\n      <menu.item\n        @label={{t \"widgets.template.actions.setAsDefault\"}}\n        @onClick={{perform this.setAsDefault}}\n        @isDisabled={{@template.default}}\n      />\n    </footer.menu>\n  </card.footer>\n</UiCard>", {"contents":"<UiCard class=\"av-template-card\" @onClick={{this.onClick}} ...attributes as |card|>\n  <card.header as |header|>\n    <header.content as |content|>\n      <content.image @url={{@template.image}} />\n    </header.content>\n  </card.header>\n  <card.footer as |footer|>\n    <footer.status @state={{if @template.default \"success\" \"grey\"}} />\n    <footer.content as |content|>\n      <content.title\n        @value={{t (concat \"widgets.template.types.\" @template.name) orgName=this.orgName}}\n      />\n    </footer.content>\n    <footer.menu as |menu|>\n      <menu.item\n        @label={{t \"widgets.template.actions.setAsDefault\"}}\n        @onClick={{perform this.setAsDefault}}\n        @isDisabled={{@template.default}}\n      />\n    </footer.menu>\n  </card.footer>\n</UiCard>","moduleName":"additive-voucher/components/av-template-card.hbs","parseOptions":{"srcName":"additive-voucher/components/av-template-card.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class AvTemplateCardComponent extends Component {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service uiPaths;
  @service uiToast;

  get orgName() {
    return this.currentUser?.currentOrganization?.name;
  }

  /**
   * Is triggered on click, transitions to route if linkTo param is present
   *
   * @event click
   * @return {Boolean}
   */
  @action
  onClick() {
    let {
      args: { linkTo, linkToParam }
    } = this;
    if (linkTo) {
      this.router.transitionTo(linkTo, linkToParam);
      return true;
    }
    return false;
  }

  @task(function* () {
    try {
      const baseUrl = this.uiPaths.pathsByRouteName('instance').api().url;

      const response = yield this.authenticatedFetch.fetch(
        `${baseUrl}/templates/${this.args.template.id}/default`,
        { method: 'PUT' }
      );

      if (!response || !response.ok) {
        throw new Error('[Template] set as default');
      } else {
        this.args.onFilterReset?.();
        // show toast and reload list
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  setAsDefault;
}
