import Controller from '@ember/controller';

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { format } from 'date-fns';

export default class InstanceDashboardController extends Controller {
  @service currentUser;
  @service uiAppSettings;
  @service intl;

  @tracked
  period = {
    name: this.intl.t(`dashboard.periodSelection.last_12_months`),
    value: 'last_12_months'
  };

  @tracked
  isNewOrderDialog = false;

  get organizationSlug() {
    return this.currentUser?.currentOrganization?.id;
  }

  get isProfessionalPlan() {
    return this.uiAppSettings?.isProfessionalPlan;
  }

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  get salutation() {
    const time = format(new Date(), 'HH:mm');
    let salutation;
    let name = this.currentUser?.user?.fullName?.split(' ')[0];
    name == undefined ? (name = '') : (name = `, ${name}`);

    time > '00:00' && time <= '12:00'
      ? (salutation = this.intl.t(`dashboard.salutations.morning`))
      : time > '12:00' && time < '17:00'
      ? (salutation = this.intl.t(`dashboard.salutations.afternoon`))
      : (salutation = this.intl.t(`dashboard.salutations.evening`));

    return `${salutation}${name}!`;
  }
}
