import ENV from 'additive-voucher/config/environment';

import { A } from '@ember/array';
import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias, equal, and } from '@ember/object/computed';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';

import { LAYOUT_LANDSCAPE, LAYOUT_PORTRAIT } from 'additive-voucher/utils/constants';
import { GRID_DEFAULT_SIZE } from '@additive-apps/media/constants';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  router: service(),
  uiAppSettings: service(),
  uiDialog: service(),
  uiPaths: service(),
  uiToast: service(),
  uiFilter: service(),

  /**
   * Indicates if template preview slider is visible or not
   *
   * @property _showTemplatePreview
   * @type {Boolean}
   * @default false
   * @private
   */
  _showTemplatePreview: false,

  /**
   * array of preview objects for the preview dialog
   *
   * @property _previews
   * @type {Array}
   * @default null
   * @private
   */
  _previews: null,

  /**
   * Indicates if this voucher has invalid products
   *
   * @property _hasInvalidProducts
   * @type {Boolean}
   * @default false
   */
  _hasInvalidProducts: false,

  /**
   * Indicates if this voucher has invalid treatments
   *
   * @property _hasInvalidTreatments
   * @type {Boolean}
   * @default false
   */
  _hasInvalidTreatments: false,

  /**
   * Indicates if this voucher has invalid room types
   *
   * @property _hasInvalidRoomTypes
   * @type {Boolean}
   * @default false
   */
  _hasInvalidRoomTypes: false,

  /**
   * default size for images
   *
   * @property defaultSize
   * @type {String}
   * @default 334x240
   */
  defaultSize: GRID_DEFAULT_SIZE,

  voucher: alias('model'),

  availableLanguages: alias('uiAppSettings.languages.contentLanguages'),
  defaultLanguage: alias('uiAppSettings.languages.defaultLanguage'),
  currentLocale: alias('uiAppSettings.currentLocale'),
  isViewer: alias('currentUser.isViewer'),

  headerImageUrl: alias('voucher.images.firstObject.url'),

  isProductVoucher: equal('model.type', 'product'),
  isTreatmentVoucher: equal('model.type', 'treatment'),
  isStayVoucher: equal('model.type', 'stay'),
  hasPersonCount: and('isStayVoucher', 'model.personCount'),
  hasMinStays: and('isStayVoucher', 'model.minStays'),

  /**
   * The path to the organization in the content app
   * @computed contentPath
   * @return {String} the url to the content-app
   */
  contentPath: computed('currentUser.currentOrganization.id', {
    get() {
      return `${ENV.APP.contentHost}/${this.currentUser?.currentOrganization?.id}`;
    }
  }),

  // just for now, will be moved to own component as the template gets split up by type
  description: computed('model.description', {
    get() {
      return htmlSafe(this.model.description);
    }
  }),
  services: computed('model.services', {
    get() {
      return htmlSafe(this.model.services);
    }
  }),

  discountedAmount: computed('voucher.{discountedAmount,isDiscounted}', {
    get() {
      const { discountedAmount } = this.voucher;
      return discountedAmount || 0;
    }
  }),
  discountPercent: computed('voucher.{discountedAmount,amount}', {
    get() {
      const { discountedAmount, amount } = this.voucher;
      if (discountedAmount && amount) {
        return Math.round(((amount - discountedAmount) / amount) * 100);
      }
      return;
    }
  }),

  /**
   * Indicates if products info message is visible or not
   *
   * @computed showProductsInfo
   * @return {Boolean}
   */
  showProductsInfo: computed('voucher.{type,publishedState}', '_hasInvalidProducts', {
    get() {
      return (
        this.voucher.type === 'product' &&
        this.voucher.publishedState !== 'error' &&
        this._hasInvalidProducts
      );
    }
  }),

  /**
   * Indicates if treatments info message is visible or not
   *
   * @computed showTreatmentsInfo
   * @return {Boolean}
   */
  showTreatmentsInfo: computed('voucher.{type,publishedState}', '_hasInvalidTreatments', {
    get() {
      return (
        this.voucher.type === 'treatment' &&
        this.voucher.publishedState !== 'error' &&
        this._hasInvalidTreatments
      );
    }
  }),

  /**
   * Indicates if room types info message is visible or not
   *
   * @computed showRoomTypesInfo
   * @return {Boolean}
   */
  showRoomTypesInfo: computed('voucher.{type,publishedState}', '_hasInvalidRoomTypes', {
    get() {
      return (
        this.voucher.type === 'stay' &&
        this.voucher.publishedState !== 'error' &&
        this._hasInvalidRoomTypes
      );
    }
  }),

  publishAction: task(function* () {
    yield timeout(2000);
  }),

  /**
   * changes the locale the model to a given locale
   *
   * @type {Task}
   * @function changeLocale
   * @param {String} locale
   */
  changeLocale: task(function* (locale) {
    if (locale !== this.currentLocale) {
      this.uiAppSettings.setLocale(locale);

      let tasks = [];
      tasks.push(this.model.reload());
      tasks.push(timeout(400));

      yield all(tasks);
    }
  }),

  /**
   * deletes a given locale of the voucher model
   *
   * @type {Task}
   * @function removeLocale
   * @param {String} locale
   */
  removeLocale: task(function* (locale) {
    try {
      const baseUrl = this.uiPaths.pathsByRouteName('instance.vouchers').api().url;
      const url = `${baseUrl}/${this.voucher.id}/${locale}`;

      const response = yield this.authenticatedFetch.fetch(url, { method: 'DELETE' });

      if (!response || !response.ok) {
        throw new Error();
      }

      const json = yield response.json();
      if (!json || !json.voucher) {
        throw new Error();
      }

      this.store.pushPayload({ vouchers: json.voucher });

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  /**
   * deletes the voucher model
   *
   * @param {Object} voucher
   * @type {Task}
   * @function _deleteVoucher
   * @private
   */
  _deleteVoucher: task(function* () {
    try {
      const voucher = this.voucher;

      let tasks = [];
      tasks.push(voucher.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);
      yield this.uiFilter.reset('vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success'),
        duration: 2000
      });

      this.router.transitionTo('instance.vouchers');
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop(),

  actions: {
    edit(model) {
      this.uiAppSettings.setLocale(model.get('language'));
      this.router.transitionTo('instance.vouchers.index.voucher.edit', model);
    },
    onClose() {
      this.router.transitionTo('instance.vouchers');
    },
    addLocale(lang) {
      this.changeLocale.perform(lang);
      this.router.transitionTo('instance.vouchers.index.voucher.edit', this.model);
    },
    deleteVoucher() {
      const { intl } = this;
      this.uiDialog.showConfirm(
        intl.t('vouchers.voucher.deleteVoucher.title'),
        htmlSafe(
          intl.t('vouchers.voucher.deleteVoucher.description', {
            name: this.voucher && this.voucher.name
          })
        ),
        () => {
          this._deleteVoucher.perform();
        },
        intl.t('global.actions.delete'),
        true,
        true
      );
    },
    openPreviewCarousel(medium) {
      const { model } = this;
      const previews = A();

      if (model && model.previewUrl && medium) {
        previews.push({
          url: `${model.previewUrl}&image=${medium.id}`,
          layoutType: LAYOUT_PORTRAIT,
          maxWidth: 439
        });
        previews.push({
          url: `${model.previewUrl}&image=${medium.id}&layout=landscape`,
          layoutType: LAYOUT_LANDSCAPE,
          maxWidth: 774
        });
      }

      set(this, '_previews', previews);
      set(this, '_showTemplatePreview', true);
    },

    onInlineNotificationClick(url) {
      window.open(url, '_blank');
    }
  },

  setInvalidContent(key, content) {
    if (key === '_hasInvalidRoomTypes') {
      let hasInvalidRoomTypes = true;
      for (let i = 0; i < content.length; i++) {
        if (content[i].basePrice) {
          hasInvalidRoomTypes = false;
          break;
        }
      }
      set(this, key, hasInvalidRoomTypes);
    } else {
      set(this, key, !content.length);
    }
  }
});
