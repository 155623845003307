import Controller from '@ember/controller';

import { customerValidation } from 'additive-voucher/validations/customer';
import { set, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { alias, not } from '@ember/object/computed';
import { task, timeout, all } from 'ember-concurrency';

import { ORDER_ACTIONS_MAP } from 'additive-voucher/utils/constants/order';

export default class InstanceOrdersOrderController extends Controller {
  @service authenticatedFetch;
  @service store;
  @service currentUser;
  @service intl;
  @service router;
  @service uiState;
  @service uiToast;
  @service uiDialog;
  @service uiFilter;

  /**
   * Whether the preview is open or not
   *
   * @property {Boolean} isOrderPreview
   * @default false
   */
  @tracked
  isOrderPreview = false;

  /**
   * Whether the ordered vouchers texts dialog is open
   *
   * @property {Boolean} isOrderTextsDialog
   * @default false
   */
  @tracked
  isOrderTextsDialog = false;

  @tracked
  isExpirationDialog = false;

  @tracked
  isRedeemDialog = false;

  @tracked
  isPaymentTypeDialog = false;

  @tracked
  isConfirmIssueDialog = false;

  @tracked
  isReactivateDialog = false;

  /**
   * Object containing only the title and text of the order
   *
   * @property _orderTexts
   * @type {Object}
   * @default null
   * @private
   */
  @tracked
  _orderTexts = null;

  @tracked
  customerValidation = customerValidation;

  @not('model.isCanceled') hasPreview;
  @alias('currentUser.isViewer') isViewer;

  get _preview() {
    return [
      {
        url: this.model.previewUrl,
        layout: this.model.layout
      }
    ];
  }

  get _availableActions() {
    return ORDER_ACTIONS_MAP[this.model.state].actions;
  }

  /**
   * Updates a orders state
   *
   * @type {Task}
   * @function updateState
   * @param {String} state the next state
   * @param {String} data the redeemCode (state = redeemed) or the payment-type (state = paid)
   */
  @task(function* (state, skipIssueCheck = false) {
    try {
      if (state === 'redeemVoucher') {
        this.isRedeemDialog = true;
        return;
      } else if (state === 'paid') {
        this.isPaymentTypeDialog = true;
        return;
      } else if (state === 'extendExpiration') {
        this.isExpirationDialog = true;
        return;
      } else if (state === 'issued' && !skipIssueCheck) {
        this.isConfirmIssueDialog = true;
        return;
      } else if (state === 'canceled' && !skipIssueCheck) {
        this.uiDialog.showConfirm(
          this.intl.t('orders.cancelOrder.title'),
          this.intl.t('orders.cancelOrder.description', {
            name: this.model.identifier,
            htmlSafe: true
          }),
          () => this.updateState.perform('canceled', true),
          this.intl.t('orders.cancelOrder.action'),
          true,
          true
        );
        return;
      }
      let body = JSON.stringify({ state });

      const adapter = this.store.adapterFor('order');
      const baseUrl = adapter.buildURL('order', this.model.id);
      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/state`, {
        method: 'PUT',
        body
      });

      if (response && response.ok) {
        const order = yield response.json();
        this.store.pushPayload('order', order);

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } else {
        const { errors } = yield response.json();
        throw new Error(`${errors}`);
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  updateState;

  /**
   * Sends a notification to the user
   * TODO: Wait for API to implement all of the following types of notification
   * [ confirm_order, confirm_gift, remind_payment, confirm_payment ]
   * @type {Task}
   * @function notifyCustomer
   * @param {String} type the type of the notification to send
   */
  @task(function* (type) {
    try {
      const adapter = this.store.adapterFor('order');
      const baseUrl = adapter.buildURL('order', this.model.id);
      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/customer/notify`, {
        method: 'POST',
        body: JSON.stringify({ type })
      });

      if (!response || !response.ok) {
        throw new Error();
      } else {
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  notifyCustomer;

  /**
   * Deletes an order
   * @type {Task}
   * @function deleteOrder
   */
  @task(function* () {
    try {
      let tasks = [];

      // delete voucher and keep the task running for at least 250ms
      tasks.push(this.model.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);

      this.router.transitionTo('instance.orders');

      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
      // reset all filters and reload list
      yield this.uiFilter.loadModel('orders');
    } catch (e) {
      // show error toast
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  deleteOrder;

  @action
  openTextsDialog() {
    this._orderTexts = {
      title: this.model.title,
      text: this.model.text
    };

    this.isOrderTextsDialog = true;
  }

  @action
  saveCustomer(customerData) {
    const customer = Object.assign({}, this.model.customer, customerData);
    this.model.customer = customer;

    return this.model.save().then(() => {
      set(this, 'isCustomerDialog', false);
    });
  }

  @action
  saveTexts(texts) {
    const { intl } = this;
    this.model.title = texts.title;
    this.model.text = texts.text;

    return this.model.save().then(() => {
      this.isOrderTextsDialog = false;
      this.uiToast.showToast({
        title: intl.t('toast.orderUpdated.title'),
        description: intl.t('toast.orderUpdated.description')
      });
    });
  }

  @action
  back() {
    this.router.transitionTo('instance.orders');
  }

  @action
  toggleDetail() {
    this.uiState.getState('order-detail').toggle();
  }

  @action
  download() {
    window?.open(this.model.pdfUrl, '_blank');
  }

  @action
  onDelete() {
    this.uiDialog.showConfirm(
      this.intl.t('orders.deleteOrder.title'),
      this.intl.t('orders.deleteOrder.description', {
        name: this.model.identifier,
        htmlSafe: true
      }),
      () => this.deleteOrder.perform(),
      this.intl.t('global.actions.delete'),
      true,
      true
    );
  }
}
