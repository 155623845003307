import { validatePresence } from 'ember-changeset-validations/validators';

export const categoryObject = (lang) => {
  return {
    name: '',
    description: '',
    language: lang || 'de',
    image: null
  };
};

export const categoryValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    name: validatePresence({ presence: true, message: presenceMessage })
  };
};
