import Model, { attr } from '@ember-data/model';

export default class CategoryModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') language;
  @attr('number') voucherCount;
  @attr() image;
  @attr() availableLanguages;
}
