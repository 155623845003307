import ENV from '../config/environment';
import Service from '@additive-apps/auth/services/current-user';

/**
 * ENV variables from the application are passed
 * to the service and can be used in the addon.
 */
export default Service.extend({
  ENV: ENV
});
