import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),
  uiAppSettings: service(),

  renderTemplate() {
    this.render('instance/vouchers/index/voucher', {
      into: 'instance/vouchers',
      outlet: 'detail'
    });
  },

  model(params) {
    const storeModel = this.store.peekRecord('voucher', params.voucher_id);
    if (storeModel) {
      this.uiAppSettings.setLocale(storeModel.language);
      return storeModel;
    }

    return this.store.findRecord('voucher', params.voucher_id);
  },

  setupController(controller, model) {
    this._super(...arguments);

    model.setAllInterestTags();
  },

  resetController(controller, isExiting) {
    if (
      isExiting &&
      this.uiAppSettings.languages.defaultLanguage !== controller.model.get('language')
    ) {
      this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
      return (
        !controller.model?.get('isDestroyed') &&
        !controller.model?.get('isDeleted') &&
        controller.model.reload()
      );
    }
  }
});
