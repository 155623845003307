import Model, { attr, hasMany } from '@ember-data/model';

export default class PackageModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('boolean') enabled;
  @attr('price') price;
  @attr('string') language;
  @attr() availableLanguages;
  @attr() image;
  @hasMany('shipping') shippings;
}
