import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiInput\n  @label={{t \"vouchers.voucher.properties.minAmount\"}}\n  @value={{@changeset.minAmount}}\n  @isTouched={{@isTouched}}\n  @isReadOnly={{@isReadOnly}}\n  @errorMessage={{get @changeset.error.minAmount \"validation\"}}\n  @onBlur={{fn this.validate @changeset}}\n  as |input|\n>\n  <input.right @class=\"secondary pr1\">\n    {{currency-symbol this.uiAppSettings.currency}}\n  </input.right>\n</UiInput>\n\n<UiInput\n  @label={{t \"vouchers.voucher.properties.maxAmount\"}}\n  @value={{@changeset.maxAmount}}\n  @isTouched={{@isTouched}}\n  @isReadOnly={{@isReadOnly}}\n  @errorMessage={{get @changeset.error.maxAmount \"validation\"}}\n  @onBlur={{fn this.validate @changeset}}\n  as |input|\n>\n  <input.right @class=\"secondary pr1\">\n    {{currency-symbol this.uiAppSettings.currency}}\n  </input.right>\n</UiInput>", {"contents":"<UiInput\n  @label={{t \"vouchers.voucher.properties.minAmount\"}}\n  @value={{@changeset.minAmount}}\n  @isTouched={{@isTouched}}\n  @isReadOnly={{@isReadOnly}}\n  @errorMessage={{get @changeset.error.minAmount \"validation\"}}\n  @onBlur={{fn this.validate @changeset}}\n  as |input|\n>\n  <input.right @class=\"secondary pr1\">\n    {{currency-symbol this.uiAppSettings.currency}}\n  </input.right>\n</UiInput>\n\n<UiInput\n  @label={{t \"vouchers.voucher.properties.maxAmount\"}}\n  @value={{@changeset.maxAmount}}\n  @isTouched={{@isTouched}}\n  @isReadOnly={{@isReadOnly}}\n  @errorMessage={{get @changeset.error.maxAmount \"validation\"}}\n  @onBlur={{fn this.validate @changeset}}\n  as |input|\n>\n  <input.right @class=\"secondary pr1\">\n    {{currency-symbol this.uiAppSettings.currency}}\n  </input.right>\n</UiInput>","moduleName":"additive-voucher/components/av-forms/amount.hbs","parseOptions":{"srcName":"additive-voucher/components/av-forms/amount.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AvFormsAmount extends Component {
  @service uiAppSettings;

  /**
   * Validates the passed changeset.
   *
   * @param {Object} changeset
   */
  @action
  validate(changeset) {
    changeset.validate();
  }
}
