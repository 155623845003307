import ApplicationAdapter from './application';
import { set } from '@ember/object';

export default ApplicationAdapter.extend({
  findRecord(store, type, id, snapshot) {
    /* Pass currentLocale as adapterOptions to define which accept-language should be used */
    if (snapshot.adapterOptions && snapshot.adapterOptions.currentLocale) {
      set(this, 'currentLocale', snapshot.adapterOptions.currentLocale);
    }

    return this._super(...arguments);
  },
  updateRecord(store, type, snapshot) {
    /* Pass currentLocale as adapterOptions to define which accept-language should be used */
    if (snapshot.adapterOptions && snapshot.adapterOptions.currentLocale) {
      set(this, 'currentLocale', snapshot.adapterOptions.currentLocale);
    }

    return this._super(...arguments);
  },
  createRecord(store, type, snapshot) {
    /* Pass currentLocale as adapterOptions to define which accept-language should be used */
    if (snapshot.adapterOptions && snapshot.adapterOptions.currentLocale) {
      set(this, 'currentLocale', snapshot.adapterOptions.currentLocale);
    }

    return this._super(...arguments);
  },
  findAll(store, type, id, snapshot) {
    /* Pass currentLocale as adapterOptions to define which accept-language should be used */
    if (snapshot.adapterOptions && snapshot.adapterOptions.currentLocale) {
      set(this, 'currentLocale', snapshot.adapterOptions.currentLocale);
    }

    return this._super(...arguments);
  }
});
