import ENV from 'additive-voucher/config/environment';
import Component from '@ember/component';

import { inject as service } from '@ember/service';
import { set, computed } from '@ember/object';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { getValidationByType } from 'additive-voucher/validations/payment-type';

import { task } from 'ember-concurrency';

export default Component.extend({
  intl: service(),
  uiLocale: service(),
  uiToast: service(),
  uiDialog: service(),

  /**
   * Whether it should be in read only mode
   *
   * @type {Boolean} readonly
   * @default false
   */
  readonly: false,

  init() {
    this._super(...arguments);

    const { credentials, type } = this.paymentType;
    const _credentials = Object.keys(credentials);

    let validation = () => {};
    /* Only add validation if payment type is enabled*/
    if (this.paymentType.enabled) {
      validation = getValidationByType(type, this.intl);
    }

    if (type === 'oppwa') {
      const { options } = this.paymentType;
      const changeset = new Changeset(
        { credentials, options },
        lookupValidator(validation),
        validation
      );
      set(this, 'changeset', changeset);
    } else {
      const changeset = new Changeset(credentials, lookupValidator(validation), validation);
      set(this, 'changeset', changeset);
    }
    set(this, '_credentials', _credentials);
  },

  downloadUrl: computed('paymentType.type', 'uiLocale.locale', {
    get() {
      const language = this.uiLocale.locale || 'de';
      return `${ENV.APP.publicHost}/payment-type-guides/${this.paymentType.type}-setup-${language}.pdf`;
    }
  }),
  hasConfigurationGuide: computed('paymentType.type', {
    get() {
      return (
        [
          'concardis',
          'nexi',
          'gastro_pool',
          'gastro_pool_v3',
          'hogast',
          'hogast_v3',
          'oppwa',
          'paypal',
          'six_payments',
          'vpos',
          'sia_vpos',
          'stripe',
          'klarna'
        ].indexOf(this.paymentType.type) > -1
      );
    }
  }),

  _hasIntro: computed('paymentType.type', {
    get() {
      return this.paymentType.type !== 'bank_transfer';
    }
  }),

  onClose() {},

  /**
   * @type {Task}
   * @function updatePaymentType
   */
  updatePaymentType: task(function* (changeset) {
    try {
      yield changeset.validate();

      if (changeset.isValid) {
        yield changeset.save();
        // TODO: Wait that api implements correct errors per payment type
        yield this.paymentType.save();

        yield this.onClose();

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      throw new Error(`[PAYMENT-TYPE] Could not update payment type ${error}`);
    }
  }),
  actions: {
    closeCreationDialog() {
      if (this.changeset.isDirty) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          this.changeset.rollback();
          this.onClose();
        });
      } else {
        this.onClose();
      }
    }
  }
});
