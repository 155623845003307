import Component from '@ember/component';
import { task, timeout, all } from 'ember-concurrency';
import { set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  authenticatedFetch: service(),
  intl: service(),
  router: service(),
  uiPaths: service(),

  /**
   * the current organization slug
   *
   * @property organizationSlug
   * @type {String}
   * @default null
   */
  organizationSlug: null,

  /**
   * the organization slug of which the chart-data was fetched,
   * used to detect whether the slug has changes
   *
   * @property _previousOrganization
   * @type {String}
   * @default null
   * @private
   */
  _previousOrganization: null,

  /**
   * whether the table is empty
   *
   * @computed isEmpty
   * @type {Boolean}
   */
  isEmpty: computed.equal('lastOrders.length', 0),

  init() {
    this._super(...arguments);

    const states = [
      'all',
      'issued',
      'ordered',
      'paid',
      'gift',
      'redeemed',
      'partial_redeemed',
      'canceled',
      'expired'
    ];
    set(
      this,
      'stateOptions',
      states.map((state) => {
        return {
          value: state,
          name: this.intl.t(`orders.order.state.${state}`)
        };
      })
    );

    set(this, 'selectedState', 'all');
    set(this, 'tableHeaderOptions', [
      { i18n: 'dashboard.lastOrders.columns.name', minWidth: '80px' },
      { i18n: 'dashboard.lastOrders.columns.identifier', minWidth: '80px' },
      { i18n: 'dashboard.lastOrders.columns.voucher', minWidth: '80px' },
      { i18n: 'dashboard.lastOrders.columns.orderedAt', minWidth: '80px' },
      { i18n: 'dashboard.lastOrders.columns.state', minWidth: '80px' }
    ]);
  },

  didUpdateAttrs() {
    this._super();
    // reload order data on organization change
    if (this.organizationSlug !== this._previousOrganization) {
      this.loadLastOrders.perform();
      set(this, '_previousOrganization', this.organizationSlug);
    }
  },

  /**
   * <slug>/dashboard/last-orders
   *
   * Loads the organisations orders whith the latest state changes
   *
   * @throws {Error}
   */
  loadLastOrders: task(function* () {
    try {
      const state = this.selectedState;
      let tasks = [];
      /* this.uiPaths.pathsByRouteName('instance.dashboard', this.organizationSlug).api() */
      const baseUrl = this.uiPaths
        .pathsByRouteName('instance.dashboard', this.organizationSlug)
        .api().url;

      let url = `${baseUrl}/dashboard/last-orders`;

      if (state && state !== 'all') {
        url = `${url}?state=${state}`;
      }

      // load last orders
      tasks.push(this.authenticatedFetch.fetch(url));
      tasks.push(timeout(450));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[DASHBOARD] Error in response');
      }

      const { orders } = yield response.json();

      set(this, 'lastOrders', orders);
    } catch (error) {
      // set error
    }
  }).on('init'),

  actions: {
    openVoucher(order) {
      this.router.transitionTo('instance.orders.order', order.id);
    }
  }
});
